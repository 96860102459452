import React from 'react';

const MapComponent = () => {
  const latitude = 34.686667; // Replace with the latitude of your desired location
  const longitude = -1.911389; // Replace with the longitude of your desired location

  return (
    <div>
      <iframe
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD6_tHS8_ACL8m8MYKj0dv5_b3htLV7RSU&q=${latitude},${longitude}`}
        allowFullScreen
        title="Embedded Map"
      ></iframe>
    </div>
  );
};

export default MapComponent;

