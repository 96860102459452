import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import Popper from '@mui/material/Popper';
import Sidebar from '../../Sidebar';
import Navbar from "../../Navbar";
import styled from "styled-components";
import url from '../../../api/serverUrl';
import AddClientModal from './AddClinetModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};


const Evaluationclient = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);


    const [openEvaluation, setOpenEvaluation] = useState(false);
    const [clientCin, setClientCin] = useState('');
    const [cin, setCin] = useState(null);

    const handleOpenEvaluation = () => {

        setOpenEvaluation(true);
    };
    const handleCloseEvaluation = () => {
        setOpenEvaluation(false);
        setClientCin('');
        setCin(null);
    };

    const handleCinChange = (event) => {
        setCin(event.target.value);
        console.log(event.target.value);
    };
    const handleSubmitCherche = async () => {

        try {
            const response = await axios.get(`${url}/api/client/${cin}/get-avis`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            setClientCin(response.data);
        } catch (error) {
            console.error('Error retrieving client list:', error);
        }
    };

    const handleOpenModal = (cin) => {
        setCin(cin);
        // Set the state to open the modal
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        // Set the state to close the modal
        setIsModalOpen(false);

        console.log("closed")
      };
    
    return (
        <>
            <Sidebar />
            <Navbar />
            <Section>
                <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px' }}>
                    <h2 className="text-center" style={{ fontSize: '24px', color: '#333', margin: '0', padding: '10px', borderBottom: '2px solid blue', width: '50%', margin: 'auto' }}>
                        Evaluation du Client
                    </h2>
                </div>
                <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Entrez Cin du client"
                            name="cin"
                            value={cin}
                            onChange={handleCinChange}
                            sx={{ mt: 2, width: '100%', marginLeft: '20px' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            onClick={handleSubmitCherche}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '15px', marginTop: "25px", marginLeft: '20px' }}
                            sx={{
                                width: '200px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                            chercher
                        </Button>
                    </Grid>
                    {clientCin && (
                        <>
                        <div style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'space-between',
                                alignItems:'center',
                                
                            }}>
                            <div style={{
                                textAlign: 'center',
                                color: 'rgb(68, 125, 248)',
                                marginTop: '50px',
                                fontWeight: 'lighter',
                                letterSpacing: '0.5px',
                                // borderBottom: '1px solid rgb(80, 80, 80)',
                                marginLeft: '350px',
                                fontSize: '35px'
                            }}>
                                {clientCin.nom}
                                <Rating
                                    value={clientCin.averageRating}
                                    precision={0.5}
                                    style={{
                                        marginTop: '1px',
                                        fontSize: '20px',
                                        color: '#FFD700', // Couleur des étoiles
                                    }}
                                    emptyIcon={<StarBorderIcon fontSize="inherit" />} // Icône d'étoile vide
                                    icon={<StarIcon fontSize="inherit" />} // Icône d'étoile pleine
                                    readOnly // Empêche l'utilisateur de modifier la note
                                />
                                <per style={{ fontSize: '20px' }}> ({clientCin.averageRating})</per>
                            </div>
                            <Button
                            onClick={() => handleOpenModal(clientCin.cin)}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '15px', marginTop: "25px", marginLeft: '20px' }}
                            sx={{
                                width: '100px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                            Ajouter
                        </Button>
                        </div>

                            <div style={{
                                width: '650px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                padding: ' 20px',
                                boxShadow: ' 0 2px 4px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#f9f9f9',
                                fontFamily: 'Arial, sans-serif',
                                margin: '40px auto',
                                overflow: 'auto',
                                marginTop: '-1px'
                            }}>

                                <div>
                                    {clientCin && (
                                        <div>
                                            <table className="table">
                                                <tbody>
                                                    {clientCin.commentaires.map((commentaire, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">
                                                                <PersonIcon />
                                                            </th>
                                                            <td>
                                                                <div
                                                                    style={{
                                                                        whiteSpace: 'pre-wrap',
                                                                        width: '100%',
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '5px',
                                                                        padding: '10px',
                                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                                        backgroundColor: '#f9f9f9',
                                                                        fontFamily: 'Arial, sans-serif',
                                                                    }}
                                                                >
                                                                    {commentaire}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    )}
                                </div>
                            </div>

                        </>)}
                </Grid>
                {isModalOpen && <AddClientModal cin={cin} openModal={true} onClose={handleCloseModal}/>}
            </Section>
        </>
    )
}

export default Evaluationclient;


const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;
