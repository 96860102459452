import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import url from '../../../api/serverUrl';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

const AddClientModal = ({cin, openModal, onClose})=>{

    let clinetCin = '';
    if(cin){
        clinetCin = cin;
    }


    const headers = {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      };

    const [open, setOpen] = useState(openModal);
    const [selectedPhotoCin, setSelectedPhotoCin] = useState(null);
    const [selectedPhotoPermis, setSelectedPhotoPermis] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        cin: clinetCin,
        datenaissance: '',
        telephone: '',
        email: '',
        adresse: '',
        ville: '',
        numeroPermis: '',
        photoCin: null,
        photoPermis: null,
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        // Call the onClose callback from the parent component
        onClose();
      };

    const [datenaissance, setDatenaissance] = useState('');
  const [permisExpire, setPermisExpire] = useState('');
  const [cinExpire, setCinExpire] = useState('');

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    // Handle file inputs separately
    if (type === 'file') {
      if (name === 'photoCin') {
        setSelectedPhotoCin(e.target.files[0]);
      } else if (name === 'photoPermis') {
        setSelectedPhotoPermis(e.target.files[0]);
      }
    } else if (name === 'datenaissance') {
      setFormValues({
        ...formValues,
        datenaissance: e.target.value,
      });
    } else if (name === 'permisExpire') {
      setFormValues({
        ...formValues,
        permisExpire: e.target.value,
      });
    } else if (name === 'cinExpire') {
      setFormValues({
        ...formValues,
        cinExpire: e.target.value, // Update the date field directly in the formValues state
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const handleSubmit = () => {
    // Vérification des champs obligatoires
    if (!formValues.name || !datenaissance || !formValues.telephone || !formValues.email || !formValues.cin || !cinExpire || !formValues.numeroPermis || !permisExpire || !formValues.adresse || !formValues.ville) {
      setErrorMessage('Veuillez remplir tous les champs obligatoires.');
      setShowErrorMessage(true);

      // Réinitialisation du message d'erreur après 3 secondes
      setTimeout(() => {
        setShowErrorMessage(false);
        setErrorMessage('');
      }, 3000);

      return;
    }

    const formData = new FormData();
    formData.append('photoCin', selectedPhotoCin);
    formData.append('photoPermis', selectedPhotoPermis);
    formData.append('datenaissance', dayjs(datenaissance).format('DD/MM/YYYY'));
    formData.append('permisExpire', dayjs(permisExpire).format('DD/MM/YYYY'));
    formData.append('cinExpire', dayjs(cinExpire).format('DD/MM/YYYY'));
    // Append other form values to the formData object
    Object.entries(formValues).forEach(([key, value]) => {
      if (key !== 'datenaissance') {
        formData.append(key, value);
      }
    });

    axios
      .post(`${url}/api/create/client/${sessionStorage.getItem('agenceId')}`, formData, { headers })
      .then((response) => {
        console.log(response.data);
        setOpen(false);

        // Afficher le message de succès
        setSuccessMessage('Le client a été ajouté avec succès.');
        setShowSuccessMessage(true);

        // Réinitialisation du message de succès après 3 secondes
        setTimeout(() => {
          setShowSuccessMessage(false);
          setSuccessMessage('');
        }, 3000);
        setFormValues({
          name: '',
          telephone: '',
          email: '',
          cin: '',
          numeroPermis: '',
          adresse: '',
          ville: ''
        });
        setDatenaissance('');
        setCinExpire('');
        setPermisExpire('');
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response.data);
      });
  };
  

return (
<Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box
            sx={{
              ...style,
              width: '100%',
              maxWidth: '1000px',
              height: '100%',
              maxHeight: '650px',
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Ajoutez cette ligne pour aligner les éléments à droite
            }}
          >

            <Button
              onClick={handleClose}
              sx={{ alignSelf: 'flex-end' }} // Ajoutez cette ligne pour aligner le bouton à droite
            >
              <CloseIcon />
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{
                mt: 2,
                color: 'rgb(68, 125, 248)',
                textAlign: 'center',
                fontWeight: 'lighter',
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                borderBottom: '1px solid rgb(80, 80, 80)',
                paddingBottom: '10px',
                marginTop: '10px'
              }}
            >
              Ajouter Client
            </Typography>


            <Box
              sx={{
                width: '100%',
                maxHeight: 'calc(100 -80 )', // Adjust the value to leave space for the header and footer
                overflowY: 'auto', // Enable vertical scrolling
              }}
            >
              {showErrorMessage && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                  {errorMessage}
                </Typography>
              )}

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Nom et Prénom"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Date de Naissance"
                        required
                        name="dateNaissance"
                        format="DD/MM/YYYY"

                        value={datenaissance}
                        onChange={(date) => setDatenaissance(date)}
                        // error={!!formErrors.datenaissance} // Set the error prop based on the presence of error for this field
                        // helperText={formErrors.name}
                        sx={{ mt: 1, width: '100%' }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Telephone"
                    name="telephone"
                    value={formValues.telephone}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="E-mail"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Cin"
                    name="cin"
                    value={formValues.cin}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Cin Expire"
                        required
                        name="cinExpire"
                        format="DD/MM/YYYY"
                        // value={formValues.datenaissance}
                        value={cinExpire}
                        onChange={(date) => setCinExpire(date)}
                        // error={!!formErrors.datenaissance} // Set the error prop based on the presence of error for this field
                        // helperText={formErrors.name}
                        sx={{ mt: 1, width: '100%' }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Numéro Permis"
                    name="numeroPermis"
                    value={formValues.numeroPermis}
                    onChange={handleChange}
                    // error={!!formErrors.numeroPermis} // Set the error prop based on the presence of error for this field
                    // helperText={formErrors.numeroPermis}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Numéro Permis Expire"
                        required
                        name="permisExpire"
                        format="DD/MM/YYYY"
                        sx={{ mt: 1, width: '100%' }}
                        value={permisExpire}
                        onChange={(date) => setPermisExpire(date)}
                      // error={!!formErrors.permisExpire}
                      // helperText={formErrors.permisExpire}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Adresse"
                    name="adresse"
                    sx={{ mt: 2, width: '100%' }}
                    value={formValues.adresse}
                    onChange={handleChange}
                  // error={!!formErrors.adresse}
                  // helperText={formErrors.adresse}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Ville"
                    name="ville"
                    sx={{ mt: 2, width: '100%' }}
                    value={formValues.ville}
                    onChange={handleChange}
                  // error={!!formErrors.ville}
                  // helperText={formErrors.ville}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="file"
                    label="Photo Cin"
                    sx={{ mt: 2, width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    accept="image/*"
                    name="photoCin"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="file"
                    label="Photo Permis"
                    sx={{ mt: 2, width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    accept="image/*"
                    name="photoPermis"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', marginBottom: '4rem' }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px' }}
                  sx={{
                    width: '200px',
                    height: '30px',
                    backgroundColor: '#eeeff5', // Couleur de fond
                    color: '#dddfe7', // Couleur du texte
                    fontWeight: 'bold',
                    textTransform: 'uppercase', // Transformation du texte en majuscules
                    padding: '12px 24px', // Espacement interne du bouton
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                    transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                    '&:hover': {
                      backgroundColor: '#3f51b5', // Couleur de survol
                    },
                  }}
                >
                  Ajouter
                </Button>
              </div>
            </Box>
          </Box>
        </Modal>
)

}

export default AddClientModal;