import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./components/Login";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import ListClient from './components/ListClient'
import ListContrat from "./components/ListeContart";
import AddVoiture from "./components/Modals/voitures/AddVoiture";
import UpdateCar from "./components/Modals/voitures/UpdateCar";
import VoituresNonDisponible from "./components/Voitures-non-disponible";
import VoituresDisponible from "./components/Voitures-disponible";
import AppMap from "./components/AppMap";
import Garage from "./components/Garage";
import VoituresArchived from "./components/Voitures-archived";
import Navbar from "./components/Navbar";
import Parametre from "./components/Parametre";
import DashboardAdmin from "./components/Admin/DashboardAdmin";
import Services from "./components/Admin/Services"; 
import DetailService from "./components/Admin/DetailService";
import Agences from "./components/Admin/Agences";
import AgencesLocation from "./components/Admin/AgencesLocation";
import Evaluationclient from "./components/Modals/clients/Evaluationclient";
import { useEffect } from "react";
import ContractsToBePaid from "./components/Admin/ContractsToBePaid";
import NavbarAdmin from "./components/Admin/NavbarAdmin";
import Service from './components/Admin/Service'
import Localisation from "./components/Localisation";
import Users from "./components/Admin/Users";


export default function App() {
  const styledDiv = {
    position: "relative",
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(sessionStorage.getItem("role"));

  const token = sessionStorage.getItem('token');
  const refreshToken = sessionStorage.getItem('refreshToken');

  // Function to handle login and set the authentication state
  const handleLogin = (role, token) => {
    localStorage.setItem('role', role);
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setUserRole(role);
  };
  
  const checkAuth = () => {
    // const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    if (token && role) {
      setIsAuthenticated(true);
      setUserRole(role);
    }
  };
  
  // Appel de la fonction de vérification lors du chargement de la page
  useEffect(() => {
    checkAuth();
  }, []);
  
  // Function to handle logout and reset the authentication state
  const handleLogout = () => {
    sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
    setUserRole('');
  };
  console.log('token : '+ token) ; 
  console.log( ' refToken : ' +  refreshToken) ;

  

  return (
    <div style={styledDiv}>
      <Routes>
    <Route path="/" element={<Login onLogin={handleLogin} />} />
        {token}
          {(token ) && (
            <>
              
              {userRole === "ROLE_SUPER_ADMIN" && (
              <>
                <Route
                  path="/dashboardAdmin"
                  element={
                    token  ? (
                      <DashboardAdmin />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/services"
                  element={
                    token  ? (
                      <Services
                        isAuthenticated={isAuthenticated}
                       
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/service"
                  element={
                    token  ? (
                      <Service
                        isAuthenticated={isAuthenticated}
                        
                      />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                <Route
                  path="/detailservice/:id"
                  element={
                    token ? (
                      <DetailService onLogout={handleLogout} />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
                 
                <Route path="/listecontratab" element={isAuthenticated ? <Agences /> : <Navigate to="/" />} />
                <Route exact path="/agences" element={isAuthenticated ? <AgencesLocation /> : <Navigate to="/" />} />
                <Route exact path="/users" element={isAuthenticated ? <Users /> : <Navigate to="/" />} />
                <Route exact path="/contractstobepayed" element={isAuthenticated ? <ContractsToBePaid /> : <Navigate to="/" />} />
                <Route path="/navbaradmin" element={isAuthenticated ? <NavbarAdmin/>: <Navigate to="/"/>} />


              </>
            )}
      
          
        
              {userRole === "ROLE_ADMIN" && (
                <>
                  <Route path="/dashboard" element={token ? <Dashboard /> : <Navigate to="/" />} />
                  <Route path="/listeClients" element={token  ? <ListClient /> : <Navigate to="/" />} />
                  <Route path="/voitures-disponibles" element={token ? <VoituresDisponible />: <Navigate to="/" />} />
                  <Route path="/voitures-non-disponibles" element={token  ? <VoituresNonDisponible />: <Navigate to="/" />} />
                  <Route path="/archived" element={token  ? <VoituresArchived />: <Navigate to="/" />} />
                  <Route path="/sidebar" element={token  ? <Sidebar />: <Navigate to="/"/>} />
                  <Route path="/create" element={token  ? <AddVoiture />: <Navigate to="/"/>} />
                  <Route path="/updatevoiture/:voitureId" element={token  ? <UpdateCar/>: <Navigate to="/"/>} />
                  <Route path="/garage" element={token ? <Garage/>: <Navigate to="/"/>} />
                  <Route path="/navbar" element={token ? <Navbar/>: <Navigate to="/"/>} />
                  <Route exact path="/listeContrat" element={token  ?<ListContrat /> : <Navigate to="/" />} />
                  <Route  path="/evaluation-client" element={token  ? <Evaluationclient /> : <Navigate to="/" />} />
                  <Route path="/profil" element={token ? <Parametre/>: <Navigate to="/" />} />
                  <Route path="/localisation" element={token ? <Localisation/>: <Navigate to="/" />} />
                  {/* Ajoutez d'autres routes en fonction du rôle de l'utilisateur */}
                </>
              )}
            </>
          )}
              
          {/* Redirigez vers la page de connexion si aucune route ne correspond */}
          <Route path="*" element={<Navigate to="/" />} />
          
        </Routes>

      {/* Rendre la barre latérale en fonction de l'authentification et du rôle de l'utilisateur */}
      
    </div>
  );
}



{/* <div style={styledDiv}>

<Routes>
  <Route path="/" element={<Login onLogin={handleLogin} />} />
  <Route path="/dashboard" element={<Dashboard onLogout={handleLogout} />} />
  <Route path="/Voitures" element={<Voitures onLogout={handleLogout} />} />
  <Route path="/voitures-disponibles" element={<VoituresDisponible onLogout={handleLogout} />} />
  <Route path="/voitures-non-disponibles" element={<VoituresNonDisponible onLogout={handleLogout} />} />
  <Route path="/archived" element={<VoituresArchived onLogout={handleLogout} />} />
  <Route path="/create" element={<AddVoiture onLogout={handleLogout} />} />
  <Route path="/test" element={<Test onLogout={handleLogout} />} />
  <Route path="/updatevoiture/:voitureId" element={<UpdateCar onLogout={handleLogout} />} />
  <Route exact path="/login" element={<Login />} />
  <Route exact path="/dashboard" element={<Dashboard />} />
  <Route exact path="/sidebar" element={<Sidebar />} />
  <Route exact path="/listeClients" element={<ListClient />} />
  <Route exact path="/listeContrat" element={<ListContrat />} />
  <Route exact path="/AjouterContrat" element={<AjouterContart />} />
  <Route path="/garage" element={<Garage onLogout={handleLogout} />} />
  <Route path="/tracker" element={<AppMap />} />
</Routes>

{/* {showSidebar && isAuthenticated && <Sidebar />}  */}
{/* {!isAuthenticated && location.pathname !== "/" && <Navigate to="/" replace />} *

</div> */}