import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import  SidebarAdmin from '../Admin/SidebarAdmin';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from '../../api/axios';
import Navbar from "../Navbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import AjouterContrat from './AjouterContrat';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from "@mui/icons-material/Edit";
import NavbarAdmin from './NavbarAdmin';
import url from '../../api/serverUrl';


const DetailService = ( ) => {
    const { id } = useParams();
    const [  services , setServices] = useState([]);
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${url}/api/super-admin/services/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(res => {
        setServices(res.data);
        console.log(res.data);
      })
      .catch(error => {
        console.error('Error retrieving client list:', error);
      });
    
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [contrats, setContrats] = useState([]);
  const [showModalupdate, setShowModalupdate] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);

  useEffect(() => {
    getContrats();
  }, []);

  const getContrats = () => {
    const token = sessionStorage.getItem('token');
    axios
      .get(`${url}/api/super-admin/contrats-by-service/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setContrats(response.data);
       
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
        
      });
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModalUp = (contract) => {
    setSelectedContract(contract);
    setShowModalupdate(true);
  };
  
  const handleCloseModalUp = () => {
    setSelectedContract(null);
    setShowModalupdate(false);
      
  const handleContractAdded = () => {
    // This function will be called after a new contract is successfully added.
    // It will update the contracts list.
    getContrats();
  };
  const handleContractUpdated = () => {
    // Ensuite, fermez le modal de mise à jour.
    handleCloseModalUp();
  };
  };
  return (
    <>
       <SidebarAdmin />
       <NavbarAdmin />
      <Section>
      <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px' }}>
          <h2 className="text-center" style={{ fontSize: '24px', color: '#333', margin: '0', padding: '10px', borderBottom: '2px solid blue', width: '50%', margin: 'auto' }}>
          {services.service}
          </h2>
        </div>
 
    <div className="row" >
    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gridColumnGap: "5px",
                        marginTop:"30px",
                        height:"90px",
                        backgroundColor:"#F2F5FA",
                      }}>

                        <Paper
                          component="form"
                          sx={{
                            p: '2px 4px',
                            display: 'flex',
                            width: '120%', 
                            height:'37px',
                            maxWidth: '500px',
                            marginTop: '20px'
                             /* Ajouter une largeur maximale pour que le formulaire ne dépasse pas une certaine taille */
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                          />
                          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                          </IconButton>
                          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                        </Paper>

                        <button
                          onClick={handleOpenModal}
                         className="btn btn-primary"
                         style={{
                           gridColumn: "4",
                           width: "100%", /* Modifier la largeur pour qu'elle soit de 100% */
                           maxWidth: "200px", /* Ajouter une largeur maximale pour que le lien ne dépasse pas une certaine taille */
                           backgroundColor: "#2769D5",
                           p: '2px 4px',
                           display: 'auto',
                           height:'37px',
                           marginTop: '20px'
                         }}
                        >
                          Ajouter Contrat
                        </button>
                         {/* Conditionnellement afficher la modale en fonction de l'état showModal */}
                       
                      
                         {showModal && <AjouterContrat onClose={handleCloseModal} service={services}/>}
                      </div>
                    
      <div className="table-grid">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>Nom Agence</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Email</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Télephone</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Service</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Date_début</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Date_fini</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Motant_total</span>
                  </TableCell>
                  {/* <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Action</span>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
              {contrats.map((contrat) => (
                <TableRow key={contrat.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover role="checkbox" tabIndex={-1}>
                  <TableCell component="th" scope="row">{contrat.agenceNom}</TableCell>
                  <TableCell align="left">{contrat.agenceEmail}</TableCell>
                  <TableCell align="left">{contrat.agenceTelephone}</TableCell>
                  <TableCell align="left">{contrat.serviceNom}</TableCell>
                  <TableCell align="left">{contrat.dateDebut.date.substring(0, 10)}</TableCell>
                  <TableCell align="left">{contrat.dateFin.date.substring(0, 10)}</TableCell>
                  <TableCell align="left">{contrat.montantToltal} DH</TableCell>
                  {/* <TableCell align="left"  onClick={() => handleOpenModalUp(contrat)} >
                    <EditIcon />

                  </TableCell> */}

                </TableRow>
                
              ))}

              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      </div>
      </Section>
    </>
  );
};

export default DetailService;

const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;
