import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import styled from "styled-components";
import Sidebar from '../../Sidebar';
import url from '../../../api/serverUrl';

const UpdateCar = ({ carId }) => {
  const [car, setCar] = useState({});
  const [formData, setFormData] = useState({
    matricule: '',
    tarif: '',
    kilometrage: '',
    couleur: '',
    disponibilite: '',
    moteur: '',
    nombrePortes: 0,
    nombreValises: 0,
    boiteVitesse: '',
    climatisation: '',
    marque: '',
    modele: '',
    franchiseAccident: 0,
    franchiseVol: 0,
    archived: false,
    categorie_id: '',
    garage_id: '',
    galeries: [],
  });
  const [garages, setGarages] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get(`${url}/api/Show/Voiture/${carId}`)
      .then(response => {
        const carData = response.data.voiture;
        setCar(carData);
        setFormData({
          matricule: carData.matricule,
          tarif: carData.tarif,
          kilometrage: carData.kilometrage,
          couleur: carData.couleur,
          disponibilite: carData.disponibilite,
          moteur: carData.moteur,
          nombrePortes: carData.nombrePortes,
          nombreValises: carData.nombreValises,
          boiteVitesse: carData.boiteVitesse,
          climatisation: carData.climatisation,
          marque: carData.marque,
          modele: carData.modele,
          franchiseAccident: carData.franchiseAccident,
          franchiseVol: carData.franchiseVol,
          archived: carData.archived,
          categorie_id: carData.categorie_id,
          garage_id: carData.garage_id,
          galeries: carData.galeries,
        });
      })
      .catch(error => {
        console.error(error);
      });
  }, [carId]);

  useEffect(() => {
    axios.get(`${url}/api/list/Garage`)
      .then(response => {
        setGarages(response.data.garages);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${url}/api/list/Categorie`)
      .then(response => {
        setCategories(response.data.categories);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = event => {
    const selectedFiles = event.target.files;

    setFormData(prevState => ({
      ...prevState,
      galeries: [...selectedFiles],
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    
    axios.put(`${url}/api/update/${carId}`, formData)
      .then(response => {
        console.log(response.data);
        // Afficher un message de succès ou effectuer une action spécifique
        alert('La voiture a été mise à jour avec succès');
      })
      .catch(error => {
        console.error(error);
        // Afficher un message d'erreur ou gérer l'erreur d'une autre manière
        alert('Une erreur s\'est produite lors de la mise à jour de la voiture');
      });
  };


  return (
      
    <>
    <Sidebar />
    <Section>
<div>
<h2  style={{color: "#8190D5"}}>Modifier</h2>

    
      
        <form onSubmit={handleSubmit}>
          
         <div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="marque" name="marque" label="Marque"  variant="filled" value={formData.marque} onChange={handleChange}  required/>
              <TextField id="modele" name="modele" label="Modèle"  variant="filled" value={formData.modele} onChange={handleChange}  required/>
              <TextField id="matricule" name="matricule" label="Matricule"  variant="filled" value={formData.matricule} onChange={handleChange}  required/>
             
           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="couleur" name="couleur" label="Couleur"  variant="filled" value={formData.couleur} onChange={handleChange}  required/>
              <TextField id="kilometrage" name="kilometrage" label="Kilométrage" variant="filled" value={formData.kilometrage} onChange={handleChange}  required/>
              <TextField id="moteur" name="moteur" label="Moteur"  variant="filled" value={formData.moteur} onChange={handleChange}  required/>
             
           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="nombrePortes" name="nombrePortes" label="Nombre de Portes"  variant="filled" value={formData.nombrePortes} onChange={handleChange}  required/>
              <TextField id="nombreValises" name="nombreValises" label="Nombre de Valises" variant="filled" value={formData.nombreValises} onChange={handleChange}  required/>
              <TextField id="boiteVitesse" name="boiteVitesse" label="Boite de Vitesse"  variant="filled" value={formData.boiteVitesse} onChange={handleChange}  required/>
             
           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="climatisation" name="climatisation" label="Climatisation"  variant="filled" value={formData.climatisation} onChange={handleChange}  required/>
              <TextField id="franchiseAccident" name="franchiseAccident" label="Franchise en cas d'Accident" variant="filled" value={formData.franchiseAccident} onChange={handleChange}  required/>
              <TextField id="franchiseVol" name="franchiseVol" label="franchise en cas de Vol"  variant="filled" value={formData.franchiseVol} onChange={handleChange}  required/>
             
           </div><br/>
          
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
               <TextField id="tarif" name="tarif" label="Tarif"  variant="filled" value={formData.tarif} onChange={handleChange}  required/>
              <TextField id="disponibilite" name="disponibilite" label="Disponibilite"  variant="filled" value={formData.disponibilite} onChange={handleChange} required/>
              <TextField id="garage_id" name="garage_id" select label="Garage" value={formData.garage_id} onChange={handleChange}  required >
                {garages.map((garage) => (
                  <MenuItem key={garage.id} value={garage.id}>
                    {garage.nameG}
                  </MenuItem>
                ))}
              </TextField>
           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="categorie_id" name="categorie_id" select label="Categorie" value={formData.categorie_id} onChange={handleChange}  required >
                {categories.map((categorie) => (
                  <MenuItem key={categorie.id} value={categorie.id}>
                    {categorie.name}
                  </MenuItem>
                ))}
              </TextField>
            </div><br/>   
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <label htmlFor="assurance">Assurance:</label>
              <input type="file" id="assurance" name="assurance" onChange={this.handleAssuranceChange} />
            </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <label htmlFor="image">Image:</label>
              <input type="file" id="image" name="image" onChange={this.handleImageChange} />
            </div> <br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <label htmlFor="galeries">Galerie:</label>
              <input type="file" id="galeries" name="galeries" multiple onChange={this.handleGaleriesChange} />
           </div><br/>
            
          </div>
          
          <button type="submit" className="btn btn-primary" style={{ gridColumn: "3",width:"200px",backgroundColor:"#8190D5"}}>Modifier</button>

           
      
        </form>
     
      </div>
      </Section>
  </>
    
  );
};
const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }

`;
export default UpdateCar;