import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%'
};

function AppMap() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    getLocation();
    const interval = setInterval(getLocation, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateLocation);
    }
  };

  const updateLocation = (position) => {
    const { latitude, longitude } = position.coords;
    setLatitude(latitude);
    setLongitude(longitude);
  };

  return (
    <div>
      {latitude && longitude && (
        <Map
          google={window.google}
          zoom={14}
          style={mapStyles}
          initialCenter={{
            lat: latitude,
            lng: longitude
          }}
        >
          <Marker position={{ lat: latitude, lng: longitude }} />
        </Map>
      )}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: ' AIzaSyD7gPr0PSBGCe4qqNV02zIhwcIqlJZKHCE' // Remplacez par votre propre clé d'API
})(AppMap);

 