import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import url from '../api/serverUrl';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false); // Nouvelle variable d'état

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${url}/api/forgot-password`, {
        email: email,
      });

      setEmailSent(true);
      setError('');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div>
      <h2 style={{color:"#8190D5"}}>Forgot Password</h2>
      {emailSent ? (
        <p>A password reset email has been sent to your email address.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            
            <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email:
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                />
                </div>
          </div><br/>
          <div>
            <button type="submit"  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Reset Password</button>
          </div><br/>

          {error && <p>{error}</p>}
        </form>
      )}
    </div>
  );
};

export default ForgetPassword;
