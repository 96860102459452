import axios from "axios";
import url from '../api/serverUrl';



export const getVoitures = async (agenceId, token) =>  {

      let voitures = null;

      await axios
      .get(`${url}/api/list/Voitures/${agenceId}`, {
          headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {

          voitures = response.data;
          console.log(voitures);

      })
      .catch((error) => {
          // Gérez les erreurs de requête
          console.error(error);  // cette partie c'est a regler !!! 
      });

      return voitures;
}

export const getContras =  async (token, agenceId) => {

  
    let data = null;
    await  axios({
      method: 'GET',
      url: `http://127.0.0.1:8000/api/contrat/liste/${agenceId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
       data = res.data;
       console.log(data);
      })
      .catch((error) => {
        console.error('Error retrieving contrat list:', error);
        
       
      });
      return data;
  };

  function isDateInRange(startDateStr, endDateStr) {
    const currentDate = new Date();
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
  
    return startDate <= currentDate && currentDate <= endDate;
  }

export const getContratParVoiture = (contras, voitureId)=> {
  let contrat = null;
  contrat = contras.filter( (data)=>data.voiture.id === voitureId && isDateInRange(data.datesortie, data.dateretoure))[0];
  return contrat;
  
}


export const voitureDispo = (contras, voitureId, token)=> {
  let contrat = null;
  let formData = {}
  contrat = contras.filter( (data)=>data.voiture.id === voitureId && isDateInRange(data.datesortie, data.dateretoure))[0];
  const existContratInDate =  contrat ? true : false;
  console.log(existContratInDate);
  if(!existContratInDate){

    axios.get(`${url}/api/Show/Voiture/${voitureId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        const carData = response.data;
        console.log(response.data);
        console.log(carData);
        formData = {
          matricule: carData.matricule,
          tarif: carData.tarif,
          kilometrage: carData.kilometrage,
          couleur: carData.couleur,
          disponibilite: 'disponible',
          moteur: carData.moteur,
          nombrePortes: carData.nombrePortes,
          nombreValises: carData.nombreValises,
          boiteVitesse: carData.boiteVitesse,
          climatisation: carData.climatisation,
          marque: carData.marque,
          modele: carData.modele,
          franchiseAccident: carData.franchiseAccident,
          franchiseVol: carData.franchiseVol,
          archived: carData.archived,
          categorie_id: carData.categorie_id,
          garage_id: carData.garage_id,
          galeries: carData.galeries,
        };
        console.log(formData);
        updateVoitureDispo(voitureId, formData, token);
      })
      .catch(error => {
        console.error(error);
      });

  }
}

export const voitureNonDispo = (contras, voitureId, token)=> {
  let contrat = null;
  let formData = {}
  contrat = contras.filter( (data)=>data.voiture.id === voitureId && isDateInRange(data.datesortie, data.dateretoure))[0];
  const existContratInDate =  contrat ? true : false;
  console.log(existContratInDate);
  if(existContratInDate){

    axios.get(`${url}/api/Show/Voiture/${voitureId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        const carData = response.data;
        console.log(response.data);
        console.log(carData);
        formData = {
          matricule: carData.matricule,
          tarif: carData.tarif,
          kilometrage: carData.kilometrage,
          couleur: carData.couleur,
          disponibilite: 'non-disponible',
          moteur: carData.moteur,
          nombrePortes: carData.nombrePortes,
          nombreValises: carData.nombreValises,
          boiteVitesse: carData.boiteVitesse,
          climatisation: carData.climatisation,
          marque: carData.marque,
          modele: carData.modele,
          franchiseAccident: carData.franchiseAccident,
          franchiseVol: carData.franchiseVol,
          archived: carData.archived,
          categorie_id: carData.categorie_id,
          garage_id: carData.garage_id,
          galeries: carData.galeries,
        };
        console.log(formData);
        updateVoitureDispo(voitureId, formData, token);
      })
      .catch(error => {
        console.error(error);
      });

  }
}

export const updateVoitureDispo = (voitureId, formData, token) => {
  console.log(voitureId);
  axios.put(`${url}/api/update/disponibilite/${voitureId}`, formData,  {
    headers: { Authorization: `Bearer ${token}` },
  })
      .then(response => {
        console.log(response.data);
        
      })
      .catch(error => {
        console.error(error);
        
      });
}

export const updateVoitureDisponibilite = async (agenceId, token)=> {
  const voitures =  await getVoitures(agenceId, token);
  const contrats =  await getContras(token, agenceId);
  
  console.log(voitures);
  console.log(contrats);
  if(voitures && contrats){
    voitures.forEach( (voiture) => {
       if(voiture.disponibilite === "non-disponible"){
         voitureDispo(contrats, voiture.id, token); // Await the promise to update disponibilite
       }
       if(voiture.disponibilite === "disponible"){
        voitureNonDispo(contrats, voiture.id, token);
       }
    });
  }
  console.log("done");
  

}


export const getAgenceList = async (token)=> {
      let agenceList = [];
      await axios
      .get(`${url}/api/Agence/liste`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        agenceList = response.data;
        
      })
      .catch((error) => {
        console.error(error);
      });
      console.log(agenceList);
      return agenceList;
}

