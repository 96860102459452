import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from "styled-components";
import moment from 'moment';
import SidebarAdmin from "../Admin/SidebarAdmin";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import AjouterContrat from './AjouterContrat';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from "@mui/icons-material/Edit";
import UpdateContratAb from './UpdateContratAb';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AjouterAgence from './AjouterAgence';
import Navbar from '../Navbar';
import UpdateAgence from './UpdateAgence';
import NavbarAdmin from './NavbarAdmin';
import url from '../../api/serverUrl';

const AgencesLocation = () => {
    const [agences, setAgences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModalupdate, setShowModalupdate] = useState(false);
    const [selectedagence, setSelectedagence] = useState(null);
    const [isAddSuccess, setIsAddSuccess] = useState(false);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

    useEffect(() => {
        getAgences();
      }, []);
    const getAgences = () => {
        const token = sessionStorage.getItem('token');
        axios
          .get(`${url}/api/Agence/liste`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setAgences(response.data);
            setLoading(false);
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      };
      const handleOpenModal = () => {
        setShowModal(true);
      };
    
      const handleCloseModal = () => {
        setShowModal(false);
      };
      const handleAgenceAdded = () => {
        getAgences();
        setIsAddSuccess(true);
        // Réinitialiser le message de succès après 30 secondes
        setTimeout(() => {
          setIsAddSuccess(false);
        }, 30000);
       
      };

      const handleOpenModalUp = (agence) => {
        setSelectedagence(agence);
        setShowModalupdate(true);
      };
      
      const handleCloseModalUp = () => {
        setSelectedagence(null);
        setShowModalupdate(false);
      };
      const handleAgenceUpdated = () => {
        getAgences();
        setIsUpdateSuccess(true);
        // Réinitialiser le message de succès après 30 secondes
        setTimeout(() => {
          setIsUpdateSuccess(false);
        }, 30000);
        handleCloseModalUp();
      };
  return (
    <>
    <SidebarAdmin />
    <NavbarAdmin />
    <Section>
    <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px'}}>
                <h2 className="text-center" style={{ fontSize: '24px',color: '#333',margin: '0', padding: '10px', borderBottom: '2px solid blue',width: '50%', margin: 'auto'}}>
                Agences engagées à notre service
                </h2>
    </div>
    <div className="row" >
    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gridColumnGap: "5px",
                        marginTop:"30px",
                        height:"90px",
                        backgroundColor:"#F2F5FA",
                      }}>

                        <Paper
                          component="form"
                          sx={{
                            p: '2px 4px',
                            display: 'flex',
                            width: '120%', 
                            height:'37px',
                            maxWidth: '500px',
                            marginTop: '20px'
                             /* Ajouter une largeur maximale pour que le formulaire ne dépasse pas une certaine taille */
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                          />
                          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                          </IconButton>
                          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                        </Paper>

                        <button
                         onClick={handleOpenModal}
                         className="btn btn-primary"
                         style={{
                           gridColumn: "4",
                           width: "100%", /* Modifier la largeur pour qu'elle soit de 100% */
                           maxWidth: "200px", /* Ajouter une largeur maximale pour que le lien ne dépasse pas une certaine taille */
                           backgroundColor: "#2769D5",
                           p: '2px 4px',
                           display: 'auto',
                           height:'37px',
                           marginTop: '20px'
                         }}
                        >
                          Ajouter Agence
                        </button>
                        {/* Conditionnellement afficher la modale en fonction de l'état showModal */}
                       
                      
                        {showModal && <AjouterAgence onClose={handleCloseModal} onContractAdded={handleAgenceAdded} />}
                      </div>
       {/* alert succés     */}
       <Snackbar
        open={isAddSuccess || isUpdateSuccess}
        autoHideDuration={6000} // Durée en millisecondes pour masquer automatiquement la boîte d'alerte
        onClose={() => {
          setIsAddSuccess(false);
          setIsUpdateSuccess(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsAddSuccess(false);
            setIsUpdateSuccess(false);
          }}
          severity="success" // Type d'alerte : "success", "error", "warning" ou "info"
          sx={{ width: '100%' }}
        >
          {isAddSuccess && 'L\'agence a été ajouté avec succès.'}
          {isUpdateSuccess && 'L\'agence a été modifié avec succès.'}
        </Alert>
      </Snackbar>
      <div className="table-grid">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>Nom Agence</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Email</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Télephone</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Adresse</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>pays</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Ville</span>
                  </TableCell>
                  
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {agences.map((agence) => (
                <TableRow key={agence.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover role="checkbox" tabIndex={-1}>
                  <TableCell component="th" scope="row">{agence.nomLocation}</TableCell>
                  <TableCell align="left">{agence.email}</TableCell>
                  <TableCell align="left">{agence.telephone}</TableCell>
                  <TableCell align="left">{agence.adresse}</TableCell>
                  <TableCell align="left">{agence.pays}</TableCell>
                  <TableCell align="left">{agence.ville}</TableCell>
                  <TableCell align="left" onClick={() => handleOpenModalUp(agence)}   >
                    <EditIcon />

                  </TableCell>

                </TableRow>
                
              ))}

              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      </div>
      </Section>
      {showModalupdate && selectedagence && (
        <UpdateAgence
          agence={selectedagence}
          Id={selectedagence.id}
          onClose={handleCloseModalUp}
          onAgenceUpdated={handleAgenceUpdated}
        />
      )}
      </>
  );
};

const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;
export default AgencesLocation;
