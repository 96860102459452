import React, { Component } from 'react';
import axios from "axios";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Navbar from './Navbar';
import TablePagination from '@mui/material/TablePagination';
import url from '../api/serverUrl';

class Garage extends Component {
    constructor() {
        super();
        this.state = {
          garages: [], // Initialisez la propriété garages avec un array vide
          loading: true,//pour indiquer que les données sont en cours de chargement
          showAddForm: false,//pour contrôler l'affichage des formulaires d'ajout et de modification
          showEditForm: false,
          editingGarageId: null,//pour stocker l'identifiant de la voiture en cours de modification
          searchQuery: "",
          page: 0,
          rowsPerPage: 10,
          isAddSuccess:false,
          isUpdateSuccess:false,
        };
      }
     
    
      handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
      };
      
      handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
      };
      
      handleSearchQueryChange = (event) => {
        const searchQuery = event.target.value;
        this.setState({ searchQuery });
      };
      getGarageById(id) {
        return this.state.garages.find((garage) => garage.id === id);
      }

      handleSubmit = (event) => {
        event.preventDefault();
    
        // Récupérez les valeurs des champs du formulaire
        const nameG = event.target.elements.nameG.value;
        const adresse = event.target.elements.adresse.value;
        const capacity = event.target.elements.capacity.value;

    
        // Effectuez une requête pour ajouter la nouvelle voiture
        const token = sessionStorage.getItem("token");
        const garageId = this.state. editingGarageId;
    
        // Effectuer une requête PUT pour mettre à jour le garage
        if (this.state.showEditForm) {
          axios
            .put(
              `${url}/api/edit/garage/${garageId}`,
              { nameG, adresse, capacity },
              { headers: {
                Authorization : `Bearer ${token}`
              } }
            )
            .then((response) => {
              //  garage a été mise à jour avec succès, effectuer les actions nécessaires
              // (par exemple, actualiser la liste des garages)
              this.getGarages();
              // Fermer le formulaire de modification
              this.toggleEditForm(null);
              // Afficher l'alerte de succès
              this.setState({ isUpdateSuccess: true });
              setTimeout(() => {
                this.setState({ isUpdateSuccess: false });
              }, 30000);
            })
            .catch((error) => {
              // Gérez les erreurs de requête
            console.error(error);
          
            });
        } else {
          const formData = new FormData();
        
            
            formData.append('nameG', nameG);
            formData.append('adresse', adresse);
            formData.append('capacity', capacity);
           
          axios
          .post(`${url}/api/add/garage/${sessionStorage.getItem('agenceId')}` ,  formData,
              { headers: { "Authorization": `Bearer ${token}` } }
            )
            .then((response) => {
              // La voiture a été ajoutée avec succès, effectuez les actions nécessaires (par exemple, actualiser la liste des garages)
              this.getGarages();
              // Réinitialisez les valeurs des champs du formulaire si nécessaire
              event.target.reset();
              // Fermer le formulaire de modification
              this.toggleAddForm(null);
               // Afficher l'alerte de succès
               this.setState({ isAddSuccess : true });
               setTimeout(() => {
                this.setState({isAddSuccess: false });
              }, 30000);
             
            })
            .catch((error) => {
              // Gérez les erreurs de requête
              console.error(error);
              if (error.response && error.response.data && error.response.data.error) {
                // Set the error message from the API response in the component state
                this.setState({ errorMessage: error.response.data.error });
              }
            });
        }
      };

      toggleAddForm = () => {
        this.setState((prevState) => ({
          showAddForm: !prevState.showAddForm,
        }));
      };
    
      toggleEditForm = (id) => {
        this.setState((prevState) => ({
          showEditForm: !prevState.showEditForm,
          editingGarageId: id,
        }));
      };
      //est un cycle de vie du composant appelé immédiatement après que le composant a été inséré dans l'arbre DOM.
    componentDidMount() {
        this.getGarages();
    }
     //forme modal
  openModal = () => {
    if (this.state.showEditForm) {
      this.setState({ modalEditOpen: true });
    } else {
      this.setState({ modalOpen: true });
    }
  };

  closeModal = () => {
    this.setState({ modalOpen: false, editModalOpen: false });
  };

 
     //get liste des garages
     getGarages() {
      const token = sessionStorage.getItem("token");
    
      axios
        .get(`${url}/api/liste/garage/${sessionStorage.getItem('agenceId')}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (response) => {
          const garages = response.data.map(async (garage) => {
            const voituresResponse = await axios.get(
              `${url}/api/garage/${garage.id}/voitures`,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            const voitures = voituresResponse.data;
            return { ...garage, voitures };
          });
    
          Promise.all(garages)
            .then((garagesWithVoitures) => {
              this.setState({ garages: garagesWithVoitures, loading: false });
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    
    
    
    handleChange = (e) => {
      this.setState({ searchQuery: e.target.value });
    };

    filterGarages = () => {
      const { garages, searchQuery } = this.state;
    
      // Filtrer les garages en fonction de la requête de recherche de le nom de garage
      const filteredgarages = garages.filter((garage) => {
        return garage.nameG.toLowerCase().includes(searchQuery.toLowerCase());
      });
    
      return filteredgarages;
    };
    render() {
        const loading = this.state.loading;
        const showAddForm = this.state.showAddForm;
        const showEditForm = this.state.showEditForm;
        const  editingGarageId = this.state.editingGarageId;
        const searchQuery = this.state;
        // Filtrer les voitures en fonction de la requête de recherche
        const filteredgarages = this.filterGarages();
        const showSuccessAlert  = this.state;
        const { page, rowsPerPage } = this.state;
        const { errorMessage } = this.state;
        const {isAddSuccess,isUpdateSuccess}=this.state
        const handleChangePage = (event, newPage) => {
          this.handleChangePage(event, newPage);
        };
        
        const handleChangeRowsPerPage = (event) => {
          this.handleChangeRowsPerPage(event);
        };


        return (
            <>
            <Sidebar/>
            <Navbar /> 
            <Section>
            <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px'}}>
                <h2 className="text-center" style={{ fontSize: '24px',color: '#333',margin: '0', padding: '10px', borderBottom: '2px solid blue',width: '50%', margin: 'auto'}}>
                    Garages
                </h2>
            </div>
            {/* <Snackbar
              open={showSuccessAlert}
              autoHideDuration={3000} // Durée de 3 secondes (3000 millisecondes)
              onClose={() => this.setState({ showSuccessAlert: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position en haut à droite
            >
              <Alert severity="success">Le garage a été ajouté avec succès !</Alert>
            </Snackbar> */}

              
              <div className="container">
                   
            
              
                <div className="row" >
                <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gridColumnGap: "5px",
                        marginTop:"30px",
                        height:"90px",
                        backgroundColor:"#F2F5FA",
                      }}>

                        <Paper
                          component="form"
                          sx={{
                            p: '2px 4px',
                            display: 'flex',
                            width: '120%', 
                            height:'37px',
                            maxWidth: '500px',
                            marginTop: '20px'
                             /* Ajouter une largeur maximale pour que le formulaire ne dépasse pas une certaine taille */
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            value={this.state.searchQuery}
                            onChange={this.handleSearchQueryChange}
                          />
                          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                          </IconButton>
                          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                        </Paper>

                        <button
                         onClick={this.toggleAddForm}
                         className="btn btn-primary"
                         style={{
                           gridColumn: "4",
                           width: "100%", /* Modifier la largeur pour qu'elle soit de 100% */
                           maxWidth: "200px", /* Ajouter une largeur maximale pour que le lien ne dépasse pas une certaine taille */
                           backgroundColor: "#2769D5",
                           p: '2px 4px',
                           display: 'auto',
                           height:'37px',
                           marginTop: '20px'
                        
         
                         }}
                        >
                          Ajouter Garage
                        </button>
                      </div>
                    
                  
                    <div className="table-grid">
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                      <TableRow>
                        <TableCell>
                          <span style={{ fontWeight: 'bold' }}>Nom Garage</span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={{ fontWeight: 'bold' }}>Adresse</span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={{ fontWeight: 'bold' }}>Capacité</span>
                        </TableCell>
                        <TableCell align="right">
                          <span style={{ fontWeight: 'bold' }}>Action</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                        <TableBody>
                          
                                {filteredgarages.map((garage) => {
                                  return (
                                    <TableRow key={garage.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover role="checkbox" tabIndex={-1}>
                                      <TableCell component="th" scope="row">{garage.nameG}</TableCell>
                                      <TableCell align="right">{garage.adresse}</TableCell>
                                      <TableCell align="right">
                                        {garage.capacity}
                                        {garage.capacity == parseInt(garage.voitures.length, 10) ? (
                                          
                                          <CancelIcon style={{ color: 'red' }} />
                                        ) : (
                                          <CheckCircleIcon style={{ color: 'green' }} />
                                        )}
                                      </TableCell>
                                      <TableCell align="right" onClick={() => this.toggleEditForm(garage.id)}>
                                        <EditIcon />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                             
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={filteredgarages.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                  
                  </div>
                </div>
                {/* alert succés     */}
                <Snackbar
        open={isAddSuccess || isUpdateSuccess}
        autoHideDuration={6000} // Durée en millisecondes pour masquer automatiquement la boîte d'alerte
        onClose={() => {
          this.setState({isAddSuccess: false });
          this.setState({IsUpdateSuccess: false });
          
        }}
      >
        <Alert
          onClose={() => {
            this.setState({isAddSuccess: false });
            this.setState({IsUpdateSuccess: false });
          }}
          severity="success" // Type d'alerte : "success", "error", "warning" ou "info"
          sx={{ width: '100%' }}
        >
          {isAddSuccess && 'garage a été ajouté avec succès.'}
          {isUpdateSuccess && 'garage a été modifié avec succès.'}
        </Alert>
      </Snackbar>
              </div>

               {/* modal pour ajouter garage */}
               <Dialog open={showAddForm} onClose={this.toggleAddForm}>
                        <DialogTitle>Ajouter Garage</DialogTitle>
                        <DialogContent>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <form id="GarageForm" onSubmit={this.handleSubmit} >
                              <div>
                                <TextField
                                  id="nameG"
                                  name="nameG"
                                  label="Name"
                                  required
                                  fullWidth
                                  margin="normal"
                                  variant="filled"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <TextField
                                  id="adresse"
                                  name="adresse"
                                  label="Adresse"
                                  required
                                  fullWidth
                                  margin="normal"
                                  variant="filled"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <TextField
                                  id="capacity"
                                  name="capacity"
                                  label="Capacity"
                                  required
                                  fullWidth
                                  margin="normal"
                                  variant="filled"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                              <button type="submit" className="btn btn-primary" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>Ajouter</button>
                            </form>
                        
                          </div>
                           {/* Display the error message if it exists */}
                         {errorMessage && <div style={{ color: 'red', marginTop: '1rem' }}>{errorMessage}</div>}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.toggleAddForm} color="primary">
                            Fermer
                          </Button>
                        </DialogActions>
                      </Dialog>


                   
                    {/* modal modifier garage */}
                    <Dialog open={showEditForm} onClose={this.toggleEditForm} maxWidth="md">
                      <DialogTitle>Modifier Garage</DialogTitle>
                      <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                          <form id="editGarageForm" onSubmit={this.handleSubmit} style={{ width: '100%', maxWidth: '500px' }}>
                            <div>
                              <TextField
                                id="nameG"
                                name="nameG"
                                label="Name"
                                placeholder="nameG"
                                variant="filled"
                                defaultValue={editingGarageId && this.getGarageById(editingGarageId) ? this.getGarageById(editingGarageId).nameG : ""}
                                required
                                style={{ marginBottom: '1rem', width: '100%' }}
                              />
                              <TextField
                                id="adresse"
                                name="adresse"
                                label="Adresse"
                                placeholder="adresse"
                                variant="filled"
                                defaultValue={editingGarageId && this.getGarageById(editingGarageId) ? this.getGarageById(editingGarageId).adresse : ""}
                                required
                                style={{ marginBottom: '1rem', width: '100%' }}
                              />
                              <TextField
                                id="capacity"
                                name="capacity"
                                label="Capacity"
                                placeholder="capacity"
                                variant="filled"
                                defaultValue={editingGarageId && this.getGarageById(editingGarageId) ? this.getGarageById(editingGarageId).capacity : ""}
                                required
                                style={{ marginBottom: '1rem', width: '100%' }}
                              />
                            </div>
                            <button type="submit" className="btn btn-primary" style={{ alignSelf: 'center', marginTop: '1rem', width: 'fit-content' }}>Modifier</button>
                          </form>
                        
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.toggleEditForm} color="primary">
                          Fermer
                        </Button>
                      </DialogActions>
                    </Dialog>
            </Section>

           </>
        );
    }
}

export default Garage;
const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  .table-grid {
    display: grid;
    margin-top: 40px;
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;