import React from "react";
import styled from "styled-components";

import { HiOutlineLocationMarker } from "react-icons/hi";
import { cardStyles } from "../ReusableStyles";
import { MdMiscellaneousServices } from "react-icons/md";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import url from '../../api/serverUrl';

const PriceCard = styled.div`
  background-color: white;
  border: 1px solid #3B82F6;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-left: 50px;
  width: 200px;
`;

const PriceCard2 = styled.div`
  background-color: white;
  border: 1px solid #3B82F6;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
`;
export default function Service() {

  const [services, setServices] = useState([]);
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${url}/api/super-admin/services/list`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(res => {
        setServices(res.data);
      })
      .catch(error => {
        console.error('Error retrieving client list:', error);
      });

  }, []);

  return (
    <>
      {services.length != 0  && services.map((service) => (
        <Section style={{ color: 'white' }}>


          <div>
            <MdMiscellaneousServices style={{ fontSize: '100px', color: '#c9daeb' }} />
          </div>

          <div className="title">
            <h2>{service.service}</h2>
          </div>
          <div className="info">
            <div className="container">
              <h5 style={{ color: '#3B82F6' }}>Prix</h5>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center', // Centre horizontalement
                  alignItems: 'center', // Centre verticalement
                  height: '100%',
                }}
              >
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    width: '60%',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    fontFamily: 'Arial, sans-serif',
                    textAlign: 'center', // Centre le contenu horizontalement
                  }}
                >
                  <h3>{service.prix}</h3>
                </div>
              </div>

            </div>
            <div className="container">
              <h5 style={{ color: '#3B82F6'  , marginTop: '15px' , marginBottom: '15px' }}>Description</h5>
              <div className="container">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Centre horizontalement
                    alignItems: 'center', // Centre verticalement
                    height: '100%',
                  }}
                >
                  <PriceCard2 >
                    {service.description}
                  </PriceCard2>
                </div>
              </div>

            </div>
          </div>

          <div>
            <Link to={`/detailservice/${service.id}`}>
              <button
                className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none"
                style={{ backgroundColor: 'blue', borderRadius: '20px' }}
              >
                Plus de détails
              </button>
            </Link>
          </div>

        </Section>
      ))}

    </>
  );

}
const Section = styled.section`
  ${cardStyles};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: white;
  border: 1px solid #3B82F6;
  min-height: 200px;
  min-width: 200px;

  .image {
    max-height: 10rem;
    overflow: hidden;
    border-radius: 20rem;

    img {
      height: 10rem;
      width: 10rem;
      object-fit: cover;
      border-radius: 20rem;
      transition: 0.5s ease-in-out;
      border: 2px solid black;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .title {
    text-align: center;

    h2,
    h5 {
      color: #1637f0;
      font-family: "Permanent Marker", cursive;
      letter-spacing: 0.3rem;
      font-size: 19px;
    }

    h5 {
      letter-spacing: 0.2rem;
    }
  }

  .info {
    color: black;
    display: flex;
    flex-direction: row; /* Ajout de la propriété flex-direction */
    flex-wrap: wrap; /* Ajout de la propriété flex-wrap */
    justify-content: center; /* Ajout de la propriété justify-content */
    gap: 1rem;

    .container {
      text-align: center;
    }
  }
`;

