import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "../api/axios";
import { LOGIN_URL } from "../api/config";

import Logo from "../assets/GeoRental.png";
import Dashboard from "./Dashboard";
import ForgotPasswordForm from "./ForgotPassword"
import { useContext } from "react";

const Login = ({ onLogin }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);




  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${LOGIN_URL}/api/login`, {
        email,
        password,
      });

      const { token , refresh_token , roles , agenceId, agenceNom, agenceAdresse, agenceTelephone, agenceEmail, agencePays, agenceVille} = response.data; // Récupérer le token et les rôles depuis la réponse

      // Decode the token to extract user information
      const decodedToken = jwt_decode(token);
     

      // Utilisez les informations de l'utilisateur comme vous le souhaitez
    

      sessionStorage.setItem('token' , token);
      sessionStorage.setItem('refreshToken', refresh_token);
      setIsAuthenticated(true);

      console.log("login token : "+ token);

      // Récupérer le rôle de l'utilisateur à partir des données de la réponse
      const userRole = roles[0]; // Dans cet exemple, nous supposons que l'utilisateur a un seul rôle
     sessionStorage.setItem("agenceId", agenceId);
     //récupèrer le nom de l'agence
     sessionStorage.setItem("agenceNom", agenceNom);
     console.log("nom de l'agence : "+ agenceNom);
      //récupèrer l'adresse de l'agence
      sessionStorage.setItem("agenceAdresse", agenceAdresse);
      console.log("l'adresse de l'agence : "+ agenceAdresse);
    //récupèrer l'email de l'agence
    sessionStorage.setItem("agenceEmail", agenceEmail);
    console.log("l'adresse mail de l'agence : "+ agenceEmail);
    //récupèrer telephone de l'agence
    sessionStorage.setItem("agenceTelephone", agenceTelephone);
    console.log("telephone de l'agence : "+ agenceTelephone);
     //récupèrer pays de l'agence
     sessionStorage.setItem("agencePays", agencePays);
     console.log("pays de l'agence : "+ agencePays);
     //récupèrer la ville de l'agence
     sessionStorage.setItem("agenceVille", agenceVille);
     console.log("ville de l'agence : "+ agenceVille);
      // Utilisez le rôle de l'utilisateur comme vous le souhaitez
     console.log("agence id  : "+ agenceId);
      sessionStorage.setItem("role", userRole);

      // Redirection en fonction du rôle de l'utilisateur
      if (userRole === "ROLE_SUPER_ADMIN") {
        navigate("/dashboardAdmin");
      } else if (userRole === "ROLE_ADMIN") {
        navigate("/dashboard");
      }
      onLogin(userRole);
    } catch (error) {
      setError("Invalid Credentials, try again!");
      setTimeout(() => {
        setError("");
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setShowForgotPassword(true);
  };
  const handleBackToLogin = () => {
    setShowForgotPassword(false);
  };

  const onChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }

  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="fixed inset-0 flex items-center justify-center bg-transparent"
    >
      <div className="flex flex-col justify-center px-6 py-12 mx-auto bg-white border-1 rounded-md shadow-lg w-[38rem]">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={Logo} alt="Your Company" />
        </div>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 text-gray-900">
          Login
        </h2>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {showForgotPassword ? (
            <ForgotPasswordForm />
          ) : (
            <>
              <form className="space-y-6" onSubmit={onSubmit}>
                {error && (
                  <div className="bg-red-500 text-white font-bold py-2 px-4 rounded">
                    {error}
                  </div>
                )}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email:
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={onChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                        onClick={handleForgotPassword}
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={onChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
                    />
                  </div>
                </div>

                <div>
                  {isLoading ? (
                    <div className="flex justify-center mt-4">
                      <motion.div
                        className="loader"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{
                          duration: 0.5,
                          repeat: Infinity,
                          repeatType: "reverse",
                        }}
                      ></motion.div>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign in
                    </button>
                  )}
                </div>
              </form>
            </>
          )}
          {showForgotPassword && (
            <button onClick={handleBackToLogin} className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Back to Login</button>
          )}
          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <a
              href="#"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Be patient with us!
            </a>
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default Login;
