import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdSpaceDashboard } from "react-icons/md";
import { RiDashboard2Fill } from "react-icons/ri";
import { FaAddressCard, FaTaxi } from "react-icons/fa";
import { GiTwirlCenter } from "react-icons/gi";
import { BsFillChatTextFill } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { FiLogOut } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import scrollreveal from "scrollreveal";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/GeoRental.png";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import GarageIcon from '@mui/icons-material/Garage';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import Navbar from "./Navbar";
import NoCrashIcon from '@mui/icons-material/NoCrash';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import CommuteIcon from '@mui/icons-material/Commute';
import { GiHomeGarage } from "react-icons/gi"
import { LiaFileContractSolid } from 'react-icons/lia';

import GroupsIcon from '@mui/icons-material/Groups';
import StarIcon from '@mui/icons-material/Star';
import { IconButton } from "@mui/material";

export default function Sidebar() {
  const [currentLink, setCurrentLink] = useState();
  const [navbarState, setNavbarState] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    navigate("/"); // Utilisez navigate() pour rediriger vers une autre route
  };

  useEffect(() => {
    const sr = scrollreveal({
      origin: "left",
      distance: "80px",
      duration: 1000,
      reset: false,
    });

    sr.reveal(`
      .brand,
      .links>ul>li:nth-of-type(1),
      .links>ul>li:nth-of-type(2),
      .links>ul>li:nth-of-type(3),
      .links>ul>li:nth-of-type(4),
      .links>ul>li:nth-of-type(5),
      .links>ul>li:nth-of-type(6),
      .links>ul>li:nth-of-type(7),
      .links>ul>li:nth-of-type(8),
      .links>ul>li:nth-of-type(9),
      .links>ul>li:nth-of-type(10),
      .links>ul>li:nth-of-type(11),
      .logout
    `, {
      opacity: 0,
      interval: 300,
    });
  }, []); // Ajout d'une dépendance vide pour que useEffect s'exécute une seule fois

  console.log(currentLink);

  return (
    <>

      <Section>
        <div style={{
          width: '100%',
          maxHeight: 'calc(100% )', // Adjust the value to leave space for the header and footer
          overflowY: 'auto',
        }}   >

          <div className="top">
            <div className="brand">
              {/* <FaTaxi /> */}
              <img src={Logo} alt="logo" width="160px" />
              {/* <span>MY TAXI</span> */}
            </div>
            <div className="toggle">
              {navbarState ? (

                <VscChromeClose onClick={() => setNavbarState(false)} />
              ) : (
                <GiHamburgerMenu
                  onClick={(e) => {
                    e.stopPropagation();
                    setNavbarState(true);
                  }}
                />
              )}
            </div>
            <div className="links">
              <ul>
                <li
                  className={currentLink === 1 ? "active" : "none"}
                  onClick={() => setCurrentLink(1)}
                >
                  <Link to="/dashboard">
                    <MdSpaceDashboard />
                    <span> Dashboard</span>
                  </Link>
                </li>
                <li
                  className={currentLink === 2 ? "active" : "none"}
                  onClick={() => setCurrentLink(2)}
                >
                  <Link to="/voitures-disponibles" onClick={handleClick} >
                    <FaTaxi />
                    <span>Voiture-Disponible </span>
                  </Link>



                </li>
                <li
                  className={currentLink === 3 ? "active" : "none"}
                  onClick={() => setCurrentLink(3)}
                >
                  <Link to="/voitures-non-disponibles">
                    <FaTaxi />
                    <span>Voitures-Indisponible</span>
                  </Link>
                </li>
                <li
                  className={currentLink === 4 ? "active" : "none"}
                  onClick={() => setCurrentLink(4)}
                >
                  <Link to="/archived">
                    <FaTaxi />
                    <span>Voitures Archivées</span>
                  </Link>
                </li>


                <li
                  className={currentLink === 5 ? "active" : "none"}
                  onClick={() => setCurrentLink(5)}
                >
                  <Link to="/garage">
                    <GiHomeGarage />
                    <span> Garage</span>
                  </Link>
                </li>
                <li
                  className={currentLink === 6 ? "active" : "none"}
                  onClick={() => setCurrentLink(6)}
                >
                  <Link to="/evaluation-client">
                    <StarIcon />
                    <span> Evaluation Client </span>
                  </Link>
                </li>
                <li
                  className={currentLink === 7 ? "active" : "none"}
                  onClick={() => setCurrentLink(7)}
                >
                  <Link to="/listeContrat">
                    <LiaFileContractSolid />
                    <span> Contrats</span>
                  </Link>
                </li>
                <li
                  className={currentLink === 8 ? "active" : "none"}
                  onClick={() => setCurrentLink(8)}
                >
                  <Link to="/listeClients">
                    <GroupsIcon />
                    <span>Clients</span>
                  </Link>
                </li>


                <li
                  className={currentLink === 9 ? "active" : "none"}
                  onClick={() => setCurrentLink(9)}
                >
                  <Link to="/profil">
                    <IoSettings />
                    <span> Settings</span>
                  </Link>
                </li>
                <li
                  className={currentLink === 10 ? "active" : "none"}
                  onClick={() => setCurrentLink(10)}
                >
                 <Link to="/" onClick={handleLogout}>
                 <FiLogOut />
                    <span> Déconnexion </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </Section>


      <ResponsiveNav state={navbarState} className={navbarState ? "show" : ""}>
        <div style={{
          width: '100%',
          maxHeight: 'calc(100% )', // Adjust the value to leave space for the header and footer
          overflowY: 'auto',
        }}>
          <div className="responsive__links">
            <ul>
              <li
                className={currentLink === 1 ? "active" : "none"}
                onClick={() => setCurrentLink(1)}
              >
                <Link to="/Dashboard">
                  <MdSpaceDashboard />
                  <span> Dashboard</span>
                </Link>
              </li>
              <li
                className={currentLink === 2 ? "active" : "none"}
                onClick={() => setCurrentLink(2)}
              >
                <a href="/voitures-disponibles" >
                  <FaTaxi />
                  <span> Voitures disponibilite</span>

                </a>

              </li>
              <li
                className={currentLink === 3 ? "active" : "none"}
                onClick={() => setCurrentLink(3)}
              >
                <a href="/voitures-non-disponibles">
                  <FaTaxi />
                  <span>Voitures Indisponible</span>
                </a>
              </li>
              <li
                className={currentLink === 4 ? "active" : "none"}
                onClick={() => setCurrentLink(4)}
              >
                <a href="/archived">
                  <FaTaxi />
                  <span>Voitures Archivées</span>
                </a>
              </li>


              <li
                className={currentLink === 5 ? "active" : "none"}
                onClick={() => setCurrentLink(5)}
              >
                <a href="/garage">
                  <GiHomeGarage />
                  <span>  Garages</span>
                </a>
              </li>
              <li
                className={currentLink === 6 ? "active" : "none"}
                onClick={() => setCurrentLink(6)}
              >
                <Link to="/evaluation-client">
                  <StarIcon />
                  <span> Evaluation Client </span>
                </Link>
              </li>
              <li
                className={currentLink === 7 ? "active" : "none"}
                onClick={() => setCurrentLink(7)}
              >
                <Link to="/listeContrat">
                  <LiaFileContractSolid />
                  <span> Contrats</span>
                </Link>
              </li>
              <li
                className={currentLink === 8 ? "active" : "none"}
                onClick={() => setCurrentLink(8)}
              >
                <NavLink to="/listeClients" activeClassName="active">
                  <GroupsIcon />
                  <span>Clients</span>
                </NavLink>
              </li>

              <li
                className={currentLink === 10 ? "active" : "none"}
                onClick={() => setCurrentLink(10)}
              >
                <Link to="/profil">
                  <FiLogOut />
                  <span>  Déconnexion</span>
                </Link>
              </li>

            </ul>
          </div>
        </div>
      </ResponsiveNav>

    </>
  );
}

const Section = styled.section`
  position: fixed;
  background-color: #ECF2F7;
  height: 100vh;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 0;
  gap: 1rem;
  margin-left:0.5px;
  .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
   margin-left: -25px;
    gap: 2rem;
    width: 100%;

    .toggle {
      display: none;
    }
    .brand {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      svg {
        color: #2769D5 ;
        font-size: 2rem;
      }
      span {
        font-size: 2rem;
        color: #2769D5 ;
        font-family: "Permanent Marker", cursive;
      }
    }
    .links {
      display: flex;
      justify-content: flex-start; 
      align-items: flex-start;
      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        li {
          padding: 0.6rem 1rem;
          border-radius: 0.6rem;
          &:hover {
            background-color: #2769D5 ;
            a {
              color: black;
            }
          }
          a {
            text-decoration: none;
            display: flex;
            gap: 1rem;
            color: black;
          }
        }
        .active {
          background-color: #2769D5 ;
          a {
            color: black;
          }
        }
      }
    }
  }

  .logout {
    padding: 0.3rem 1rem;
    border-radius: 0.6rem;
    &:hover {
      background-color: #1C275A ;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: black;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    position: initial;
    width: 100%;
    height: max-content;
    padding: 1rem;
    .top {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      .toggle {
        display: block;
        color: black;
        z-index: 99;
        svg {
          font-size: 1.4rem;
        }
      }
      .brand {
        gap: 1rem;
        justify-content: flex-start;
      }
    }
    .top > .links,
    .logout {
      display: none;
    }
    .small-link {
      font-size: 5px;
    }
  }
`;

const ResponsiveNav = styled.div`
  position: fixed;
  right: -10vw;
  top: 0;
  z-index: 10;
  background-color:  #ECF2F7;
  height: 100vh;
  width: ${({ state }) => (state ? "40%" : "0%")};
  transition: 0.4s ease-in-out;
  display: flex;
  opacity: 0;
  visibility: hidden;
  padding: 1rem;
  .responsive__links {
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 3rem;
      li {
        padding: 0.6rem 1rem;
        border-radius: 0.6rem;
        &:hover {
          background-color: #2769D5 ;
          a {
            color: black;
          }
        }
        a {
          text-decoration: none;
          display: flex;
          gap: 1rem;
          color: black;
        }
      }
      .active {
        background-color: #2769D5 ;
        a {
          color: black;
        }
    }
    }
  }
`;