import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import { FormatOverline } from '@mui/icons-material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import url from '../../../api/serverUrl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};


const EditModal = ({ client, onClose, fetchClients }) => {

  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  const [formValues, setFormValues] = useState({
    name: client.name || '',
    cinExpire: client.cinExpire || '',
    numeroPermis: client.numeroPermis || '',
    permisExpire: client.permisExpire || '',
    cin: client.cin || '',
    datenaissance: client.datenaissance || '',
    telephone: client.telephone || '',
    email: client.email || '',
    adresse: client.adresse || '',
    ville: client.ville || '',
    photoCin: client.photocin || '',
    photoPermis: client.photopermis || '',
    rating: client.evaluationClient.rating || 0, // Valeur par défaut pour la notation
    commentaire: client.evaluationClient.commentaire || '',
    evid: client.id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };




  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('name', formValues.name);
   
    formData.append('cin', formValues.cin);
    formData.append('datenaissance', formValues.datenaissance);
    formData.append('telephone', formValues.telephone);
    formData.append('email', formValues.email);
    formData.append('adresse', formValues.adresse);
    formData.append('ville', formValues.ville);
    formData.append('rating', formValues.rating);
    formData.append('commentaire', formValues.commentaire);
    formData.append('evaluationId', formValues.evid);
    formData.append('numeroPermis', formValues.numeroPermis);
    formData.append('permisExpire', formValues.permisExpire);
    formData.append('cinExpire', formValues.cinExpire);


    if (formValues.photoCin) {
      formData.append('photoCin', formValues.photoCin);
    }
    if (formValues.photoPermis) {
      formData.append('photoPermis', formValues.photoPermis);
    }
    console.log(formData);
    axios({
      method: 'POST',
      url: `${url}/api/update/client/${client.id}`,
      headers: headers,
      data: formData,
    })
      .then((res) => {
        // Traitez la réponse du serveur si nécessaire
        console.log('Client updated successfully:', res.data);
        fetchClients();
      })
      .catch((error) => {
        console.error('Error updating client:', error);
      });

    onClose(); // Fermer le modal après la soumission
  };



  return (
    <Modal
      open={Boolean(client)}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box
        sx={{
          ...style,
          width: '100%',
          maxWidth: '1000px',
          height: '100%',
          maxHeight: '700px',
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={onClose}
          sx={{ alignSelf: 'flex-end' }} // Ajoutez cette ligne pour aligner le bouton à droite
        >
          <CloseIcon />
        </Button>

        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{
            mt: 2,
            color: 'rgb(68, 125, 248)',
            textAlign: 'center',
            fontWeight: 'lighter',
            letterSpacing: '0.5px',
            textTransform: 'uppercase',
            borderBottom: '1px solid rgb(80, 80, 80)',
            paddingBottom: '10px',
            marginTop: '10px'
          }}
        >
          Modifier les informations d'un Client
        </Typography>


        <Box
          sx={{
            width: '100%',
            maxHeight: 'calc(900px )', // Adjust the value to leave space for the header and footer
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Nom et Prénom"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                sx={{ mt: 2, width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <TextField
                    required
                    id="outlined-required"
                    label="date de Naissance"
                    name="datenaissance"
                    value={formValues.datenaissance}
                    onChange={handleChange}
                    // error={!!formErrors.name} // Set the error prop based on the presence of error for this field
                    // helperText={formErrors.name}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Telephone"
                name="telephone"
                value={formValues.telephone}
                onChange={handleChange}
                // error={!!formErrors.telephone} // Set the error prop based on the presence of error for this field
                // helperText={formErrors.telephone}
                sx={{ mt: 2, width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="E-mail"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                // error={!!formErrors.email} // Set the error prop based on the presence of error for this field
                // helperText={formErrors.email}
                sx={{ mt: 2, width: '100%' }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Cin"
                name="cin"
                value={formValues.cin}
                onChange={handleChange}
                // error={!!formErrors.cin} // Set the error prop based on the presence of error for this field
                // helperText={formErrors.cin}
                sx={{ mt: 2, width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Cin Expire"
                    name="cinExpire"
                    value={formValues.cinExpire}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Numéro Permis"
                name="numeroPermis"
                value={formValues.numeroPermis}
                onChange={handleChange}
                // error={!!formErrors.numeroPermis} // Set the error prop based on the presence of error for this field
                // helperText={formErrors.numeroPermis}
                sx={{ mt: 2, width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Permis Expire"
                    name="permisExpire"
                    value={formValues.permisExpire}
                    onChange={handleChange}
                    // error={!!formErrors.name} // Set the error prop based on the presence of error for this field
                    // helperText={formErrors.name}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Adresse"
                name="adresse"
                sx={{ mt: 2, width: '100%' }}
                value={formValues.adresse}
                onChange={handleChange}
              // error={!!formErrors.adresse}
              // helperText={formErrors.adresse}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Ville"
                name="ville"
                sx={{ mt: 2, width: '100%' }}
                value={formValues.ville}
                onChange={handleChange}
              // error={!!formErrors.ville}
              // helperText={formErrors.ville}
              />
            </Grid>
          </Grid>


          <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                type="file"
                label="Photo Cin"
                sx={{ mt: 2, width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                accept="image/*"
                name="photoCin"
                onChange={handleFileChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="file"
                label="Photo Permis"
                sx={{ mt: 2, width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                accept="image/*"
                name="photoPermis"
                onChange={handleFileChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%', flex: 1, mt: 1, width: '100%' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'Normal', color: 'gray', mt: 1 }}>
                Rating
              </Typography>
              <Rating
                name="rating"
                value={formValues.rating}
                precision={0.5}
                onChange={handleChange}
                style={{
                  marginTop: '1px',
                  fontSize: '30px',
                  color: '#FFD700', // Couleur des étoiles
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />} // Icône d'étoile vide
                icon={<StarIcon fontSize="inherit" />}
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                la label="Commentaire"
                name="commentaire"
                sx={{ mt: 2, width: '100%' }}
                value={formValues.commentaire}
                onChange={handleChange}

              />

            </Grid>
          </Grid>

          <hr
            style={{
              border: 'none',
              borderBottom: '1px solid #010102',
              margin: '10px 0',
            }}
          />
             <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            
            <Button
                            onClick={onClose}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px', marginRight: '20px', marginBottom: '20px' }}
                            sx={{
                                width: '200px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                            Annuler
                        </Button>

                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px', marginBottom: '20px'  }}
                            sx={{
                                width: '200px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                              Enregistrer
                        </Button>
          </Box>
        </Box>

      </Box>
    </Modal>
  );
};


export default EditModal;
