import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from 'mdb-react-ui-kit';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import {BsPersonCircle }from "react-icons/bs";
import url from '../api/serverUrl';

const Parametre = () => {
  const [nom, setNom] = useState('');
  const [adresse, setAdresse] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [pays, setPays] = useState('');
  const [ville, setVille] = useState('');
  const [showEditForm, setShowEditForm] = useState(false);
  const [editingAgenceId, setEditingAgenceId] = useState(null);
  const [newNom, setNewNom] = useState('');
  const [newAdresse, setNewAdresse] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newTelephone, setNewtelephone] = useState('');
  const [newPays, setNewpays] = useState('');
  const [newVille, setnewVille] = useState('');

  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');


  useEffect(() => {
    const agenceId=sessionStorage.getItem('agenceId');
    const agenceNom = sessionStorage.getItem('agenceNom');
    setNom(agenceNom);
    const agenceAdresse = sessionStorage.getItem('agenceAdresse');
    setAdresse(agenceAdresse);
    const agenceEmail = sessionStorage.getItem('agenceEmail');
    setEmail(agenceEmail);
    const agenceTelephone = sessionStorage.getItem('agenceTelephone');
    setTelephone(agenceTelephone);
    const agencePays = sessionStorage.getItem('agencePays');
    setPays(agencePays);
    const agenceVille = sessionStorage.getItem('agenceVille');
    setVille(agenceVille);
  }, []);
 

  const handleSubmit = (event) => {
    event.preventDefault();

    // Récupérer les valeurs des champs du formulaire
    const name = event.target.elements.name.value;
    const adresse = event.target.elements.adresse.value;
    const email = event.target.elements.email.value;
    const telephone = event.target.elements.telephone.value;
    const pays = event.target.elements.pays.value;
    const ville = event.target.elements.ville.value;
    
    // Mettre à jour les variables d'état avec les nouvelles valeurs
    setNewNom(name);
    setNewAdresse(adresse);
    setNewEmail(email);
    setNewtelephone(telephone);
    setNewpays(pays);
    setnewVille(ville);
    // Effectuer une requête PUT pour mettre à jour l'agence
    const token = sessionStorage.getItem("token");

    if (editingAgenceId) {
      axios
        .put(
          `${url}/dashboard/agence/update/${editingAgenceId}`,
          { agenceNom: name, agenceAdresse: adresse, agenceEmail:email,agenceTelephone: telephone,agencePays:pays,agenceVille: ville},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          // L'agence a été mise à jour avec succès, effectuer les actions nécessaires
          // Mettre à jour les variables d'état originales avec les nouvelles valeurs
          setNom(newNom);
          setAdresse(newAdresse);
          setEmail(newEmail);
          setTelephone(newTelephone);
          setPays(newPays);
          setVille(newVille);
          // Fermer le formulaire de modification
          setShowEditForm(false);
          // Afficher l'alerte de succès
          console.log('Agence mise à jour avec succès');
        })
        .catch((error) => {
          // Gérer les erreurs de requête
          console.error(error);
        });
    }
  };
  //envoyez la demmande de modification de mot de passe au backend
  const handlePasswordChange = () => {
    const agenceId = sessionStorage.getItem('agenceId');
    
    const requestBody = {
      password: currentPassword,
      newpassword: newPassword,
    };
    
    const token = sessionStorage.getItem('token');
    
    axios
      .post(`${url}/dashboard/edit/password/${agenceId}`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // Le mot de passe a été modifié avec succès, effectuer les actions nécessaires
        setCurrentPassword('');
        setNewPassword('');
        togglePasswordForm();
        console.log('Mot de passe modifié avec succès');
      })
      .catch((error) => {
        // Gérer les erreurs de requête
        console.error(error);
      });
  };
  
  const toggleEditForm = (agenceId) => {
    setEditingAgenceId(agenceId);
    setShowEditForm((prevState) => !prevState);
  };

  const closeModal = () => {
    setShowEditForm(false);
  };
  const togglePasswordForm = () => {
    setShowPasswordForm((prevState) => !prevState);
  };
  
  return (
    <>
      <Sidebar />
      <Navbar />
      <Section>
        <div>
          <MDBContainer className="py-5">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
                  <h2 className="text-center" style={{ fontSize: '24px', color: '#333', margin: '0', padding: '10px', borderBottom: '2px solid blue', width: '50%', margin: 'auto' }}>
                    Votre profil
                  </h2>
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="4">
                <MDBCard className="mb-4">
                <MDBCardBody className="text-center">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EditIcon
                  style={{ position: 'absolute', top: '5px', right: '5px', fontSize: '24px', cursor: 'pointer' }}
                  onClick={() => toggleEditForm(sessionStorage.getItem('agenceId'))}
                />
                <BsPersonCircle style={{ fontSize: '150px', color: '#c9daeb' }} />
                        </div>
                <p className="text-muted mb-1">{newNom || nom}</p>
                <p className="text-muted mb-4">{newAdresse || adresse}</p>
                <div className="d-flex justify-content-center mb-2">
                <MDBBtn outline className="ms-1" onClick={togglePasswordForm}>
                  Modifier le mot de passe
                </MDBBtn>

                </div>
              </MDBCardBody>
                </MDBCard>

                <MDBCard className="mb-4 mb-lg-0">
                  <MDBCardBody className="p-0">
                    <MDBListGroup flush className="rounded-3">
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                        <MDBIcon fas icon="globe fa-lg text-warning" />
                        <MDBCardText>https://mdbootstrap.com</MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                        <MDBIcon fab icon="github fa-lg" style={{ color: '#333333' }} />
                        <MDBCardText>mdbootstrap</MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                        <MDBIcon fab icon="twitter fa-lg" style={{ color: '#55acee' }} />
                        <MDBCardText>@mdbootstrap</MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                        <MDBIcon fab icon="instagram fa-lg" style={{ color: '#ac2bac' }} />
                        <MDBCardText>mdbootstrap</MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                        <MDBIcon fab icon="facebook fa-lg" style={{ color: '#3b5998' }} />
                        <MDBCardText>mdbootstrap</MDBCardText>
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol lg="8">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Adresse</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">{newAdresse || adresse}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Adresse email</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">{newEmail || email}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Téléphone</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">{newTelephone || telephone}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Pays</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">{newPays || pays}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Ville</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">{newVille || ville}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>

                <MDBRow>
                  <MDBCol md="6">
                    <MDBCard className="mb-4 mb-md-0">
                      <MDBCardBody>
                        <MDBCardText className="mb-4">
                          <span className="text-primary font-italic me-1">assigment</span> Project Status
                        </MDBCardText>
                        <MDBCardText className="mb-1" style={{ fontSize: '.77rem' }}>
                          Web Design
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={80} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          Website Markup
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={72} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          One Page
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={89} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          Mobile Template
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          Backend API
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                        </MDBProgress>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol md="6">
                    <MDBCard className="mb-4 mb-md-0">
                      <MDBCardBody>
                        <MDBCardText className="mb-4">
                          <span className="text-primary font-italic me-1">assigment</span> Project Status
                        </MDBCardText>
                        <MDBCardText className="mb-1" style={{ fontSize: '.77rem' }}>
                          Web Design
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={80} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          Website Markup
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={72} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          One Page
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={89} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          Mobile Template
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                        </MDBProgress>

                        <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>
                          Backend API
                        </MDBCardText>
                        <MDBProgress className="rounded">
                          <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                        </MDBProgress>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

            {/* Modal de modification de l'agence */}
        <Dialog open={showEditForm} onClose={closeModal} maxWidth="md">
          <DialogTitle>Modifier le profil</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <form id="editAgenceForm" onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '500px' }}>
                <div>
                  <TextField
                    id="name"
                    name="name"
                    label="Nom"
                    placeholder="Nom"
                    variant="filled"
                    defaultValue={newNom || nom}
                    required
                    style={{ marginBottom: '1rem', width: '100%' }}
                  />
                  <TextField
                    id="adresse"
                    name="adresse"
                    label="Adresse"
                    placeholder="Adresse"
                    variant="filled"
                    defaultValue={newAdresse || adresse}
                    required
                    style={{ marginBottom: '1rem', width: '100%' }}
                  />
                   <TextField
                    id="email"
                    name="email"
                    label="email"
                    placeholder="email"
                    variant="filled"
                    defaultValue={newEmail || email}
                    required
                    style={{ marginBottom: '1rem', width: '100%' }}
                  />
                   <TextField
                    id="telephone"
                    name="telephone"
                    label="telephone"
                    placeholder="telephone"
                    variant="filled"
                    defaultValue={newTelephone || telephone}
                    required
                    style={{ marginBottom: '1rem', width: '100%' }}
                  />
                   <TextField
                    id="pays"
                    name="pays"
                    label="pays"
                    placeholder="pays"
                    variant="filled"
                    defaultValue={newPays || pays}
                    required
                    style={{ marginBottom: '1rem', width: '100%' }}
                  />
                   <TextField
                    id="ville"
                    name="ville"
                    label="ville"
                    placeholder="ville"
                    variant="filled"
                    defaultValue={newVille || ville}
                    required
                    style={{ marginBottom: '1rem', width: '100%' }}
                  />
                  {/* Ajoutez d'autres champs ici si nécessaire */}
                </div>
                <button type="submit" className="btn btn-primary" style={{ alignSelf: 'center', marginTop: '1rem', width: 'fit-content' }}>Modifier</button>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal modifier le mot de passe */}
        {showPasswordForm && (
        <Dialog open={showPasswordForm} onClose={togglePasswordForm}>
          <DialogTitle>Modifier le mot de passe</DialogTitle>
          <DialogContent>
            <TextField
              label="Mot de passe actuel"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nouveau mot de passe"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={togglePasswordForm}>Annuler</Button>
            <Button onClick={handlePasswordChange} color="primary">Modifier</Button>
          </DialogActions>
        </Dialog>
      )}

      </Section>
    </>
  );
};

const Section = styled.section`
  .custom-dialog {
    width: 90%; /* Définissez la largeur souhaitée en pourcentage, pixels, etc. */
  }
  .search-bar {
    display: grid;
  }
  .card-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    margin-top: 40px;
    background-color: #ffffff;
  }
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
  @media (max-width: 768px) {
    /* Modifiez les propriétés CSS pour la mise en page mobile */
    div {
      gridTemplateColumns: 1fr;
      gridTemplateRows: auto;
    }
    .btn {
      grid-column: 1;
    }
  }
`;
const ModalContainer = styled.div`
  /* Styles du conteneur du modal */
`;

const ModalDialog = styled.div`
  /* Styles du dialog */
`;

const ModalContent = styled.div`
  /* Styles du contenu du modal */
`;

export default Parametre;
