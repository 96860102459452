

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

const ShowModal = ({ infoclient, onCloseInfo }) => {
    const [client, setClient] = useState(infoclient);
    const [activeTab, setActiveTab] = useState('first');
    return (
        <Modal
            open={Boolean(infoclient)}
            onClose={onCloseInfo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    maxHeight: '600px',
                    borderRadius: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    border: '1px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Button onClick={onCloseInfo} sx={{ alignSelf: 'flex-end' }}>
                    <CloseIcon />
                </Button>
                <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{
                mt: 2,
                color: 'rgb(68, 125, 248)',
                textAlign: 'center',
                fontWeight: 'lighter',
                textTransform: 'uppercase',
                borderBottom: '1px solid rgb(53, 57, 250)',
                paddingBottom: '10px',
                marginTop: '10px'
              }}
            >
              Détail du Client  
            </Typography>
                <div style={{
                    width: '650px',
                    height: '500px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: ' 20px',
                    boxShadow: ' 0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    fontFamily: 'Arial, sans-serif',
                    margin: '40px auto',
                    overflow: 'auto',
                    marginTop: '10px'
                }}>
                    <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs" role="tablist">
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'first' ? 'active' : ''}`}
                                    id="first-tab"
                                    href="#first"
                                    role="tab"
                                    aria-controls="first"
                                    aria-selected={activeTab === 'first'}
                                    onClick={() => setActiveTab('first')}
                                >
                                    client
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeTab === 'second' ? 'active' : ''}`}
                                    id="second-tab"
                                    href="#second"
                                    role="tab"
                                    aria-controls="second"
                                    aria-selected={activeTab === 'second'}
                                    onClick={() => setActiveTab('second')}
                                >
                                    Avis-Client
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">
                        <div className="tab-content">
                            <div
                                className={`tab-pane fade show ${activeTab === 'first' ? 'active' : ''}`}
                                id="first"
                                role="tabpanel"
                                aria-labelledby="first-tab"
                            >
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col">
                                        <p><strong>  Nom et Prénom : </strong>  {client.name} </p>
                                    </div>
                                    <div className="col">
                                        <p><strong>  Date de Naissance : </strong> {client.datenaissance}</p>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col">
                                        <p><strong> CIN : </strong> {client.cin} </p>
                                    </div>
                                    <div className="col">
                                        <p><strong>CIN Expire: </strong> {client.cinExpire} </p>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col">
                                        <p><strong>Numéro Premis: </strong>  {client.numeroPermis} </p>
                                    </div>
                                    <div className="col">
                                        <p><strong>Permis Expire : </strong> {client.permisExpire} </p>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col">
                                        <p><strong>E-mail:</strong>   {client.email} </p>
                                    </div>
                                    <div className="col">
                                        <p><strong>Télèphone </strong> : {client.telephone} </p>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col">
                                        <p><strong>Adresse:</strong> {client.adresse} </p>
                                    </div>
                                    <div className="col">
                                        <p><strong>Ville:</strong> {client.ville}</p>
                                    </div>
                                </div>

                            </div>
                            <div
                                className={`tab-pane fade ${activeTab === 'second' ? 'show active' : ''}`}
                                id="second"
                                role="tabpanel"
                                aria-labelledby="second-tab"
                                
                                
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <Rating
                                        value={client.evaluationClient.rating}
                                        precision={0.5}
                                        style={{
                                            marginTop: '1px',
                                            fontSize: '30px',
                                            color: '#FFD700', // Couleur des étoiles
                                            marginTop: '30px',

                                        }}
                                        emptyIcon={<StarBorderIcon fontSize="inherit" />} // Icône d'étoile vide
                                        icon={<StarIcon fontSize="inherit" />} // Icône d'étoile pleine
                                        readOnly // Empêche l'utilisateur de modifier la note
                                    />
                                </div>
                                <div >
                                    
                                    <div className="row">
                                        <div className="col">
                                        <strong>Mon Avis : </strong>
                                        </div>
                                        <div className="col">
                                        <div
                                        style={{
                                            marginTop: '20px',
                                            whiteSpace: 'pre-wrap',
                                            width: '500px',
                                            border: '1px solid #ccc',
                                            borderRadius: '5px',
                                            padding: '10px',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                            backgroundColor: '#f9f9f9',
                                            fontFamily: 'Arial, sans-serif',
                                            marginLeft: '20px',
                                            maxHeight:"900px" , 
                                            marginLeft :'-200px' , 
                                            textAlign: 'center'
                                        }}
                                    >
                                        
                                          {client.evaluationClient.commentaire} 
                                        
                                    </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        variant="contained"
                        style={{
                            color: 'white',
                            backgroundColor: 'rgb(68, 125, 248)',
                            borderRadius: '20px',
                        }}
                        sx={{
                            mr: '5',
                            width: '100px',
                            height: '30px',
                            backgroundColor: '#eeeff5',
                            color: '#dddfe7',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            padding: '12px 24px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#3f51b5',
                            },
                        }}
                        onClick={onCloseInfo}
                    >
                        Annuler
                    </Button>
                </Box> */}
            </Box>
        </Modal>
    );
};

export default ShowModal;






