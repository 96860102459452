import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import Popper from '@mui/material/Popper';
import moment from 'moment';
import url from '../../../api/serverUrl';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};


const ShowModal = ({ infocontrat, onCloseContrat }) => {
    const [contrat, setContrat] = useState(infocontrat);
    // download Contrat
    const [id, setId] = useState(contrat.id);

      const date  = moment(contrat.prolongation).format('YYYY-MM-DD')  ; 

    const handleDownloadPdf = () => {
        axios.get(`${url}/api/convert/html-to-pdf/${id}`, {
            responseType: 'blob', headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        })
            .then(response => {
                // Créez un objet URL à partir des données de la réponse
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Créez un lien temporaire pour le téléchargement
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'converted.pdf');

                // Ajoutez le lien au document et déclenchez le téléchargement
                document.body.appendChild(link);
                link.click();

                // Nettoyez l'objet URL et supprimez le lien du document
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch(error => {
                console.error('Erreur lors du téléchargement du PDF :', error);
            });
    };

    return (

        <Modal
            open={Boolean(infocontrat)}
            onClose={onCloseContrat}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box
                sx={{
                    ...style,
                    width: '80%',
                    maxWidth: '1000px',
                    height: '100%',
                    maxHeight: '650px',
                    borderRadius: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Button
                    onClick={onCloseContrat}
                    sx={{ alignSelf: 'flex-end' }} // Ajoutez cette ligne pour aligner le bouton à droite
                >
                    <CloseIcon />
                </Button>

                <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                    sx={{ mt: 2, color: 'blue', textAlign: 'center' }}
                >
                    Location N ° {contrat.id}
                </Typography>
                <hr
                    style={{
                        border: 'none',
                        borderBottom: '1px solid #010102',
                        margin: '10px 0 ',

                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        maxHeight: 'calc(100% )', // Adjust the value to leave space for the header and footer
                        overflowY: 'auto', // Enable vertical scrolling
                    }}
                >
                    <hr
                        style={{
                            border: 'none',
                            borderBottom: '1px solid #010102',
                            margin: '10px 0',
                            marginBottom: '20px'
                        }}
                    />

                    <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px' }}>
                        <div>
                            <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}> Locataire </h3>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Grid container spacing={10} sx={{ width: '100%', flex: 1, top: '2px' }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Nom et Prénom :</strong> {contrat.client.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong  >Téléphone : </strong> {contrat.client.telephone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1, }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>CIN/Passeport  :</strong>   {contrat.client.cin}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography

                                    >
                                        <strong> Expire le : </strong>   {contrat.client.cinExpire}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Pérmis du conduire N°  : </strong>  {contrat.client.numeroPermis}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography

                                    >
                                        <strong>Expire le : </strong>    {contrat.client.permisExpire}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Adresse : </strong>  {contrat.client.adresse}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography

                                    >
                                        <strong>Ville : </strong>    {contrat.client.ville}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px', marginTop: '1rem' }}>
                        <div>
                            <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}>Véhicule </h3>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Marque :</strong>   {contrat.voiture.marque}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography

                                    >
                                        <strong> Immatriculation : </strong>   {contrat.voiture.matricule}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Carburant : </strong>   {contrat.voiture.moteur}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </div>
                    </div>
                    {contrat.client.numeroPermisDc && contrat.client.cinDc && contrat.client.telephoneDc && contrat.client.nameDc && (
                        <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px' }}>
                            <div>
                                <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}>2<sup>ème</sup> Conducteur :</h3>
                            </div>

                            <>
                                <div style={{ marginTop: '20px' }}>
                                    <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                        <Grid item xs={12} md={6}>
                                            <Typography>
                                                <strong>Nom et Prénom :</strong> {contrat.client.nameDc}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography>
                                                <strong>Téléphone :</strong> {contrat.client.telephoneDc}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                        <Grid item xs={12} md={6}>
                                            <Typography>
                                                <strong>CIN/Passeport :</strong> {contrat.client.cinDc}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography>
                                                <strong>Pérmis du conduire N° :</strong> {contrat.client.numeroPermisDc}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>

                        </div>
                    )}
                    <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px', marginTop: '1rem' }}>
                        <div>
                            <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}>Location </h3>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Date de départ :</strong>   {contrat.voiture.marque}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography

                                    >
                                        <strong> Heure départ : </strong>   {contrat.voiture.matricule}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Date de retour : </strong>   {contrat.voiture.moteur}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Heure retour : </strong>   {contrat.voiture.moteur}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Km Départ : </strong>   {contrat.voiture.moteur}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Km Limite :  </strong>   {contrat.voiture.moteur}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Km Supplémentaire : </strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography >
                                        <strong>Tarif :  </strong>   {contrat.prixnegocie}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>

                    </div>

                    <div style={{ marginBottom: '10px', marginLeft: '20px', marginTop: '20px' }}>
                        <Typography >  Prolongation : {date} </Typography>
                    </div>


                    <div style={{ marginBottom: '10px', marginLeft: '20px', marginRight: '20px', marginTop: '20px', textAlign: 'center' }}>
                        <Typography > Comportement du locataire sera évalué sur la plateforme georent.ma </Typography>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <div style={{ marginBottom: '10px', marginLeft: '20px' }}>

                            <Grid container spacing={10} sx={{ width: '100%', flex: 1, }}>
                                <Grid item xs={12} md={6}>
                                    <Typography style={{ textAlign: 'center' }}>
                                        <strong >Loueur</strong> <br></br>
                                        Signature procédéé………………..
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography style={{ textAlign: 'center' }} >
                                        <strong >Locataire </strong>   <br />
                                        <p >
                                            J'ai pris connaissance et j'accepte
                                            les termes de locations.jesuis
                                            responsables des vialations, de la
                                            loi sur ………
                                        </p>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <hr
                        style={{
                            border: 'none',
                            borderBottom: '1px solid #010102',
                            margin: '10px 0 ',

                        }}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                        <Button
                            onClick={onCloseContrat}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px', marginRight: '20px', marginBottom: '20px' }}
                            sx={{
                                width: '200px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                            Annuler
                        </Button>

                        <Button
                            onClick={handleDownloadPdf}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px', marginBottom: '20px' }}
                            sx={{
                                width: '200px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                            Telecharger
                        </Button>

                    </Box>
                </Box>

            </Box>
        </Modal>
    )
}

export default ShowModal;


// <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
// <Grid item xs={12} md={6}>
//     <Typography >
//         <strong>Prolongation :  </strong>   {contrat.voiture.moteur}
//     </Typography>
// </Grid>

// </Grid>
// <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
// <Grid item xs={12} md={6}>
//     <Typography >
//         Comportement du locataire sera évalué sur la plateforme <strong>georent.ma </strong>
//     </Typography>
// </Grid>

// </Grid>

// <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
// <Grid item xs={12} md={6}>
//     <Typography >
//         <strong>Loueur</strong>  <br></br>
//         Signature procédéé……………….
//     </Typography>
// </Grid>
// <Grid item xs={12} md={6}>
//     <Typography >
//         <strong>Locataire </strong>    <br></br>

//         J\’ai pris connaissance et j\’accepte
//         les termes de locations.jesuis
//         responsables des vialations, de la
//         loi sur ………
//     </Typography>
// </Grid>
// </Grid>