import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsFillCalendar2WeekFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { BiGroup, BiDetail } from "react-icons/bi";
import {GiHomeGarage} from "react-icons/gi"
import { cardStyles } from "./ReusableStyles";
import axios from "../api/axios";
import { FaAddressCard, FaTaxi } from "react-icons/fa";
import GarageIcon from '@mui/icons-material/Garage';
import url from '../api/serverUrl';

export default function Analytics() {

  const [clients, setClients] = useState("");
  const [garages, setGarages] = useState("");
  const [voitures, setVoitures] = useState("");
  const [contrats, setContrats] = useState("");



  useEffect(() => {
    console.log(url);
    axios({
      method: 'GET',
      url: ` ${url}/api/dashboard/agence/${sessionStorage.getItem("agenceId")}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(res => {
        const { Clients, Garages , Voitures ,  Contrat } = res.data;
        setClients(Clients[0].nombreClient);
        setGarages(Garages[0].nombreGarage);
        setVoitures(Voitures[0].nombreVoiture);
        setContrats(Contrat[0].nombreContart);


      })
      .catch(error => {
        console.error('Error retrieving data:', error);
      });
  }, []);

  


  return (
    <Section>
      <div className="analytic" >
        <div className="logo">
         <GiHomeGarage  />
        </div>
        <div className="content">
          <h5>Garages</h5>
          <h2> {garages} </h2>
        </div>

      </div>
      <div className="analytic">
        <div className="logo">
        <FaTaxi />
        </div>
        <div className="content">
          <h5>Voitures</h5>
          <h2> {voitures} </h2>
        </div>
      </div>
      <div className="analytic">
      
        <div className="content">
          <h5> Clients</h5>
          <h2> {clients} </h2>
        </div>
        <div className="logo">
          <BiGroup />
        </div>
      </div>
      <div className="analytic ">
        <div className="content">
          <h5>Contrats</h5>
          <h2> {contrats} </h2>
        </div>
        <div className="logo">
          <BiDetail/>
        </div>
      </div>
    </Section>
  );
}
const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  .analytic {
    ${cardStyles};
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    transition: 0.5s ease-in-out;
    border: 1px solid #3B82F6;
    background-color: white;
     color : #3B82F6; 
    &:hover {
      background-color: #3085d6 ;
      color: #070808;
      svg {
        color:#3085d6 ;
      }
    }
    .logo {
      background-color: #c9daeb ;
      border-radius: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem;
      svg {
        font-size: 1.5rem;
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    .analytic {
      &:nth-of-type(3),
      &:nth-of-type(4) {
        flex-direction: row-reverse;
      }
    }
  }
`;
