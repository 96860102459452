import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from "styled-components";
import moment from 'moment';
import SidebarAdmin from "../Admin/SidebarAdmin";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import AjouterContrat from './AjouterContrat';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from "@mui/icons-material/Edit";
import UpdateContratAb from './UpdateContratAb';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Switch, Checkbox } from '@mui/material';
import NavbarAdmin from './NavbarAdmin';

const ContractsToBePaid = ({contracts}) => {
   
      console.log("contrat : " +contracts);
  return (
    <>
      <SidebarAdmin />
      <NavbarAdmin /> 

      <Section>
      <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px'}}>
                <h2 className="text-center" style={{ fontSize: '24px',color: '#333',margin: '0', padding: '10px', borderBottom: '2px solid blue',width: '50%', margin: 'auto'}}>
                    Contrats à payés
                </h2>
    </div>
    <div className="table-grid">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span style={{ fontWeight: 'bold' }}>Nom Agence</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Télephone</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Service</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Motant_total</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>paiement</span>
                  </TableCell>
                  <TableCell align="right">
                    <span style={{ fontWeight: 'bold' }}>Activation</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {contracts && contracts.map((contrat) => (
                
                <TableRow key={contrat.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover role="checkbox" tabIndex={-1}>
                  <TableCell component="th" scope="row">{contrat.agenceNom}</TableCell>
                  <TableCell align="right">{contrat.agenceTelephone}</TableCell>
                  <TableCell align="right">{contrat.serviceNom}</TableCell>
                  <TableCell align="right">{contrat.montantToltal} DH</TableCell>
                  <TableCell align="right" ><Checkbox defaultChecked /></TableCell>
                  <TableCell align="right" ><Switch defaultChecked /></TableCell>

                </TableRow>
                
              ))}

              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      </Section>
    </>
  );
};
const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export default ContractsToBePaid;
