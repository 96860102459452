import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import { FormatOverline } from '@mui/icons-material';
import styled from "styled-components";
import url from '../../../api/serverUrl';
import Swal from 'sweetalert2';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledSelect = styled.select`
  /* Styles personnalisés pour le select */
  width: 100%;
  padding: 0.8rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  /* Autres styles... */
`;



const EditModal = ({ contrat, onClose, fetchContratList }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formValues, setFormValues] = useState({
    cinClient: contrat.client.cin || "",
    nameClient : contrat.client.name || "" , 
    numeroPermis : contrat.client.numeroPermis || "",
    telephone : contrat.client.telephone || "",
    idClient: contrat.client.id || "",
    idVoiture: contrat.voiture.id || "",
    matricule: contrat.voiture.matricule || "",
    marque : contrat.voiture.marque || "",
    debut: formatDate(contrat.datesortie) || "", //
    fin: formatDate(contrat.dateretoure) || "", // 
    prolo : formatDate(contrat.prolongation) || "",
    prix: contrat.prixnegocie || "",
    nameDc: contrat.nameDc || "",
    telephoneDc: contrat.telephoneDc || "",
    cinDc: contrat.cinDc || "",
    numeroPermisDc : contrat.numeroPermisDc || "",
    montantKS: contrat.MontantKS || "",
  });
console.log("prolo avent : "+ formValues.prolo );
console.log("date sortie avent : "+ formValues.debut );

  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };
  const [cinList, setCinList] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedCin, setSelectedCin] = useState(formValues.cinClient);

  const [selectedClient, setSelectedClient] = useState({
    name: '',
    numeroPermis: '',
    telephone: '',
  });
  useEffect(() => {
    fetchClientList();
  }, []);
  const fetchClientList = () => {
    axios({
      method: 'GET',
      url: `${url}/api/client/liste/${sessionStorage.getItem('agenceId')}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        const cinList = res.data.map(client => client.cin);
        setCinList(cinList);
        setClients(res.data); // Mettre à jour l'état des clients
      })
      .catch((error) => {
        console.error('Error retrieving client list:', error);
      });
  };
  const handleSelectChange = (event) => {
    const selectedCin = event.target.value;

    setSelectedCin(selectedCin);

    // Trouver le client sélectionné en utilisant le cin
    const selectedClient = clients.find(client => client.cin === selectedCin);

    // Mettre à jour les valeurs des champs d'entrée avec les informations du client sélectionné
    setSelectedClient(selectedClient);
  };


  const [matriculeList, setMatriculeList] = useState([]);
  const [voitures, setVoitures] = useState([]);
  const [selectedMatricule, setSelectedMatricule] = useState(formValues.matricule);

  const [selectedVoiture, setSelectedVoiture] = useState({
    marque: '',
    kilometrage: '',
  });

  useEffect(() => {
    fetchVoitureList();
  }, []);
  const fetchVoitureList = () => {
    axios({
      method: 'GET',
      url: `${url}/api/list/Voitures/${sessionStorage.getItem('agenceId')}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        const matriculeList = res.data.map(voiture => voiture.matricule);
        setMatriculeList(matriculeList);
        setVoitures(res.data); // Mettre à jour l'état des clients
      })
      .catch((error) => {
        console.error('Error retrieving client list:', error);
      });
  };
  const handleSelectChangeVoiture = (event) => {
    const selectedMatricule = event.target.value;

    setSelectedMatricule(selectedMatricule);

    // Trouver le client sélectionné en utilisant le cin
    const selectedVoiture = voitures.find(voiture => voiture.matricule === selectedMatricule);

    // Mettre à jour les valeurs des champs d'entrée avec les informations du client sélectionné
    setSelectedVoiture(selectedVoiture);
  };

  const [isDeuxiemeConducteurChecked, setIsDeuxiemeConducteurChecked] =
        useState(true);

    const handleDeuxiemeConducteurChange = (event) => {
        setIsDeuxiemeConducteurChecked(event.target.checked);
    };

    const [isKiloChecked, setIsKilorChecked] =
        useState(true);

    const handleKiloChange = (event) => {
        setIsKilorChecked(event.target.checked);
    };

    const [isProlongation, setIsProlongation] =
        useState(true);

    const handleProlongation = (event) => {
        setIsProlongation(event.target.checked);
    };
   
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
    
    
  console.log(selectedClient.id || formValues.idClient);
  console.log(selectedVoiture.id || formValues.idVoiture);
  console.log("debut " + formValues.debut);
  console.log( "fin " + formValues.fin);
  console.log("prolo "+formValues.prolo);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues.clientId);
    const formData = new FormData();

    const prix = parseFloat(document.getElementById('prix').value);
    
    const nameDc = document.getElementById('nameDc').value;
    const cinDc = document.getElementById('cinDc').value;
    const telephoneDc = document.getElementById('telephoneDc').value;
    const numeroPermisDc = document.getElementById('numeroPermisDc').value;
    const montantKS = document.getElementById('montantKS').value ;

    const debut = new Date(document.getElementById('datedebut').value);
    const fin = new Date(document.getElementById('datefin').value);
    const prolo = new Date(document.getElementById('prolongation').value);
    const debutFormatted = debut.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) // Remplacer les slashs par des tirets pour le format de date attendu
    
    const finFormatted = fin.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) // Remplacer les slashs par des tirets pour le format de date attendu
    
    const prolongationFormatted = prolo.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) // Remplacer les slashs par des tirets pour le format de date attendu
    
    console.log('prolongationFormatted: ' + prolongationFormatted);
    console.log('finFormatted: ' + finFormatted);
    console.log('debutFormatted: ' + debutFormatted);
    console.log(prolo)
  
  
    formData.append('clientId', selectedClient?.id ||  formValues.idClient  || '');
    formData.append('voitureId', selectedVoiture?.id || formValues.idVoiture || '');
    formData.append('dateSortie', debutFormatted || '15/12/2022');
    formData.append('dateRetour', finFormatted || '20/2/2023');
    formData.append('prixNegocie', prix || 'NULL');
    formData.append('etatVehicule', 'null');
    formData.append('nameDc', nameDc || 'NULL');
    formData.append('telephoneDc', telephoneDc || 'NULL');
    formData.append('cinDc', cinDc || 'NULL');
    formData.append('numeroPermisDc',numeroPermisDc || 'NULL');
    formData.append('montantKS', montantKS || 'NULL');
    formData.append('prolongation', prolongationFormatted || '00/00/00 00:00 ');
    
    console.log(formData);   // 
    console.log({'clientId': selectedClient?.id ||  formValues.idClient  || '', 
                'voitureId': selectedVoiture?.id || formValues.idVoiture || '',
                'dateSortie': debutFormatted || '15/12/2022',
                'dateRetour': finFormatted || '20/2/2023',
                'prixNegocie': prix || 'NULL',
                'etatVehicule': 'null',
                'nameDc': nameDc || 'NULL',
                'telephoneDc': telephoneDc || 'NULL',
                'cinDc': cinDc || 'NULL',
                'numeroPermisDc':numeroPermisDc || 'NULL',
                'montantKS': montantKS || 'NULL',
                'prolongation': prolongationFormatted || '00/00/00 00:00 '})
    axios({
      method: 'PUT',
      url: `${url}/api/contrat/update/${contrat.id}`,
      headers: headers,
      
      data: {'clientId': selectedClient?.id ||  formValues.idClient  || '', 
              'voitureId': selectedVoiture?.id || formValues.idVoiture || '',
              'dateSortie': debutFormatted || '15/12/2022',
              'dateRetour': finFormatted || '20/2/2023',
              'prixNegocie': prix || 'NULL',
              'etatVehicule': 'null',
              'nameDc': nameDc || 'NULL',
              'telephoneDc': telephoneDc || 'NULL',
              'cinDc': cinDc || 'NULL',
              'numeroPermisDc':numeroPermisDc || 'NULL',
              'montantKS': montantKS || 'NULL',
              'prolongation': prolongationFormatted || '00/00/00 00:00 '},
            })
      .then((res) => {
        // Traitez la réponse du serveur si nécessaire
        console.log('Contart updated successfully:', res.data);
        fetchContratList();
        onClose ();
         // Afficher le message de succès
         setSuccessMessage('Contrat updated successfully');
         setShowSuccessMessage(true);
         
      

         // Réinitialisation du message de succès après 3 secondes
        
        
      })
      .catch((error) => {
        console.error('Error updating client:', error);
        Swal.fire('Ooops.. !', 'La voiture est indisponible à cette date!', 'error');
        onClose ();
      });
  };
  

  return (
    <Modal
      open={Boolean(contrat)}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

 
<Box
            sx={{
              ...style,
              width: '100%',
              maxWidth: '1000px',
              height: '100%',
              maxHeight: '650px',
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Ajoutez cette ligne pour aligner les éléments à droite
            }}
          >
 <Button
                    onClick={onClose}
                    sx={{ alignSelf: 'flex-end' }} // Ajoutez cette ligne pour aligner le bouton à droite
                >
                    <CloseIcon />
                </Button>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          sx={{ mt: 2, color: 'blue', textAlign: 'center' }}
        >
          Modifier  une location
        </Typography>
        <hr
          style={{
            border: 'none',
            borderBottom: '1px solid #010102',
            margin: '10px 0',
          }}
        />
        <Box
          sx={{
            width: '100%',
            maxHeight: 'calc(100% )', // Adjust the value to leave space for the header and footer
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          
          <div className="containner" >
            <div className="Reservation">
              <div className="container" style={{marginTop : '-40px'}}>
                <div className="container2Col">
                  <div className="form_card">
                  <div style={{ fontweight:' 500', 
                          fontSize: '20px' , 
                          color: '#2769D5' ,
                          marginTop: '5px' ,  }}>
                              Client</div>
                    <div className="inputs__container">
                      <div className="form__control">
                      <label htmlFor="CIN">Sélectionnez le  CIN / passport</label>
                        <StyledSelect
                          value={selectedCin}
                          onChange={handleSelectChange}
                          placeholder="Entrer votre CIN"
                          id="CIN"
                        >
                          
                          {cinList.map((cin, index) => (
                            <option key={index} value={cin}>
                              {cin}
                            </option>
                          ))}
                        </StyledSelect>
                      </div>
                      <div className="form__control">
                        <label htmlFor="name">Nom et prénom</label>
                        <input
                          type="text"
                          placeholder="Entrer votre nom et prénom"
                          id="name"
                          value={selectedClient.name || formValues.nameClient || ""}
                          disabled
                          style={{
                            backgroundColor: "white",
                          }}
                        />
                      </div>
                      <div className="form__control">
                        <label htmlFor="npermis">N permis</label>
                        <input
                          type="text"
                          placeholder="Entrer votre n permis"
                          id="npermis"
                          value={selectedClient.numeroPermis || formValues.numeroPermis || "" }
                          disabled
                          style={{
                            backgroundColor: "white",
                          }}
                        />
                      </div>
                      <div className="form__control">
                        <label htmlFor="tel">Téléphone</label>
                        <input
                          type="text"
                          placeholder="Entrer votre numéro de téléphone"
                          id="tel"
                          value={selectedClient.telephone || formValues.telephone ||""}
                          disabled
                          style={{
                            backgroundColor: "white",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form_card">
                  <div style={{ fontweight:' 500', 
    fontSize: '20px' , 
    color: '#2769D5' ,
    marginTop: '5px' , 

    }}>
       Voiture </div>
                    <div className="inputs__container">
                      <div className="form__control">
                      <label htmlFor="Matricule">Sélectionnez le matricule :</label>
                        <StyledSelect value={selectedMatricule} onChange={handleSelectChangeVoiture} laceholder="Entrer Le matricule" id="Matricule">
                        
                          {matriculeList.map((matricule, index) => (
                            <option key={index} value={matricule}>
                              {matricule}
                              </option>
                          ))}
                        </StyledSelect>

                      </div>
                      <div className="form__control">
                        <label htmlFor="marque">Marque</label>
                        <input type="text" placeholder="Entrer la marque" id="marque"
                          value={selectedVoiture.marque || formValues.matricule || ""}
                          disabled
                          style={{
                            backgroundColor: 'white',
                          }}
                        />
                      </div>
                      <div className="form__control">
                        <label htmlFor="km">Kilometrage</label>
                        <input type="text" placeholder="Entrer le kelometrage" id="km"
                          value={selectedVoiture.kilometrage || formValues.marque || ""}
                          disabled
                          style={{
                            backgroundColor: 'white',
                          }}
                        />
                      </div>
                      <div className="form__control">
  <label htmlFor="prix">Prix négocié</label>
  <input type="text"
   placeholder="Entrer le prix"
    id="prix" 
    name="prix"
     value={formValues.prix} onChange={handleChange} />
</div>

                    </div>
                  </div>
                </div>
                <div className="form_card duree">
  <div style={{ 
    fontweight: '500' , 
    fontsize: '20px'  ,
    color: '#2769D5' ,
    margintop: '-10px' , 
    
    }}>Durée</div>
  <div className="inputs__container">
    <div className="form__control">
      <label htmlFor="datedebut">Date/heure début</label>
      <input type="datetime-local" id="datedebut" name="debut" value={formValues.debut} onChange={handleChange} />
    </div>
    <div className="form__control">
      <label htmlFor="datefin">Date/heure fin</label>
      <input type="datetime-local" id="datefin" name="fin" value={formValues.fin} onChange={handleChange} />
    </div>
  </div>

</div>
<div className="form_card duree">
  <div style={{ 
    fontweight: '500' , 
    fontsize: '20px'  ,
    color: '#2769D5' ,
    margintop: '-10px' , 
    }}>
     <input
                          type="checkbox"
                          id="prolong"
                        checked={isProlongation}
                        onChange={handleProlongation}
                        />
                        <label htmlFor="prolong"> Prolongation</label></div>
                  
  <div className="inputs__container">
    <div className="form__control">
      <label htmlFor="prolongation">Date/heure </label>
      <input type="datetime-local" id="prolongation" name="prolo"  disabled={isProlongation}  value={formValues.prolo}   onChange={handleChange} />
    </div>
   </div>
  

</div>

                {/* <div className="form_card"> */}
                  <div style={{ 
    fontweight: '500' , 
    fontsize: '20px'  ,
    color: '#2769D5' ,
    margintop: '-10px' ,
    marginBottom: '25px' 
    
    }}>Info suplumentaire</div>
                  <div className="container2Col" >
                    <div className="form_card" style={{ 
    
    margintop: '30px' , 
    
    }}>
                      <div className="title">
                        <input
                          type="checkbox"
                          id="deuxièmeConducteur"
                        checked={isDeuxiemeConducteurChecked}
                        onChange={handleDeuxiemeConducteurChange}
                        />
                        <label htmlFor="deuxièmeConducteur"> Deuxième conducteur</label></div>
                      <div className="inputs__container">

                        <div className="form__control">
                          <label htmlFor="name">Nom et prenom</label>
                          <input
                            type="text"
                            placeholder="Entrer votre nom et prénom"
                            id="nameDc"
                            value={formValues.nameDc}
                            name= "nameDc"
                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form__control">
                          <label htmlFor="CIN">CIN / passport</label>
                          <input
                            type="text"
                            placeholder="Entrer votre nom et prénom"
                            id="cinDc"
                            value={formValues.cinDc}
                            name="cinDc"
                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="npermis">N permis</label>
                          <input
                            type="text"
                            placeholder="Entrer votre n premis"
                            id="numeroPermisDc"
                            value={formValues.numeroPermisDc}
                            name= "numeroPermisDc"
                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="tel">Telephone</label>
                          <input
                            type="text"
                            placeholder="Entrer votre num de telephone"
                            id="telephoneDc"
                            name="telephoneDc"
                            value={formValues.telephoneDc}
                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                            onChange={handleChange}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="form_card">
                      <div className="title">
                        <input
                          type="checkbox"
                          id="additionalKilometers"
                          checked={isKiloChecked}
                          onChange={handleKiloChange}
                        />
                        <label htmlFor="additionalKilometers">Kilomètre supplémentaire</label>
                      </div>
                      <div className="inputs__container">
                        <div className="form__control">
                          <label htmlFor="montantKS">Montant</label>
                          <input
                            type="text"
                            placeholder="Montant"
                            id="montantKS"
                            name="montantKS"
                            value={formValues.montantKS}
                            disabled={isKiloChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                            onChange={handleChange}

                          />   
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            
            <Button
                            onClick={onClose}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px', marginRight: '20px', marginBottom: '20px', marginTop: '-80px' }}
                            sx={{
                                width: '200px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                            Annuler
                        </Button>

                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px', marginBottom: '20px' , marginTop: '-80px' }}
                            sx={{
                                width: '200px',
                                height: '30px',
                                backgroundColor: '#eeeff5', // Couleur de fond
                                color: '#dddfe7', // Couleur du texte
                                fontWeight: 'bold',
                                textTransform: 'uppercase', // Transformation du texte en majuscules
                                padding: '12px 24px', // Espacement interne du bouton
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                                transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                                '&:hover': {
                                    backgroundColor: '#3f51b5', // Couleur de survol
                                },
                            }}
                        >
                              Enregistrer
                        </Button>
          </Box>
          {/* </div> */}
          
        </Box>
      
      </Box>
    </Modal>
  );
};


export default EditModal;


  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();

  //   formData.append('name', formValues.name);
  //   formData.append('passport', formValues.passport);
  //   formData.append('cin', formValues.cin);
  //   formData.append('datenaissance', formValues.datenaissance);
  //   formData.append('telephone', formValues.telephone);
  //   formData.append('email', formValues.email);
  //   formData.append('adresse', formValues.adresse);
  //   formData.append('ville', formValues.ville);
  //   formData.append('rating', formValues.rating);
  //   formData.append('commentaire', formValues.commentaire);
  //   formData.append('evaluationId', formValues.evid);
  //   formData.append('numeroPermis', formValues.numeroPermis);
  //   formData.append('permisExpire', formValues.permisExpire);
  //   formData.append('cinExpire', formValues.cinExpire);
  //   if (formValues.photoCin) {
  //     formData.append('photoCin', formValues.photoCin);
  //   }
  //   if (formValues.photoPermis) {
  //     formData.append('photoPermis', formValues.photoPermis);
  //   }
  //   console.log(formData);
  //   axios({
  //     method: 'POST',
  //     url: `http://127.0.0.1:8000/api/update/client/${contrat.id}`,
  //     headers: headers,
  //     data: formData,
  //   })
  //     .then((res) => {
  //       // Traitez la réponse du serveur si nécessaire
  //       console.log('Client updated successfully:', res.data);
  //       fetchContratList();
  //     })
  //     .catch((error) => {
  //       console.error('Error updating client:', error);
  //     });

  //   onClose(); // Fermer le modal après la soumission
  // };

  // pour client 


   
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(formValues.clientId)
  //   const prix = document.getElementById('prix').value;
  //   const debut = new Date(document.getElementById('datedebut').value);
  //   const debutFormatted = debut.toLocaleDateString('fr-FR', {
  //     day: '2-digit',
  //     month: '2-digit',
  //     year: 'numeric',
  //     hour: '2-digit',
  //     minute: '2-digit'
  //   });
    
  //   const fin = new Date(document.getElementById('datefin').value);
  //   const finFormatted = fin.toLocaleDateString('fr-FR', {
  //     day: '2-digit',
  //     month: '2-digit',
  //     year: 'numeric',
  //     hour: '2-digit',
  //     minute: '2-digit'
  //   });
    
  //   const nameDc = document.getElementById('nameDc').value;
  //   const cinDc = document.getElementById('cinDc').value;
  //   const telephoneDc = document.getElementById('telephoneDc').value;
  //   const numeroPermisDC = document.getElementById('numeroPermisDC').value;
  //   const montantKS = document.getElementById('montantKS').value;
  
  //   axios({
  //     method: 'PUT',
  //     url: `http://127.0.0.1:8000/api/contrat/update/${contrat.id}`,
  //     headers: headers,
  //     data: {
  //       clientId: selectedClient.id,
  //       voitureId: selectedVoiture.id,
  //       dateSortie: debutFormatted,
  //       dateRetour: finFormatted,
  //       prixNegocie: prix,
  //       etatVehicule: "",
  //       nameDc: nameDc,
  //       telephoneDc: telephoneDc,
  //       cinDc: cinDc,
  //       numeroPermisDC: numeroPermisDC,
  //       montantKS: montantKS
  //     },
  //   })
  //   .then((res) => {
  //     console.log('Contrat updated successfully:', res.data);
  //     fetchContratList();
  //     onClose();
  //   })
  //   .catch((error) => {
  //     console.error('Error updating Contrat:', error);
  //   });
  // };
  