import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import url from '../../api/serverUrl';
import styled from "styled-components";
import { getAgenceList } from '../../service/functions';

import {
    Select,
    InputLabel
} from '@mui/material';


const StyledSelect = styled.select`
  /* Styles personnalisés pour le select */
  width: 100%;
  padding: 0.8rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  /* Autres styles... */
`;


const AjouterUser = ({onClose,onContractAdded}) => {
    const [agenceId,setAgenceId]=useState('');
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    const [agences, setAgences] = useState([]); // get the agence list 

    const token = sessionStorage.getItem('token');

    useEffect( () => {
     const getAgences = async ()=> {
        const data = await getAgenceList(token);
        setAgences(data);
     }

     getAgences();
     
        
    }, [])

    useEffect(()=> {
        console.log(agences);
    }, [agences])
    
    const handleSubmit = (event) => {
      event.preventDefault();
  
     
  
      const userData = {
        agenceId: agenceId,
        email: email,
        password: password,
      };

      console.log(userData);
  
      {/*axios
        .post(`${url}/api/super-admin/add/user`, contratData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          // Traiter la réponse si nécessaire
          onClose(); // Fermer le modal après avoir ajouté le contrat
          onContractAdded();
        })
        .catch((error) => {
          console.error(error);
        });*/}
    };

    const handleInputChange = (event) => {
      if (event.target.name === 'email') {
        setEmail(event.target.value);
      } else if (event.target.name === 'password') {
        setPassword(event.target.value);
      } 
    };

    const handleAgenceChange = (event)=> {
        setAgenceId(event.target.value);

        // get the selected agnece email
        const agenceEmail =  agences.find(agence =>  agence.id === event.target.value);
        console.log(agenceEmail);
        setEmail(agenceEmail.email);
    }
    
  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="md" style={{ height: '500px' }}>
        <DialogTitle>Ajouter Agence</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <form id="GarageForm" onSubmit={handleSubmit}>
              <div>
              <div className='sous-title' > <strong>Client</strong></div>
                        <div className="form__control">
                        <InputLabel htmlFor="categorie-patient" style={{ fontWeight: 'bold', color: 'primary' }}>Agence*</InputLabel>
                                        <Select
                                            labelId="agenceId"
                                            name="agenceId"
                                            id="agenceId"
                                            value={agenceId}
                                            label="Agence"
                                            onChange={handleAgenceChange}
                                        >
                                            {  agences.length?  agences.map((agence) => (
                                                <MenuItem key={agence.id} value={agence.id}>
                                                    {agence.nomLocation}
                                                </MenuItem>
                                            )): null}
                                        </Select>
                        </div>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={email}
                  onChange={handleInputChange}
                />
                 <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={password}
                  onChange={handleInputChange}
                />
               
              </div>
              <Button type="submit" className="btn btn-primary" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                Ajouter
              </Button>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AjouterUser;
