import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover'; 


const NavbarAdmin = ({ pendingContractsCount }) => {
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const navigate = useNavigate();

 

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/"); // Utilisez navigate() pour rediriger vers une autre route
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
   // Function to set the anchor element when the notification icon is clicked
   const handleMenuOpen = (event) => {
    setAnchorEll(event.currentTarget);
  };

  // Function to reset the anchor element when the menu is closed
  const handleMenuClose = () => {
    setAnchorEll(null);
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ height: '57px' }} style={{ backgroundColor: '#2769D5 '}}>
          <Toolbar>
            <p style={{ color: '#f1dafb', marginLeft:'1020px',fontSize: '20px',marginTop:'7px' }}>
            Admin
            </p>
            {auth && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'down',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'down',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                
                  <MenuItem onClick={handleLogout}><FiLogOut />Déconnexion</MenuItem>
                </Menu>
              </div>
        
            )}
             
                 {/* Display the notification icon with the badge */}
            <IconButton
              size="large"
              color="inherit"
              onClick={handleMenuOpen}
            >
              <Badge badgeContent={pendingContractsCount} color="error">
                <NotificationsActiveIcon />
              </Badge>
            </IconButton>
            <Popover
              open={Boolean(anchorEll)}
              anchorEl={anchorEll}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              // Add custom styling to increase the size of the Popover
              sx={{
                '& .MuiPopover-paper': {
                  maxWidth: '400px', // Set the maximum width
                  maxHeight: '300px', // Set the maximum height
                  padding: '16px', // Add padding for content inside the Popover
                },
              }}
            >
              <Typography>
                {/* Add the link to the list of contracts that need to be paid */}
                <a href="/contractstobepayed" onClick={handleMenuClose}>
                {pendingContractsCount} Contrats à payer
                </a>
              </Typography>
            </Popover>

          </Toolbar>
        
        </AppBar>
      </Box>
    </div>
  );
};

export default NavbarAdmin;
