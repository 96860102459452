import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import url from '../../api/serverUrl';

function formatDateToDDMMYYYY(dateString) {
  const dateObj = new Date(dateString);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${day}/${month}/${year}`;
}

const UpdateContratAb = ({ contract, onClose, onContractUpdated }) => {
  const [agences, setAgences] = useState([]);
  const [services, setServices] = useState([]);
  const [datedebut, setDatedebut] = useState('');
  const [datefin, setDatefin] = useState('');
  const [montanttotal, setMontanttotal] = useState('');
  const [agenceId, setAgenceId] = useState('');
  const [serviceId, setServiceId] = useState('');

  useEffect(() => {
    fetchAgences();
    fetchServices();
    if (contract) {
      setDatedebut(formatDateToDDMMYYYY(contract.dateDebut));
      setDatefin(formatDateToDDMMYYYY(contract.dateFin));
      setMontanttotal(contract.montantToltal);
      setAgenceId(contract.agenceId); // Mettre à jour l'ID de l'agence sélectionnée
      setServiceId(contract.serviceId); // Mettre à jour l'ID du service sélectionné
      
    }
  }, [contract]);

  useEffect(() => {
    // Chargez les agences et les services lors du montage du composant
    fetchAgences();
    fetchServices();
  }, []);

  const fetchAgences = () => {
    const token = sessionStorage.getItem('token');

    axios
      .get(`${url}/api/Agence/liste`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAgences(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchServices = () => {
    const token = sessionStorage.getItem('token');

    axios
      .get(`${url}/api/super-admin/services/list`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const token = sessionStorage.getItem('token');

    const contratData = {
      datedebut: new Date(datedebut),
      datefin: new Date(datefin),
      montanttotal: montanttotal,
      agence_id: agenceId,
      service_id: serviceId,
    };

    axios
      .put(`${url}/api/super-admin/modifier/Contrats/${contract.id}`, contratData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // Traiter la réponse si nécessaire
        onClose(); // Fermer le modal après avoir mis à jour le contrat
        onContractUpdated(); 
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'datedebut') {
      setDatedebut(value);
    } else if (name === 'datefin') {
      setDatefin(value);
    } else if (name === 'montanttotal') {
      setMontanttotal(value);
    } else if (name === 'agence_id') {
      setAgenceId(value);
    } else if (name === 'service_id') {
      setServiceId(value);
    }
  };
  
  
  

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="md" style={{ height: '500px' }}>
        <DialogTitle>Modifier Contrat</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <form onSubmit={handleSubmit}>
              <div>
                <TextField
                  id="datedebut"
                  name="datedebut"
                  label="Datedebut"
                  type="date"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={datedebut}
                  onChange={handleInputChange}
                  
                />
                <TextField
                  id="datefin"
                  name="datefin"
                  label="Datefin"
                  type="date"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={datefin}
                  onChange={handleInputChange}
                  
                />
                <TextField
                  id="montanttotal"
                  name="montanttotal"
                  label="Montanttotal"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={montanttotal}
                  onChange={handleInputChange}
                
                />
                <TextField
                  id="agence_id"
                  name="agence_id"
                  label="Agence"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  select
                  value={agenceId}
                  onChange={handleInputChange}
                >
                  {agences.map((agence) => (
                    <MenuItem key={agence.id} value={agence.id}>
                      {agence.nomLocation}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="service_id"
                  name="service_id"
                  label="Service"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  select
                  value={serviceId}
                  onChange={handleInputChange}
                >
                  {services.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.service}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <Button type="submit" className="btn btn-primary" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                Modifier
              </Button>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateContratAb;
