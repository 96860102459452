import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import url from '../../api/serverUrl';

const UpdateAgence = ({agence,onClose,onAgenceUpdated  }) => {
  
  const [nomLocation, setNomLocation] = useState('');
  const [email, setEmail] = useState('');
  const [adresse, setAdresse] = useState('');
  const [telephone, setTelephone] = useState('');
  const [pays, setPays] = useState('');
  const [ville, setVille] = useState('');
  useEffect(() => {
    
    if (agence) {
      setNomLocation(agence.nomLocation);
      setEmail(agence.email);
      setAdresse(agence.adresse);
      setTelephone(agence.telephone); 
      setPays(agence.pays); 
      setVille(agence.ville);
      
    }
  }, [agence]);
  const handleSubmit = (event) => {
    event.preventDefault();

    const token = sessionStorage.getItem('token');

    const agenceData = {
      nomLocation: nomLocation,
      email: email,
      telephone: telephone,
      adresse: adresse,
      pays: pays,
      ville: ville,
    };

    axios
      .put(`${url}/api/super-admin/update/agence/${agence.id}`, agenceData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        // Traiter la réponse si nécessaire
        onClose(); // Fermer le modal après avoir mis à jour le contrat
        onAgenceUpdated(); 
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleInputChange = (event) => {
    if (event.target.name === 'nomLocation') {
      setNomLocation(event.target.value);
    } else if (event.target.name === 'email') {
      setEmail(event.target.value);
    }  else if (event.target.name === 'telephone') {
      setTelephone(event.target.value);
    }
    else if (event.target.name === 'adresse') {
      setAdresse(event.target.value);
    } else if (event.target.name === 'pays') {
      setPays(event.target.value);
    }else if (event.target.name === 'ville') {
      setVille(event.target.value);
    }
  };

  return (
    <>
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md" style={{ height: '500px' }}>
      <DialogTitle>Modifier Agence</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <form onSubmit={handleSubmit}>
            <div>
            <TextField
                  id="nomLocation"
                  name="nomLocation"
                  label="Nom"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={nomLocation}
                  onChange={handleInputChange}
                />
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={email}
                  onChange={handleInputChange}
                />
               <TextField
                id="telephone"
                name="telephone"
                label="Telephone"
                required
                fullWidth
                margin="normal"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                value={telephone}
                onChange={handleInputChange}
                type="number" // Ajoutez cette ligne pour spécifier le type "number"
                inputProps={{
                  step: 1, // Si vous souhaitez définir un pas (pour les nombres décimaux), sinon vous pouvez le supprimer
                }}
              />
                 <TextField
                  id="adresse"
                  name="adresse"
                  label="Adresse"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={adresse}
                  onChange={handleInputChange}
                />
                 <TextField
                  id="pays"
                  name="pays"
                  label="Pays"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={pays}
                  onChange={handleInputChange}
                />
                 <TextField
                  id="ville"
                  name="ville"
                  label="Ville"
                  required
                  fullWidth
                  margin="normal"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={ville}
                  onChange={handleInputChange}
                />
            </div>
            <Button type="submit" className="btn btn-primary" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              Modifier
            </Button>
          </form>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  </>
  );
};

export default UpdateAgence;
