import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import scrollreveal from "scrollreveal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import '../components/ListClient.css';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Rating from '@mui/material/Rating';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@material-ui/icons/Search';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import EditModal from './Modals/clients/EditModal';
import ShowModal from './Modals/clients/ShowModal';
import Popper from '@mui/material/Popper';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import Navbar from './Navbar';
import Paper from '@mui/material/Paper';
import url from '../api/serverUrl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};



const ListClient = () => {
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };

  // Evaluation Client 
  const [openEvaluation, setOpenEvaluation] = useState(false);
  const [clientCin, setClientCin] = useState('');
  const [cin, setCin] = useState(null);

  const handleOpenEvaluation = () => {

    setOpenEvaluation(true);
  };
  const handleCloseEvaluation = () => {
    setOpenEvaluation(false);
    setClientCin('');
    setCin(null);
  };

  const handleCinChange = (event) => {
    setCin(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmitCherche = async () => {

    try {
      const response = await axios.get(`${url}/api/client/${cin}/get-avis`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      setClientCin(response.data);
    } catch (error) {
      console.error('Error retrieving client list:', error);
    }
  };





  //Add client 
  const [open, setOpen] = useState(false);
  const [selectedPhotoCin, setSelectedPhotoCin] = useState(null);
  const [selectedPhotoPermis, setSelectedPhotoPermis] = useState(null);
  const [formValues, setFormValues] = useState({
    name: '',
    cin: '',
    datenaissance: '',
    telephone: '',
    email: '',
    adresse: '',
    ville: '',
    numeroPermis: '',
    photoCin: null,
    photoPermis: null,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [datenaissance, setDatenaissance] = useState('');
  const [permisExpire, setPermisExpire] = useState('');
  const [cinExpire, setCinExpire] = useState('');

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    // Handle file inputs separately
    if (type === 'file') {
      if (name === 'photoCin') {
        setSelectedPhotoCin(e.target.files[0]);
      } else if (name === 'photoPermis') {
        setSelectedPhotoPermis(e.target.files[0]);
      }
    } else if (name === 'datenaissance') {
      setFormValues({
        ...formValues,
        datenaissance: e.target.value,
      });
    } else if (name === 'permisExpire') {
      setFormValues({
        ...formValues,
        permisExpire: e.target.value,
      });
    } else if (name === 'cinExpire') {
      setFormValues({
        ...formValues,
        cinExpire: e.target.value, // Update the date field directly in the formValues state
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const handleSubmit = () => {
    // Vérification des champs obligatoires
    if (!formValues.name || !datenaissance || !formValues.telephone || !formValues.email || !formValues.cin || !cinExpire || !formValues.numeroPermis || !permisExpire || !formValues.adresse || !formValues.ville) {
      setErrorMessage('Veuillez remplir tous les champs obligatoires.');
      setShowErrorMessage(true);

      // Réinitialisation du message d'erreur après 3 secondes
      setTimeout(() => {
        setShowErrorMessage(false);
        setErrorMessage('');
      }, 3000);

      return;
    }

    const formData = new FormData();
    formData.append('photoCin', selectedPhotoCin);
    formData.append('photoPermis', selectedPhotoPermis);
    formData.append('datenaissance', dayjs(datenaissance).format('DD/MM/YYYY'));
    formData.append('permisExpire', dayjs(permisExpire).format('DD/MM/YYYY'));
    formData.append('cinExpire', dayjs(cinExpire).format('DD/MM/YYYY'));
    // Append other form values to the formData object
    Object.entries(formValues).forEach(([key, value]) => {
      if (key !== 'datenaissance') {
        formData.append(key, value);
      }
    });

    axios
      .post(`${url}/api/create/client/${sessionStorage.getItem('agenceId')}`, formData, { headers })
      .then((response) => {
        console.log(response.data);
        fetchClientList();
        setOpen(false);

        // Afficher le message de succès
        setSuccessMessage('Le client a été ajouté avec succès.');
        setShowSuccessMessage(true);

        // Réinitialisation du message de succès après 3 secondes
        setTimeout(() => {
          setShowSuccessMessage(false);
          setSuccessMessage('');
        }, 3000);
        setFormValues({
          name: '',
          telephone: '',
          email: '',
          cin: '',
          numeroPermis: '',
          adresse: '',
          ville: ''
        });
        setDatenaissance('');
        setCinExpire('');
        setPermisExpire('');

        fetchClientList();
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response.data);
        Swal.fire('Ooops.. !', 'Le client exist déjà!', 'error');
        handleClose();
      });
  };


  // update Client 

  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedClient, setEditedClient] = useState("");

  const handleEdit = (client) => {
    console.log('Edit client:', client);
    setEditedClient(client);
    setOpenEditModal(true);
  };
  const handleCloseModal = () => {
    setOpenEditModal(false);
    setEditedClient(null);
  };





  //delete client 
  const handleDelete = (row) => {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer ce client ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${url}/api/delete/client/${row.id}`, { headers })
          .then((response) => {
            Swal.fire('Supprimé !', 'Le client a été supprimé avec succès.', 'success');
            fetchClientList();
          })
          .catch((error) => {
            console.error(error);
            console.log(error.response.data);
          });
      }
    });
  };


  const fetchClientList = () => {
    axios({
      method: 'GET',
      url: `${url}/api/client/liste/${sessionStorage.getItem('agenceId')}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        setClients(res.data);
      })
      .catch((error) => {
        console.error('Error retrieving client list:', error);
      });
  };
  




  //List of clients
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Nombre d'éléments à afficher par page

  const filteredClients = clients.filter(client => {
    // Convertir la recherche et les valeurs des colonnes en minuscules pour une recherche insensible à la casse
    const query = searchQuery.toLowerCase();
    const name = client.name.toLowerCase();
    const cin = client.cin.toLowerCase();
    const telephone = client.telephone.toLowerCase();

    // const numeroPermis = client.numeroPermis.toLowerCase();

    // Vérifier si la recherche correspond à l'une des valeurs des colonnes
    return name.includes(query) || cin.includes(query) || telephone.includes(query) ;
  });

  const columns = [
    {
      name: 'Action', accessor: (row) => row.action, cell: row => (
        <>
          <div style={{ textAlign: 'center' }}>
            <IconButton onClick={() => handleEdit(row)}>
              <EditIcon style={{ color: 'black' }} />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)} >
              <DeleteIcon style={{ color: 'grey' }} />
            </IconButton>
          </div>

        </>
      ),
    },
    {
      name: 'Nom et Prénom', accessor: (row) => row.name, cell: row => (
        <div className="long-name-cell" style={{ textAlign: 'center' }} >{row.name}</div>
      )
    },
    {
      name: 'Cin/passport', accessor: (row) => row.cin, cell: row => (
        <div className="long-name-cell" style={{ textAlign: 'center' }}>{row.cin}</div>
      )
    },
    {
      name: 'Numéro Permis', accessor: (row) => row.numeroPermis, cell: row => (
        <div className="long-name-cell" style={{ textAlign: 'center' }}>{row.numeroPermis}</div>
      )
    },
    {
      name: 'Evaluation', accessor: (row) => row.evaluationClient, cell: row => (
        <div style={{ textAlign: 'center' }}>

          <Rating
            value={row.evaluationClient.rating}
            precision={0.5}
            style={{
              marginTop: '1px',
              fontSize: '24px',
              color: '#FFD700', // Couleur des étoiles
            }}
            emptyIcon={<StarBorderIcon fontSize="inherit" />} // Icône d'étoile vide
            icon={<StarIcon fontSize="inherit" />} // Icône d'étoile pleine
            readOnly // Empêche l'utilisateur de modifier la note
          />
        </div>
      )
    },

    {
      name: 'Télèphone', accessor: (row) => row.telephone, cell: row => (
        <div className="long-name-cell" style={{ textAlign: 'center' }} >{row.telephone}</div>
      )
    },
    {
      name: 'Plus d\'info',
      accessor: (row) => row.action, cell: row => (
        <div style={{ textAlign: 'center' }}>
          <IconButton
            onClick={() => handleInfo(row)}
          >
            <InfoIcon style={{ color: 'blue' }} />
          </IconButton>
        </div>

      ),
    },
  ];
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${url}/api/client/liste/${sessionStorage.getItem("agenceId")}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(res => {
        setClients(res.data);
      })
      .catch(error => {
        console.error('Error retrieving client list:', error);
      });
  }, []);

  const sortedClients = [...filteredClients].sort((a, b) => b.id - a.id);


  //info of client 

  const [infoClient, setInfoClient] = useState("");
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const handleInfo = (row) => {
    console.log('Info client:', row);
    setInfoClient(row);
    setOpenInfoModal(true);
  };

  const handleCloseModalInfo = () => {
    setOpenInfoModal(false);
    setInfoClient(null);
  };


  const customStyles = {
    headRow: {
      style: {
        background: '#2769D5',
        color: 'white'
      },
    },
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgba(0,0,0,.075)',
      },
    },
  };



  useEffect(() => {
    const sr = scrollreveal({
      origin: "bottom",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        nav,
        .row__one,
        .row__two
    `,
      {
        opacity: 0,
        interval: 100,
      }
    );
  }, []);





  return (
    <><Sidebar />
    <Navbar /> 
      <Section>


        <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px' }}>
          <h2 className="text-center" style={{ fontSize: '24px', color: '#333', margin: '0', padding: '10px', borderBottom: '2px solid blue', width: '50%', margin: 'auto' }}>
            Liste Des Clients
          </h2>
        </div>
        <div>
          <div className="flex justify-end" style={{ marginTop: '20px', marginBottom: '20px' }}>
            <button
              onClick={handleOpenEvaluation}
              className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none mr-2"
              style={{ marginRight: '5px', backgroundColor: 'blue', borderRadius: '20px' }}
            >
              Evaluation du Client
            </button>

            <button
              onClick={handleOpen}
              className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none"
              style={{ backgroundColor: 'blue', borderRadius: '20px' }}
            >
              Ajouter Client
            </button>



          </div>



          <div className="datatable-container">

            <div>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  width: '35%',
                  height: '37px',
                  maxWidth: '500px',
                  marginTop: '20px'
                  , marginBottom: '20px',
                  border: ' 1px solid #3B82F6',
                  marginLeft: '20px'
                  /* Ajouter une largeur maximale pour que le formulaire ne dépasse pas une certaine taille */
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchQuery} onChange={e => setSearchQuery(e.target.value)}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon style={{ color: 'black' }} />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

              </Paper>

              {showSuccessMessage && (

                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    width: '50%',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f7efdac8',
                    fontFamily: 'Arial, sans-serif',
                  }}
                >
                  {successMessage}

                </div>
              )}

            </div>

            <DataTable
              columns={columns}
              data={sortedClients}
              customStyles={customStyles}
              pagination
              paginationPerPage={itemsPerPage}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              paginationTotalRows={sortedClients.length}
              onChangePage={handlePageChange}
            />
          </div>

          {/* Modal Edit Client  */}
          {openEditModal && (
            <EditModal client={editedClient} onClose={handleCloseModal} fetchClients={fetchClientList} />
          )}

          {openInfoModal && (
            <ShowModal infoclient={infoClient} onCloseInfo={handleCloseModalInfo} />
          )}



        </div>





        {/* Modal Add Client  */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box
            sx={{
              ...style,
              width: '100%',
              maxWidth: '1000px',
              height: '100%',
              maxHeight: '650px',
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between', // Ajoutez cette ligne pour aligner les éléments à droite
            }}
          >

            <Button
              onClick={handleClose}
              sx={{ alignSelf: 'flex-end' }} // Ajoutez cette ligne pour aligner le bouton à droite
            >
              <CloseIcon />
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{
                mt: 2,
                color: 'rgb(68, 125, 248)',
                textAlign: 'center',
                fontWeight: 'lighter',
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                borderBottom: '1px solid rgb(80, 80, 80)',
                paddingBottom: '10px',
                marginTop: '10px'
              }}
            >
              Ajouter Client
            </Typography>


            <Box
              sx={{
                width: '100%',
                maxHeight: 'calc(100 -80 )', // Adjust the value to leave space for the header and footer
                overflowY: 'auto', // Enable vertical scrolling
              }}
            >
              {showErrorMessage && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                  {errorMessage}
                </Typography>
              )}

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Nom et Prénom"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Date de Naissance"
                        required
                        name="dateNaissance"
                        format="DD/MM/YYYY"

                        value={datenaissance}
                        onChange={(date) => setDatenaissance(date)}
                        // error={!!formErrors.datenaissance} // Set the error prop based on the presence of error for this field
                        // helperText={formErrors.name}
                        sx={{ mt: 1, width: '100%' }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Telephone"
                    name="telephone"
                    value={formValues.telephone}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="E-mail"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Cin"
                    name="cin"
                    value={formValues.cin}
                    onChange={handleChange}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Cin Expire"
                        required
                        name="cinExpire"
                        format="DD/MM/YYYY"
                        // value={formValues.datenaissance}
                        value={cinExpire}
                        onChange={(date) => setCinExpire(date)}
                        // error={!!formErrors.datenaissance} // Set the error prop based on the presence of error for this field
                        // helperText={formErrors.name}
                        sx={{ mt: 1, width: '100%' }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Numéro Permis"
                    name="numeroPermis"
                    value={formValues.numeroPermis}
                    onChange={handleChange}
                    // error={!!formErrors.numeroPermis} // Set the error prop based on the presence of error for this field
                    // helperText={formErrors.numeroPermis}
                    sx={{ mt: 2, width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Numéro Permis Expire"
                        required
                        name="permisExpire"
                        format="DD/MM/YYYY"
                        sx={{ mt: 1, width: '100%' }}
                        value={permisExpire}
                        onChange={(date) => setPermisExpire(date)}
                      // error={!!formErrors.permisExpire}
                      // helperText={formErrors.permisExpire}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Adresse"
                    name="adresse"
                    sx={{ mt: 2, width: '100%' }}
                    value={formValues.adresse}
                    onChange={handleChange}
                  // error={!!formErrors.adresse}
                  // helperText={formErrors.adresse}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="outlined-required"
                    label="Ville"
                    name="ville"
                    sx={{ mt: 2, width: '100%' }}
                    value={formValues.ville}
                    onChange={handleChange}
                  // error={!!formErrors.ville}
                  // helperText={formErrors.ville}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="file"
                    label="Photo Cin"
                    sx={{ mt: 2, width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    accept="image/*"
                    name="photoCin"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="file"
                    label="Photo Permis"
                    sx={{ mt: 2, width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    accept="image/*"
                    name="photoPermis"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', marginBottom: '4rem' }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px' }}
                  sx={{
                    width: '200px',
                    height: '30px',
                    backgroundColor: '#eeeff5', // Couleur de fond
                    color: '#dddfe7', // Couleur du texte
                    fontWeight: 'bold',
                    textTransform: 'uppercase', // Transformation du texte en majuscules
                    padding: '12px 24px', // Espacement interne du bouton
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                    transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                    '&:hover': {
                      backgroundColor: '#3f51b5', // Couleur de survol
                    },
                  }}
                >
                  Ajouter
                </Button>
              </div>
            </Box>
          </Box>
        </Modal>





        {/* Evaluation client   */}
        <Modal
          open={openEvaluation}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: '60%',
              maxWidth: '1000px',
              height: '100%',
              maxHeight: '600px',
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            <Button
              onClick={handleCloseEvaluation}
              sx={{ alignSelf: 'flex-end' }} // Ajoutez cette ligne pour aligner le bouton à droite
            >
              <CloseIcon />
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{
                mt: 2,
                color: 'rgb(68, 125, 248)',
                textAlign: 'center',
                fontWeight: 'lighter',
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                borderBottom: '1px solid rgb(80, 80, 80)',
                paddingBottom: '10px',
                marginTop: '10px'
              }}
            >
              Evaluation du Client
            </Typography>
            <hr
              style={{
                border: 'none',
                borderBottom: '1px solid #010102',
                margin: '10px 0',
              }}
            />
            <Grid container spacing={2} sx={{ width: '100%', flex: 1 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Entrez Cin du client"
                  name="cin"
                  value={cin}
                  onChange={handleCinChange}
                  sx={{ mt: 2, width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={handleSubmitCherche}
                  variant="contained"
                  style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '15px', marginTop: "25px" }}
                  sx={{
                    width: '200px',
                    height: '30px',
                    backgroundColor: '#eeeff5', // Couleur de fond
                    color: '#dddfe7', // Couleur du texte
                    fontWeight: 'bold',
                    textTransform: 'uppercase', // Transformation du texte en majuscules
                    padding: '12px 24px', // Espacement interne du bouton
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                    transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                    '&:hover': {
                      backgroundColor: '#3f51b5', // Couleur de survol
                    },
                  }}
                >
                  chercher
                </Button>
              </Grid>



              {clientCin && (
              <>
                <div style={{
                  textAlign: 'center',
                  color: 'rgb(68, 125, 248)',
                  textAlign: 'center',
                  fontWeight: 'lighter',
                  letterSpacing: '0.5px',
                  // borderBottom: '1px solid rgb(80, 80, 80)',
                  marginLeft:'150px',
                  fontSize: '35px'
                }}>
                  {clientCin.nom}
                  <Rating
                    value={clientCin.averageRating}
                    precision={0.5}
                    style={{
                      marginTop: '1px',
                      fontSize: '20px',
                      color: '#FFD700', // Couleur des étoiles
                    }}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />} // Icône d'étoile vide
                    icon={<StarIcon fontSize="inherit" />} // Icône d'étoile pleine
                    readOnly // Empêche l'utilisateur de modifier la note
                  />
                  <per style={{ fontSize: '20px'}}> ({clientCin.averageRating})</per>
                </div>


                <div style={{
                  width: '650px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: ' 20px',
                  boxShadow: ' 0 2px 4px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#f9f9f9',
                  fontFamily: 'Arial, sans-serif',
                  margin: '40px auto',
                  overflow: 'auto',
                  marginTop: '-1px'
                }}>

                  <div>
                    {clientCin && (
                      <div>
                        <table className="table">
                          <tbody>
                            {clientCin.commentaires.map((commentaire, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <PersonIcon />
                                </th>
                                <td>
                                  <div
                                    style={{
                                      whiteSpace: 'pre-wrap',
                                      width: '100%',
                                      border: '1px solid #ccc',
                                      borderRadius: '5px',
                                      padding: '10px',
                                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                      backgroundColor: '#f9f9f9',
                                      fontFamily: 'Arial, sans-serif',
                                    }}
                                  >
                                    {commentaire}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </div>
                    )}




                  </div>



                </div>

              </>)}

            </Grid>
            

          </Box>
        </Modal>




      </Section>
    </>
  );
};

export default ListClient;


const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;

