import React, { Component} from 'react';
import { useState } from 'react'
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import Sidebar from './Sidebar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import Navbar from "./Navbar";
import AddVoiture from "./Modals/voitures/AddVoiture";
import ReactModal from 'react-modal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import url from '../api/serverUrl';

class VoituresDisponible extends Component {
  constructor() {
    super();
    this.state = {
      voitures: [], // Initialisez la propriété voitures avec un array vide
      loading: true,//pour indiquer que les données sont en cours de chargement
      searchQuery: '', // Ajouter le state pour la requête de recherche
      showEditForm: false,
      editingVoitureId: null,
      showForm:false,
      showVoitureId:null,
      categories: [],
      garages: [],
      galeries: [],
      showImage: false,
      searchQuery: '',
      showModal: false,
      isAddSuccess:false,
      isUpdateSuccess:false,
    };

    this.toggleShowForm = this.toggleShowForm.bind(this);
   
  }
  handleOpenModal = () => {
    this.setState({ showModal: true }); // Mettez à jour l'état pour afficher la modale
  };

  handleCloseModal = () => {
    this.setState({ showModal: false }); // Mettez à jour l'état pour masquer la modale
  };
  handleSearchQueryChange = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });
  };
  handleViewImage = () => {
    this.setState({ showImage: true });
  };
  handleCloseImage = () => {
    this.setState({ showImage: false });
  };
  //supprimer une voiture
  toggleDelete = (id) => {
    const token = sessionStorage.getItem("token");
    axios
      .delete(`${url}/api/delete/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        this.getVoitures();
      })
      .catch((error) => {
        // Gérer les erreurs de requête
        console.error(error);
      });
  };
  //function archived voiture
    toggleArchived = (id) => {
      const token = sessionStorage.getItem("token");
      axios
        .put(`${url}/api/archive/${id}`, null, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.getVoitures();
        })
        .catch((error) => {
          // Gérez les erreurs de requête
          console.error(error);
        });
    };

    handleImageChange = (event) => {
      const selectedFiles = event.target.files;
    
      if (selectedFiles.length > 0) {
        // Mettre à jour l'état ou effectuer d'autres actions en fonction des fichiers sélectionnés
        this.setState({ selectedFiles });
    
        // Vous pouvez par exemple mettre à jour l'état avec les fichiers sélectionnés
        const previewURLs = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          const previewURL = URL.createObjectURL(file);
          previewURLs.push(previewURL);
        }
        this.setState({ previewURLs });
    
        // Vous pouvez également effectuer d'autres actions en fonction des fichiers sélectionnés, comme valider leur type ou leur taille
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          const fileType = file.type;
          const fileSize = file.size;
          // Effectuer des validations ou des traitements supplémentaires en fonction des informations du fichier
        }
      }
    };
    
    
    

    
    handleSubmit = (event) => {
      event.preventDefault();
  
      // Récupérez les valeurs des champs du formulaire
      const matricule = event.target.elements.matricule?.value ?? "";
      const tarif = event.target.elements.tarif?.value ?? "";
      const modele = event.target.elements.modele?.value ?? "";
      const couleur = event.target.elements.couleur?.value ?? "";
      const marque = event.target.elements.marque?.value ?? "";
      const image = event.target.elements.image?.value ?? "";
      const assurance = event.target.elements.assurance?.value ??"";
      const kilometrage = event.target.elements.kilometrage?.value ?? "";
      const disponibilite = event.target.elements.disponibilite?.value ?? "";
      const moteur = event.target.elements.moteur?.value ?? "";
      const nombrePortes = event.target.elements.nombrePortes?.value ?? "";
      const nombreValises = event.target.elements.nombreValises?.value ?? "";
      const boiteVitesse = event.target.elements.boiteVitesse?.value ?? "";
      const climatisation = event.target.elements.climatisation?.value ?? "";
      const franchiseAccident = event.target.elements.franchiseAccident?.value ?? "";
      const franchiseVol = event.target.elements.franchiseVol?.value ?? "";
      const categorie_id = event.target.elements.categorie_id?.value ?? "";
      const garage_id = event.target.elements.garage_id?.value ?? "";

      
  
      // Effectuez une requête pour ajouter la nouvelle voiture
      
        const token = sessionStorage.getItem("token");
        const voitureId = this.state.editingVoitureId;
      // Effectuer une requête PUT pour mettre à jour la voiture
      if (this.state.showEditForm) {
        
        axios
          .put(
            `${url}/api/update/${voitureId}`,
            { matricule, tarif, modele, couleur, marque, image, assurance, kilometrage, disponibilite, moteur, nombrePortes, nombreValises, boiteVitesse, climatisation , franchiseAccident, franchiseVol, categorie_id, garage_id},
            { headers: {
              Authorization : `Bearer ${token}`,
            } }
          )
          .then((response) => {
            // La voiture a été mise à jour avec succès, effectuer les actions nécessaires
            // (par exemple, actualiser la liste des voitures)
            this.getVoitures();
            // Fermer le formulaire de modification
            this.toggleEditForm(null);
            this.setState({ isUpdateSuccess: true });
            setTimeout(() => {
              this.setState({ isUpdateSuccess: false });
            }, 30000);
          })
          .catch((error) => {
            // Gérer les erreurs de requête
            console.error(error);
          });
      } 
    };
  
    
    
  
   
    toggleEditForm = (id) => {
      this.setState((prevState) => ({
        showEditForm: !prevState.showEditForm,
        editingVoitureId: id,
      }));
    };
    toggleShowForm(id) {
      this.getGaleries(id); // Appeler la méthode getGaleries() avec l'identifiant de la voiture
    
      // Trouver la voiture correspondante dans la liste des voitures
      const editingVoiture = this.state.voitures.find((voiture) => voiture.id === id);
    
      // Vérifier si la voiture et les galeries existent
      if (editingVoiture && editingVoiture.galeries) {
        this.setState((prevState) => ({
          showForm: !prevState.showForm, // Utiliser showForm au lieu de showEditForm
          showVoitureId: id,
          editingVoiture: editingVoiture, // Mettre à jour l'objet editingVoiture dans l'état avec les galeries correspondantes
        }));
      }
    }
    
    

    //est un cycle de vie du composant appelé immédiatement après que le composant a été inséré dans l'arbre DOM.
    componentDidMount() {
        this.getVoitures();
        this.fetchCategories();
        this.fetchGarages();
        this.getGaleries();
    }
    handleFileChange = (event) => {
      const file = event.target.files[0];
      this.setState({ selectedFile: file });
    };
  
    fetchCategories() {
      const token = sessionStorage.getItem("token");
  
      axios
        .get(`${url}/api/list/Categorie`, {
          headers: { Authorization: `Bearer ${token}` },})
        .then((response) => {
          this.setState({ categories: response.data, loading: false  });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  
    fetchGarages() {
      const token = sessionStorage.getItem("token");
      axios
        .get(`${url}/api/liste/garage/${sessionStorage.getItem('agenceId')}`, {
          headers: { Authorization: `Bearer ${token}` },})
        .then((response) => {
          this.setState({ garages: response.data, loading: false  });
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
     //forme modal
  openModal = () => {
    if (this.state.showEditForm || this.state.showForm) {
      this.setState({ modalEditOpen: true });
      this.setState({ modalShowOpen: true });
    } else {
      this.setState({ modalOpen: true });
    }
  };

  closeModal = () => {
    this.setState({ modalOpen: false, editModalOpen: false });
  };


  getCarById(id) {
    const car = this.state.voitures.find((voiture) => voiture.id === id);
    return car || {}; // Retourner un objet vide par défaut si aucune voiture n'est trouvée
  }
    //get liste des voitures
    getVoitures() {
        const token = sessionStorage.getItem("token");

        axios
        .get(`${url}/api/list/Voitures/${sessionStorage.getItem('agenceId')}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
            this.setState({ voitures: response.data, loading: false });
        })
        .catch((error) => {
            // Gérez les erreurs de requête
            console.error(error);
        });
    }
   
    //get galeries
    getGaleries(id) {
      const token = sessionStorage.getItem("token");
      axios
        .get(`${url}/api/galeries/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const galeries = response.data.galeries;
          this.setState({ galeries: galeries }); // Mettre à jour la propriété galeries avec les données récupérées
        })
        .catch((error) => {
          // Gérez les erreurs de requête
          console.error(error);
        });
    }
    
    handleChange = (e) => {
      this.setState({ searchQuery: e.target.value });
    };
   

    filterVoitures = () => {
      const { voitures, searchQuery } = this.state;
    
      // Filtrer les voitures en fonction de la requête de recherche de la marque de voiture
      const filteredVoitures = voitures.filter((voiture) => {
        return voiture.marque.toLowerCase().includes(searchQuery.toLowerCase());
      });
    
      return filteredVoitures;
    };

    render() {
      const { loading, voitures, searchQuery, galeries } = this.state;
      const showEditForm = this.state.showEditForm;
      const showForm = this.state.showForm;
      const editingVoitureId = this.state.editingVoitureId;
      const showVoitureId = this.state.showVoitureId;
      const { showImage } = this.state;
      const {isAddSuccess,isUpdateSuccess}=this.state
      // const classes = styled();

      // Filtrer les voitures en fonction de la requête de recherche
      const filteredVoitures = this.filterVoitures();
     
    
      const editingCar = editingVoitureId ? this.getCarById(editingVoitureId) : {};
      const editingVoiture = showVoitureId ? this.getCarById(showVoitureId) : {};
        return (
          
          <>
            <Sidebar />
            <Navbar /> 
            <Section>
             
            <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px'}}>
                <h2 className="text-center" style={{ fontSize: '24px',color: '#333',margin: '0', padding: '10px', borderBottom: '2px solid blue',width: '50%', margin: 'auto'}}>
                    Voitures disponible
                </h2>
            </div>
         
            

            
            <div className="container">
                   
                      
                    <div className="row">
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gridColumnGap: "5px",
                        marginTop:"30px",
                        height:"90px",
                        backgroundColor:"#F2F5FA",
                      }}>

                        <Paper
                          component="form"
                          sx={{
                            p: '2px 4px',
                            display: 'flex',
                            width: '120%', 
                            height:'37px',
                            maxWidth: '500px',
                            marginTop: '20px'
                             /* Ajouter une largeur maximale pour que le formulaire ne dépasse pas une certaine taille */
                          }}
                        >
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            value={this.state.searchQuery}
                            onChange={this.handleSearchQueryChange}
                          />
                          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                          </IconButton>
                          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                        </Paper>

                        <button
                          onClick={this.handleOpenModal}
                          to="/create"
                          className="btn btn-primary"
                          style={{
                            gridColumn: "4",
                            width: "100%", /* Modifier la largeur pour qu'elle soit de 100% */
                            maxWidth: "200px", /* Ajouter une largeur maximale pour que le lien ne dépasse pas une certaine taille */
                            backgroundColor: "#2769D5",
                            p: '2px 4px',
                            display: 'auto',
                            height:'37px',
                            marginTop: '20px'
                         
          
                          }}
                        >
                          Ajouter une voiture
                        
                        </button>
                        {/* Conditionnellement afficher la modale en fonction de l'état showModal */}
                       
                      
                        {this.state.showModal && <AddVoiture onClose={this.handleCloseModal} />}

                     
                      </div>
                    
                      <div className="card-grid">
                      {filteredVoitures
                        .filter((voiture) => voiture.disponibilite === "disponible" && voiture.archived === false)
                        .map((voiture) => (
                          <Card  sx={{ maxWidth: 200, border: '1.5px solid #BDC2CC', borderRadius: '20px'}} key={voiture.id} >
                            <CardContent>
                               <Typography gutterBottom variant="h5" component="div">
                                {voiture.marque}
                              </Typography>
                            </CardContent>
                            <CardMedia
                              sx={{
                                height: 84,
                                borderRadius: '45%',
                                width: 150,
                                margin: '0 auto',
                                objectFit: 'cover',
                              }}
                              component="img"
                              //image={`images/${voiture.image}`}
                              image= {`${url}/image/${voiture.image}`}
                              title={voiture.marque}
                              onClick={() => this.toggleShowForm(voiture.id)}
                            />

                            <CardContent>
                             
                              <Typography variant="body2" color="text.secondary">
                                Matricule:  {voiture.matricule}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Kilométrage: {voiture.kilometrage}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Prix de location: {voiture.tarif}DH/jour
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <IconButton onClick={() => this.toggleEditForm(voiture.id)} sx={{
                                "&:hover": {
                                  color: 'red' 
                                }
                              }}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => this.toggleArchived(voiture.id)} sx={{
                                "&:hover": {
                                  color: 'blue' 
                                }
                              }}>
                                <BookmarkIcon color={voiture.archived ? "primary" : "inherit"} />
                              </IconButton>
                              <Link to="/localisation" state={{ data: voiture, dispo:true }} ><IconButton >
                                <PinDropIcon />
                              </IconButton>
                              </Link>
                              {/* <IconButton onClick={() => this.toggleDelete(voiture.id)}>
                                <DeleteIcon />
                              </IconButton> */}
                            </CardActions>
                          </Card>
                        ))}
                        {/* alert succés     */}
                        <Snackbar
        open={isAddSuccess || isUpdateSuccess}
        autoHideDuration={6000} // Durée en millisecondes pour masquer automatiquement la boîte d'alerte
        onClose={() => {
          this.setState({isAddSuccess: false });
          this.setState({IsUpdateSuccess: false });
          
        }}
      >
        <Alert
          onClose={() => {
            this.setState({isAddSuccess: false });
            this.setState({IsUpdateSuccess: false });
          }}
          severity="success" // Type d'alerte : "success", "error", "warning" ou "info"
          sx={{ width: '100%' }}
        >
          {isAddSuccess && 'voiture a été ajouté avec succès.'}
          {isUpdateSuccess && 'voiture a été modifié avec succès.'}
        </Alert>
      </Snackbar>
                    </div>
                    
                      
                      {/* modal modifier voiture */}
                      <Dialog open={showEditForm} onClose={this.toggleEditForm} fullWidth maxWidth="md" style={{ height: "500px" }}>
                        <DialogTitle>Modifier une voiture</DialogTitle>
                        <DialogContent>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <form id="editVoitureForm" onSubmit={this.handleSubmit} >
                          <div>
                            
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
                              <TextField id="marque" name="marque" label="Marque" placeholder="marque" variant="filled"  defaultValue={editingCar.marque || ""}  required/>
                              <TextField id="modele" name="modele" label="Modèle"  variant="filled"  defaultValue={editingCar.modele || ""}  required/>
                              <TextField id="matricule" name="matricule" label="Matricule" variant="filled"  defaultValue={editingCar.matricule || ""}  required/>
                            </div><br/>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
                              <TextField id="couleur" name="couleur" label="Couleur" variant="filled" defaultValue={editingCar.couleur || ""}  required/>
                              <TextField id="kilometrage" name="kilometrage" label="Kilométrage" variant="filled"  defaultValue={editingCar.kilometrage || ""}  required/>
                              <TextField id="moteur" name="moteur" label="Moteur" variant="filled"  defaultValue={editingCar.moteur || ""}  required/>
                            </div><br/>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
                              <TextField id="nombrePortes" name="nombrePortes" label="Nombre de Portes" variant="filled"  defaultValue={editingCar.nombrePortes || ""}  required/>
                              <TextField id="nombreValises" name="nombreValises" label="Nombre de Valises" variant="filled"  defaultValue={editingCar.nombreValises || ""}  required/>
                              <TextField id="boiteVitesse" name="boiteVitesse" label="Boite de Vitesse" variant="filled" defaultValue={editingCar.boiteVitesse || ""}  required/>
                            </div><br/>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
                              <TextField id="climatisation" name="climatisation" label="Climatisation" variant="filled"  defaultValue={editingCar.climatisation || ""}  required/>
                              <TextField id="franchiseAccident" name="franchiseAccident" label="Franchise en cas d'Accident" variant="filled"  defaultValue={editingCar.franchiseVol || ""} required/>
                              <TextField id="franchiseVol" name="franchiseVol" label="Franchise en cas de Vol" variant="filled"  defaultValue={editingCar.franchiseVol || ""} required/>
                            </div><br/>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
                              <TextField id="tarif" name="tarif" label="Tarif" variant="filled"  defaultValue={editingCar.tarif || ""}  required/>
                              <Select
                                id="disponibilite"
                                name="disponibilite"
                                label="Disponibilité"
                                variant="filled"
                                defaultValue={editingCar.disponibilite || ""}
                                required
                              >
                                <MenuItem value="disponible">Disponible</MenuItem>
                                <MenuItem value="non-disponible">Non disponible</MenuItem>
                              </Select>


                            </div><br/>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
                              <InputLabel variant="standard" htmlFor="garage_id">
                                Garage
                              </InputLabel>
                              <NativeSelect
                                defaultValue={editingCar.garage_id !== undefined ? editingCar.garage_id : ""}
                                inputProps={{
                                  name: 'garage_id',
                                  id: 'garage_id',
                                }}
                                required
                              >
                              
                                {this.state.garages.map((garage) => (
                                  <option
                                    key={garage.id}
                                    value={garage.id}
                                    selected={editingCar.garage_id === garage.id}
                                  >
                                    {garage.nameG}
                                  </option>
                                ))}
                              </NativeSelect>



                            </div><br/>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
                            <InputLabel variant="standard" htmlFor="categorie_id">
                                categorie
                              </InputLabel>
                              <NativeSelect
                                defaultValue={editingCar.categorie_id !== undefined ? editingCar.categorie_id : ""}
                                inputProps={{
                                  name: 'categorie_id',
                                  id: 'categorie_id',
                                }}
                                required
                              >
                              
                                {this.state.categories.map((categorie) => (
                                  <option
                                    key={categorie.id}
                                    value={categorie.id}
                                    selected={editingCar.categorie_id === categorie.id}
                                  >
                                    {categorie.name}
                                  </option>
                                ))}
                              </NativeSelect>
                            
                            
                          


                            </div><br/>
                              {/* Affichage de l'image de la voiture */}
                              {showImage && editingCar.image && (
                              <div style={{ position: 'relative' }}>
                              <img src={`${url}/image/${editingCar.image}`} alt="Voiture" style={{ width: "100%", height: "auto" }} />
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.handleCloseImage}
                                style={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "#e0e0e0", border: "none", borderRadius: "50%", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              >
                                <CloseIcon style={{ color: "#000" }} />
                              </button>
                            </div>
                            )}
                                        
                            {/* Bouton pour voir l'image */}
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary" style={{ gridColumn: "3", width: "180px", backgroundColor: "#8190D5" }}
                              onClick={this.handleViewImage}
                            >
                              Voir l'image
                            </button>
                              {/* Bouton pour modifier l'image */}
                          
                            <input type="file" id="image" name="image" onChange={this.handleImageChange}   />
                          
                          </div>
                            
                          <br/>
                          {/* Affichage de l'assurance de la voiture */}
                          {showImage && editingCar.assurance && (
                              <div style={{ position: 'relative' }}>
                              <img src={`${url}/image/${editingCar.assurance}`} alt="Voiture" style={{ width: "100%", height: "auto" }} />
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.handleCloseassurance}
                                style={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "#e0e0e0", border: "none", borderRadius: "50%", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}
                              >
                                <CloseIcon style={{ color: "#000" }} />
                              </button>
                            </div>
                            )}
                                        
                            {/* Bouton pour voir l'assurance */}
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary" style={{ gridColumn: "3", width: "180px", backgroundColor: "#8190D5" }}
                              onClick={this.handleViewImage}
                            >
                              Voir l'assurance
                            </button>
                              {/* Bouton pour modifier l'assurance */}
                          
                            <input type="file" id="assurance" name="assurance" onChange={this.handleImageChange}   />
                          
                          </div>
                            
                          <br/>
                            
                          </div>
                          <button type="submit" className="btn btn-primary" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>Modifier</button>
                        </form>
                        
                          
                        </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.toggleEditForm} color="primary">
                              Fermer 
                            </Button>
                        </DialogActions>
                      </Dialog>
                   
                        {/* modal plus de détails */}
                        <Dialog open={this.state.showForm} onClose={() => this.toggleShowForm(this.state.showVoitureId)} fullWidth maxWidth="md" style={{ height: "500px" }}>
                        <DialogTitle>Plus de détails</DialogTitle>
                        <DialogContent>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 2fr', width: '80%' }}>
                              <div style={{ padding: '1rem' }}>
                                <img src={`${url}/image/${editingVoiture.image}`} alt="Voiture" style={{ width: "100%", height: "auto" }} />
                              </div>
                              <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                {editingVoiture && editingVoiture.galeries && editingVoiture.galeries.map((galerie) => (
                                  <img src={`${url}/image/${galerie.image}`} alt="Galerie" style={{ width: "60%", height: "auto" }} key={galerie.id} />
                                ))}
                              </div>
                              <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <div style={{ fontWeight: 'bold' }}>Marque : {editingVoiture.marque}</div>
                                <div style={{ fontWeight: 'bold' }}>Modèle : {editingVoiture.modele}</div>
                                <div style={{ fontWeight: 'bold' }}>Matricule : {editingVoiture.matricule}</div>
                                <div style={{ fontWeight: 'bold' }}>Tarif : {editingVoiture.tarif} DH</div>
                                <div style={{ fontWeight: 'bold' }}>Kilométrage : {editingVoiture.kilometrage} Km</div>
                                <div >{editingVoiture.moteur} | {editingVoiture.boiteVitesse} | C:{editingVoiture.climatisation} |{editingVoiture.nombrePortes} portes | {editingVoiture.nombreValises} valise</div>
                              </div>
                            </div>
                           
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <button type="button" className="btn btn-primary" style={{ width: "180px", backgroundColor: "#8190D5" }}>
                              Traqueur véhicule
                            </button>
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => this.toggleShowForm(this.state.showVoitureId)} color="primary">
                            Fermer 
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* modal pour ajouter une voiture */}




                       </div> 
                    
                  </div>
               
            </Section>
          </>
        );
    }
     }
     const Section = styled.section`
     .custom-dialog {
      width: 90%; /* Définissez la largeur souhaitée en pourcentage, pixels, etc. */
    }
     .search-bar {
     
        display: "grid",
      
     
    }
     .card-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 1rem;
      margin-top: 40px;
      background-color: #ffffff;
      
    }
     margin-left: 18vw;
     padding: 2rem;
     height: 100%;
     .grid {
       display: flex;
       flex-direction: column;
       height: 100%;
       gap: 1rem;
       margin-top: 2rem;
       .row__one {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         height: 50%;
         gap: 1rem;
       }
       .row__two {
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         gap: 1rem;
         height: 50%;
       }
     }
     @media screen and (min-width: 280px) and (max-width: 1080px) {
       margin-left: 0;
       .grid {
         .row__one,
         .row__two {
           grid-template-columns: 1fr;
         }
       }
     }
     @media (max-width: 768px) {
      /* Modifiez les propriétés CSS pour la mise en page mobile */
      div {
        gridTemplateColumns: 1fr;
        gridTemplateRows: auto;
      }
      .btn {
        grid-column: 1;
      }
    }
    
   `;
export default VoituresDisponible;