import React, { Component } from 'react';
import axios from 'axios';
import Sidebar from '../../Sidebar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import styled from "styled-components";
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import url from '../../../api/serverUrl';

class AddVoiture extends Component {
  constructor() {
   
    super();
    this.state = {
      loading: false,
      matricule: '',
      tarif: '',
      kilometrage: '',
      couleur: '',
      disponibilite: "disponible",
      assurance: '',
      moteur: '',
      nombrePortes: '',
      nombreValises: '',
      boiteVitesse: '',
      climatisation: '',
      marque: '',
      modele: '',
      franchiseAccident: '',
      franchiseVol: '',
      categorie_id: '',
      garage_id: '',
      image:'',
      galeries: [],
      categories: [],
      garages: [],
      successMessage: '',
      errorMessage: '',
      selectedFile: null,
      
     
    };
   
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    this.setState({ selectedFile: file });
  };
  componentDidMount() {
    this.fetchCategories();
    this.fetchGarages();
  }

  fetchCategories() {
    const token = sessionStorage.getItem("token");

    axios
      .get(`${url}/api/list/Categorie`, {
        headers: { Authorization: `Bearer ${token}` },})
      .then((response) => {
        this.setState({ categories: response.data, loading: false  });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchGarages() {
    const token = sessionStorage.getItem("token");
  
    axios
      .get(`${url}/api/liste/garage/${sessionStorage.getItem('agenceId')}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (response) => {
        const garages = response.data.map(async (garage) => {
          const voituresResponse = await axios.get(
            `${url}/api/garage/${garage.id}/voitures`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          const voitures = voituresResponse.data;
          const isSature = garage.capacity == voitures.length;
          return { ...garage, voitures, isSature };
        });
  
        Promise.all(garages)
          .then((garagesWithVoitures) => {
            this.setState({ garages: garagesWithVoitures, loading: false });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }
  

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

 
  handleGaleriesChange = (event) => {
    const files = Array.from(event.target.files);
    const images = files.map((file) => URL.createObjectURL(file));
  
    this.setState((prevState) => ({
      galeries: [...prevState.galeries, ...files],
      // selectedFiles: [...prevState.selectedFiles, ...files],
    }));
  };
 
  
  handleImageChange = (event)=>{
    // Mettre à jour l'état ou effectuer d'autres actions en fonction des fichiers sélectionnés
  const selectedFiles = event.target.files;

  // Vous pouvez par exemple mettre à jour l'état avec les fichiers sélectionnés
  this.setState({ selectedFiles });

  // Si vous souhaitez prévisualiser les images avant de les ajouter, vous pouvez créer des URL d'objet pour chaque fichier
  const previewURLs = [];
  for (let i = 0; i < selectedFiles.length; i++) {
    const file = selectedFiles[i];
    const previewURL = URL.createObjectURL(file);
    previewURLs.push(previewURL);
  }
  this.setState({ previewURLs });

  // Vous pouvez également effectuer d'autres actions en fonction des fichiers sélectionnés, comme valider leur type ou leur taille
  for (let i = 0; i < selectedFiles.length; i++) {
    const file = selectedFiles[i];
    const fileType = file.type;
    const fileSize = file.size;
    // Effectuer des validations ou des traitements supplémentaires en fonction des informations du fichier
  }

  }
  




handleAssuranceChange = (event) => {
  // Mettre à jour l'état ou effectuer d'autres actions en fonction des fichiers sélectionnés
  const selectedFiles = event.target.files;

  // Vous pouvez par exemple mettre à jour l'état avec les fichiers sélectionnés
  this.setState({ selectedFiles });

  // Si vous souhaitez prévisualiser les images avant de les ajouter, vous pouvez créer des URL d'objet pour chaque fichier
  const previewURLs = [];
  for (let i = 0; i < selectedFiles.length; i++) {
    const file = selectedFiles[i];
    const previewURL = URL.createObjectURL(file);
    previewURLs.push(previewURL);
  }
  this.setState({ previewURLs });

  // Vous pouvez également effectuer d'autres actions en fonction des fichiers sélectionnés, comme valider leur type ou leur taille
  for (let i = 0; i < selectedFiles.length; i++) {
    const file = selectedFiles[i];
    const fileType = file.type;
    const fileSize = file.size;
    // Effectuer des validations ou des traitements supplémentaires en fonction des informations du fichier
  }

  // ...
};


handleSubmit = (event) => {
  event.preventDefault();

  const formData = new FormData(event.target);
  const token = sessionStorage.getItem('token');

  axios
    .post(`${url}/api/add/Voiture`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const voitureId = response.data.voiture.id;
      const { galeries } = this.state;

      const promises = galeries.map((image) => {
        const galerieFormData = new FormData();
        galerieFormData.append('voiture_id', voitureId);
        galerieFormData.append('image', image);

        return axios.post(`${url}/api/add/Galerie`, galerieFormData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      });

      axios
        .all(promises)
        .then(() => {
          this.setState({
            matricule: '',
            tarif: '',
            kilometrage: '',
            couleur: '',
            disponibilite: '',
            assurance: '',
            moteur: '',
            nombrePortes: '',
            nombreValises: '',
            boiteVitesse: '',
            climatisation: '',
            marque: '',
            modele: '',
            franchiseAccident: '',
            franchiseVol: '',
            categorie_id: '',
            garage_id: '',
            image:'',
            galeries: [],
            selectedFiles: [],
            successMessage: 'La voiture a été ajoutée avec succès.',
            errorMessage: '',
          });

          this.props.onClose();
        })
        .catch((error) => {
          this.setState({
            successMessage: '',
            errorMessage: window.location.href = '/voitures',
          });
        });
    })
    .catch((error) => {
      this.setState({
        successMessage: '',
        errorMessage: "Une erreur est survenue lors de l'ajout de la voiture.",
      });
      if (error.response && error.response.data && error.response.data.error) {
        // Set the error message from the API response in the component state
        this.setState({ errorMessage: error.response.data.error });
      }
    });
};




  
  

  render() {
    const loading = this.state.loading;
    
    const {
      matricule,
      tarif,
      kilometrage,
      couleur,
      disponibilite,
      assurance,
      moteur,
      nombrePortes,
      nombreValises,
      boiteVitesse,
      climatisation,
      marque,
      modele,
      franchiseAccident,
      franchiseVol,
      categorie_id,
      garage_id,
      image,
      categories,
      garages,
      successMessage,
      errorMessage,
    } = this.state;
    return (
        
       <>
       <Dialog open={true} onClose={this.props.onClose} fullWidth maxWidth="md" style={{ height: "500px" }}>
         <DialogTitle>Ajoutez une voiture</DialogTitle>
          <DialogContent>
        
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {loading ? (
              <div className={"row text-center"}>
                <span className="fa fa-spin fa-spinner fa-4x"></span>
              </div>
              
            ) : (
        
        <form onSubmit={this.handleSubmit} style={{ display: "flex", flexDirection: "column", marginTop:"30px" }} sx={{
          '& .MuiTextField-root': { m: 2, width: '25ch' },
        }}
        noValidate
        autoComplete="off">
        
          <div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="marque" name="marque" label="Marque"  variant="filled" value={marque} onChange={this.handleInputChange} required/>
              <TextField id="modele" name="modele" label="Modèle"  variant="filled" value={modele} onChange={this.handleInputChange} required/>
              <TextField id="matricule" name="matricule" label="Matricule"  variant="filled" value={matricule} onChange={this.handleInputChange} required/>
             
           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="couleur" name="couleur" label="Couleur"  variant="filled" value={couleur} onChange={this.handleInputChange} required/>
              <TextField id="kilometrage" name="kilometrage" label="Kilométrage" variant="filled" value={kilometrage} onChange={this.handleInputChange} required/>
              <TextField id="moteur" name="moteur" label="Moteur"  variant="filled" value={moteur} onChange={this.handleInputChange} required/>
             
           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="nombrePortes" name="nombrePortes" label="Nombre de Portes"  variant="filled" value={nombrePortes} onChange={this.handleInputChange} required/>
              <TextField id="nombreValises" name="nombreValises" label="Nombre de Valises" variant="filled" value={nombreValises} onChange={this.handleInputChange} required/>
              <TextField id="boiteVitesse" name="boiteVitesse" label="Boite de Vitesse"  variant="filled" value={boiteVitesse} onChange={this.handleInputChange} required/>
             
           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="climatisation" name="climatisation" label="Climatisation"  variant="filled" value={climatisation} onChange={this.handleInputChange} required/>
              <TextField id="franchiseAccident" name="franchiseAccident" label="Franchise en cas d'Accident" variant="filled" value={franchiseAccident} onChange={this.handleInputChange} required/>
              <TextField id="franchiseVol" name="franchiseVol" label="franchise en cas de Vol"  variant="filled" value={franchiseVol} onChange={this.handleInputChange} required/>
             
           </div><br/>
          
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
               <TextField id="tarif" name="tarif" label="Tarif"  variant="filled" value={tarif} onChange={this.handleInputChange} required/>
               <TextField id="disponibilite" name="disponibilite" label="Disponibilite" variant="filled" value={disponibilite} onChange={this.handleInputChange} required disabled defaultValue="Valeur par défaut" />
               <TextField id="garage_id" name="garage_id" select label="Garage" value={garage_id} onChange={this.handleInputChange} required>
                {this.state.garages
                  .filter((garage) => !garage.isSature) // Filtrer les garages non saturés
                  .map((garage) => (
                    <MenuItem key={garage.id} value={garage.id}>
                      {garage.nameG}
                    </MenuItem>
                  ))}
              </TextField>

           </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <TextField id="categorie_id" name="categorie_id" select label="Categorie" value={categorie_id} onChange={this.handleInputChange} required >
                {this.state.categories.map((categorie) => (
                  <MenuItem key={categorie.id} value={categorie.id}>
                    {categorie.name}
                  </MenuItem>
                ))}
              </TextField>
            </div><br/>   
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <label htmlFor="assurance">Assurance:</label>
              <input type="file" id="assurance" name="assurance" onChange={this.handleAssuranceChange} />
            </div><br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <label htmlFor="image">Image:</label>
              <input type="file" id="image" name="image" onChange={this.handleImageChange} />
            </div> <br/>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '7px' }}>
              <label htmlFor="galeries">Galerie:</label>
              <input type="file" id="galeries" name="galeries" multiple onChange={this.handleGaleriesChange} />
           </div><br/>
            
          </div>
          
          <button type="submit" className="btn btn-primary" style={{ gridColumn: "3",width:"120px",backgroundColor:"#2769D5"}}>Ajouter </button>

        </form>
       

        )}
        {/* Display the error message if it exists */}
        {errorMessage && <div style={{ color: 'red', marginTop: '1rem' }}>{errorMessage}</div>}
         </DialogContent> 
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
                Fermer 
            </Button>
          </DialogActions>
      
          </Dialog>
  </>
    );
  }
}
const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;
export default AddVoiture;
