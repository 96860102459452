import React from "react";
import styled from "styled-components";
import image from "../assets/profile.jpeg";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { cardStyles } from "./ReusableStyles";
import {BsPersonCircle }from "react-icons/bs";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { color } from "framer-motion";
import url from '../api/serverUrl';

export default function Profile() {

  const [  agence , setAgence] = useState('');
  useEffect(() => {
    axios({
      method: 'GET',
      url: `${url}/api/Agence/${sessionStorage.getItem("agenceId")}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(res => {
        setAgence(res.data);
      })
      .catch(error => {
        console.error('Error retrieving client list:', error);
      });
    
  }, []);

  return (
    <Section style={{ color: 'white'}}>
      <div className="image">
        <BsPersonCircle style={{ fontSize: '150px' , color: '#c9daeb' }} />
      </div>
      <div className="title">
        <h2>{agence.nomLocation}</h2>
        <h5>
          <HiOutlineLocationMarker /> {agence.ville}, {agence.pays}
        </h5>
      </div>
  
    </Section>
  );
  
}
const Section = styled.section`
  ${cardStyles};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: white;
  border: 1px solid #3B82F6;
  .image {
    max-height: 10rem;
    overflow: hidden;
    border-radius: 20rem;
    img {
      height: 10rem;
      width: 10rem;
      object-fit: cover;
      border-radius: 20rem;
      transition: 0.5s ease-in-out;
      border: 2px solid black; /* Set border color to black */
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .title {
    /* background-color: whit; */
    text-align: center;
    h2,
    h5 {
      color: #1637f0;
      font-family: "Permanent Marker", cursive;
      letter-spacing: 0.3rem;
    }
    h5 {
      letter-spacing: 0.2rem;
    }
  }
  .info {
    color: black;
    display: flex;
    gap: 1rem;
    .container {
      text-align: center;
    }
  }
`;
