import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import scrollreveal from "scrollreveal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import '../components/ListClient.css';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Rating from '@mui/material/Rating';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@material-ui/icons/Search';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import EditContrat from './Modals/Contart/EditContrat';
import ShowContrat from './Modals/Contart/ShowContrat';
import { Link } from 'react-router-dom';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import "../components/Contrat.css";
import CloseIcon from '@mui/icons-material/Close';
import url from '../api/serverUrl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledSelect = styled.select`

  width: 100%;
  padding: 0.8rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;

`;


const ListContrat = () => {
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  };

  //Add Contrat 
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // pour client 
  const [cinList, setCinList] = useState([]);
  const [client, setClient] = useState([]);
  const [selectedCin, setSelectedCin] = useState('');
  const [selectedClient, setSelectedClient] = useState({
    name: '',
    numeroPermis: '',
    telephone: '',
  });

  useEffect(() => {
    fetchClientList();
  }, []);

  const fetchClientList = () => {
    axios({
      method: 'GET',
      url: `${url}/api/client/liste/${sessionStorage.getItem('agenceId')}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        const cinList = res.data.map(client => client.cin);
        setCinList(cinList);
        setClient(res.data); // Mettre à jour l'état des clients
      })
      .catch((error) => {
        console.error('Error retrieving client list:', error);
      });
  };


  const handleSelectChange = (event) => {
    const selectedCin = event.target.value;
    setSelectedCin(selectedCin);

    // Trouver le client sélectionné en utilisant le cin
    const selectedClient = client.find(client => client.cin === selectedCin);

    // Mettre à jour les valeurs des champs d'entrée avec les informations du client sélectionné
    setSelectedClient(selectedClient);
  };

  console.log(selectedClient.id);
  //pour voiture 
  const [matriculeList, setMatriculeList] = useState([]);
  const [voitures, setVoitures] = useState([]);
  const [selectedMatricule, setSelectedMatricule] = useState('');
  const [selectedVoiture, setSelectedVoiture] = useState({
    marque: '',
    kilometrage: '',
  });


  useEffect(() => {
    fetchVoitureList();
  }, []);

  const fetchVoitureList = () => {
    axios({
      method: 'GET',
      url: `${url}/api/list/Voitures/${sessionStorage.getItem('agenceId')}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        const matriculeList = res.data.map(voiture => voiture.matricule);
        setMatriculeList(matriculeList);
        setVoitures(res.data); // Mettre à jour l'état des clients
      })
      .catch((error) => {
        console.error('Error retrieving voiture list:', error);
      });
  };

  const handleSelectChangeVoiture = (event) => {
    const selectedMatricule = event.target.value;
    setSelectedMatricule(selectedMatricule);

    // Trouver le client sélectionné en utilisant le cin
    const selectedVoiture = voitures.find(client => client.matricule === selectedMatricule);

    // Mettre à jour les valeurs des champs d'entrée avec les informations du client sélectionné
    setSelectedVoiture(selectedVoiture);
  };

  const [isDeuxiemeConducteurChecked, setIsDeuxiemeConducteurChecked] =
    useState(true);

  const handleDeuxiemeConducteurChange = (event) => {
    setIsDeuxiemeConducteurChecked(event.target.checked);
  };

  const [isKiloChecked, setIsKilorChecked] =
    useState(true);

  const handleKiloChange = (event) => {
    setIsKilorChecked(event.target.checked);
  };
  // const [prix, setPrix] = useState("");
  // const [debut, setDebut] = useState("");
  // const [fin, setFin] = useState("");

  //   

  const handleSubmit = () => {
    const departureDate = new Date(document.getElementById('datedebut').value);
    const returnDate = new Date(document.getElementById('datefin').value);

    if (departureDate >= returnDate) {
      // Display an error message or perform any other necessary action
      alert('Please select a return date that is after the departure date');
      return;
    }
    const prix = document.getElementById('prix').value;
    const debut = new Date(document.getElementById('datedebut').value);
    const debutFormatted = debut.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });

    const fin = new Date(document.getElementById('datefin').value);
    const finFormatted = fin.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    const nameDc = document.getElementById('nameDc').value;
    const cinDc = document.getElementById('cinDc').value;
    const telephoneDc = document.getElementById('telephoneDc').value;
    const numeroPermisDC = document.getElementById('numeroPermisDC').value;
    const montantKS = document.getElementById('montantKS').value;
    // Utilisez debutFormatted et finFormatted dans votre requête axios

    //    console.log(prix);
    //  const debut = document.getElementById('datedebut').value;
    console.log(debutFormatted);
    //  const fin = document.getElementById('datefin').value;
    console.log(finFormatted);

    axios
      .post(`${url}/api/contrat/create`, {
        clientId: selectedClient.id, voitureId: selectedVoiture.id, dateSortie: debutFormatted, dateRetour: finFormatted, prixNegocie: prix,
        etatVehicule: "", nameDc: nameDc, telephoneDc: telephoneDc, cinDc: cinDc, numeroPermisDC: numeroPermisDC, montantKS: montantKS
      }, { headers })
      .then((response) => {
        console.log(response.data);
        handleClose();
        fetchContratList();
      })
      .catch((error) => {
        console.error(error);
        // add a toast that tells the user that we cannot add this contrat  ==> la voiure est deje reservée dans cette date 
        console.log(error.response.data);
        Swal.fire('Ooops.. !', 'La voiture est indisponible à cette date!', 'error');
        handleClose();
      });
  };




  // update Client 

  const [openEditContrat, setOpenEditContrat] = useState(false);
  const [editedContrat, setEditedContrat] = useState("");

  const handleEditContrat = (contrat) => {
    console.log('Edit contrat:', contrat);
    setEditedContrat(contrat);
    setOpenEditContrat(true);
  };
  const handleCloseModalContrat = () => {
    setOpenEditContrat(false);
    setEditedContrat(null);
  };



  //Delete Contrat
  const handleDelete = (row) => {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cette Contrat ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${url}/api/delete/contrat/${row.id}`, { headers })
          .then((response) => {
            Swal.fire('Supprimé !', 'Le contrat a été supprimé avec succès.', 'success');
            fetchContratList();
          })
          .catch((error) => {
            console.error(error);
            console.log(error.response.data);
          });
      }
    });
  };

  const refreshToken = localStorage.getItem('refreshToken');
  const refreshAccessToken = (refreshToken) => {
    return axios.post('https://example.com/api/refresh-token', {
      refresh_token: refreshToken,
    });
  };


  const fetchContratList = () => {
    const accessToken = sessionStorage.getItem('token');
    const refreshToken = sessionStorage.getItem('refreshToken');

    axios({
      method: 'GET',
      url: `${url}/api/contrat/liste/${sessionStorage.getItem('agenceId')}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        setClients(res.data);
      })
      .catch((error) => {
        // Vérifiez si l'erreur est due à un token expiré (code 401 Unauthorized)
        if (error.response.status === 401) {
          // Récupérez le nouveau token d'accès en utilisant le refreshToken
          refreshAccessToken(refreshToken)
            .then((response) => {
              const newAccessToken = response.data.access_token;
              // Mettez à jour le token d'accès dans le sessionStorage
              sessionStorage.setItem('token', newAccessToken);
              // Refaites l'appel API avec le nouveau token d'accès
              fetchContratList();
            })
            .catch((error) => {
              console.error('Error refreshing access token:', error);
              // Gérez l'erreur de rafraîchissement du token, par exemple, redirigez vers la page de connexion
            });
        } else {
          console.error('Error retrieving contrat list:', error);
        }
      });
  };



  //List of clients
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Nombre d'éléments à afficher par page

  const filteredClients = clients.filter(contrat => {
    // Convertir la recherche et les valeurs des colonnes en minuscules pour une recherche insensible à la casse
    const query = searchQuery.toLowerCase();
    const name = contrat.client.name.toLowerCase();
    const cin = contrat.client.cin.toLowerCase();
    const matricule = contrat.voiture.matricule.toLowerCase();

   
    

    // Vérifier si la recherche correspond à l'une des valeurs des colonnes
    return name.includes(query) || cin.includes(query) || matricule.includes(query);
  });


  // download Contrat

  const handleDownloadPdf = (id) => {
    axios.get(`${url}/api/convert/html-to-pdf/${id}`, {
      responseType: 'blob', headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(response => {
        // Créez un objet URL à partir des données de la réponse
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Créez un lien temporaire pour le téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'converted.pdf');

        // Ajoutez le lien au document et déclenchez le téléchargement
        document.body.appendChild(link);
        link.click();

        // Nettoyez l'objet URL et supprimez le lien du document
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error('Erreur lors du téléchargement du PDF :', error);
      });
  };

  const columns = [
    {
      name: 'Action', accessor: (row) => row.action, cell: row => (
        <>
          <div style={{ textAlign: 'center' }}>
            <IconButton onClick={() => handleEditContrat(row)}>
              <EditIcon style={{ color: 'black' }} />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)}>
              <DeleteIcon style={{ color: 'grey' }} />
            </IconButton>
          </div>

        </>
      ),
    },
    {
      name: 'Nom et Prénom', accessor: (row) => row.name, cell: row => (
        <div className="long-name-cell" style={{ textAlign: 'center' }} >{row.client.name}</div>
      )
    },
    {
      name: 'Cin/passport ', accessor: (row) => row.cin, cell: row => (
        <div className="long-name-cell" style={{ textAlign: 'center' }}>{row.client.cin}</div>
      )
    },
    {
      name: 'Matricule', accessor: (row) => row.numeroPermis, cell: row => (
        <div className="long-name-cell" style={{ textAlign: 'center' }}>{row.voiture.matricule}</div>
      )
    },
    {
      name: 'Date Sortie',
      accessor: 'datesortie',
      cell: (row) => {
        const formattedDate = moment(row.datesortie).format('YYYY-MM-DD');

        return (
          <div style={{ textAlign: 'center' }}>
            {formattedDate}
          </div>
        );
      }
    },

    {
      name: 'Date retoure', accessor: (row) => row.dateretoure, cell: (row) => {
        const DateR = moment(row.dateretoure).format('YYYY-MM-DD');
        return (
          <div style={{ textAlign: 'center' }}>
            {DateR}
          </div>
        );
      }
    },
    // {
    //   name: 'Prix negocie', accessor: (row) => row.telephone, cell: row => (
    //     <div className="long-name-cell" style={{ textAlign: 'center' }} >{row.prixnegocie}</div>
    //   )
    // },
    {
      name: 'Plus d\'info',
      accessor: (row) => row.action,
      cell: row => (
        <div style={{ textAlign: 'center' }}>
          <IconButton
            onClick={() => handleShowContrat(row)}
          >
            <InfoIcon style={{ color: 'blue' }} />
          </IconButton>
          <IconButton
            onClick={() => handleDownloadPdf(row.id)} // Appel de la fonction handleDownloadPdf lors du clic sur l'icône de téléchargement
          >
            <DownloadForOfflineIcon style={{ color: 'green' }} />
          </IconButton>
        </div>
      ),
    },

  ];
  const handlePageChange = page => {
    setCurrentPage(page);
  };
  const sortedClients = [...filteredClients].sort((a, b) => b.id - a.id);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${url}/api/contrat/liste/${sessionStorage.getItem('agenceId')}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })
      .then(res => {
        setClients(res.data);
      })
      .catch(error => {
        console.error('Error retrieving contrat list:', error);
      });
  }, []);




  //Show Contart

  const [constratShow, setConstratShow] = useState("");
  const [openConstratShowModal, setOpenConstratShowModal] = useState(false);
  const handleShowContrat = (row) => {
    console.log('Info client:', row);
    setConstratShow(row);
    setOpenConstratShowModal(true);
  };

  const handleCloseModalShowContrat = () => {
    setOpenConstratShowModal(false);
    setConstratShow(null);
  };


  const customStyles = {
    headRow: {
      style: {
        background: '#2769D5',
        color: 'white'
      },
    },
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgba(0,0,0,.075)',
      },
    },
  };



  useEffect(() => {
    const sr = scrollreveal({
      origin: "bottom",
      distance: "80px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        nav,
        .row__one,
        .row__two
    `,
      {
        opacity: 0,
        interval: 100,
      }
    );
  }, []);





  return (
    <><Sidebar />
      <Navbar />
      <Section>

        <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px' }}>
          <h2 className="text-center" style={{ fontSize: '24px', color: '#333', margin: '0', padding: '10px', borderBottom: '2px solid blue', width: '50%', margin: 'auto' }}>
            Liste Des Contrats
          </h2>
        </div>

        <div>
          <div className="flex justify-end" style={{ marginTop: '20px', marginBottom: '20px' }}>
            <button
              onClick={handleOpen}
              className="px-4 py-2 rounded-md bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none"
              style={{ backgroundColor: 'blue', borderRadius: '20px' }}
            >
              Ajouter Contrat
            </button>
          </div>


          <div className="datatable-container">
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                width: '40%',
                height: '37px',
                maxWidth: '500px',
                marginTop: '20px'
                , marginBottom: '20px',
                border: ' 1px solid #3B82F6',
                marginLeft: '20px'
                /* Ajouter une largeur maximale pour que le formulaire ne dépasse pas une certaine taille */
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery} onChange={e => setSearchQuery(e.target.value)}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon style={{ color: 'black' }} />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            </Paper>
            <DataTable
              columns={columns}
              data={sortedClients}
              customStyles={customStyles}
              pagination
              paginationPerPage={itemsPerPage}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              paginationTotalRows={sortedClients.length}
              onChangePage={handlePageChange}
            />
          </div>

          {/* Modal Edit Contart */}
          {openEditContrat && (
            <EditContrat contrat={editedContrat} onClose={handleCloseModalContrat} fetchContratList={fetchContratList} />
          )}

          {openConstratShowModal && (
            <ShowContrat infocontrat={constratShow} onCloseContrat={handleCloseModalShowContrat} />
          )}

        </div>



        {/* Modal Add Contrat  */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: '100%',
              maxWidth: '1000px',
              height: '100%',
              maxHeight: '650px',
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            <Button
              onClick={handleClose}
              sx={{ alignSelf: 'flex-end' }} // Ajoutez cette ligne pour aligner le bouton à droite
            >
              <CloseIcon />
            </Button>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{
                mt: 2,
                color: 'rgb(68, 125, 248)',
                textAlign: 'center',
                fontWeight: 'lighter',
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                borderBottom: '1px solid rgb(80, 80, 80)',
                paddingBottom: '10px',

              }}
            >
              Créer une location
            </Typography>
            <div className="containner" style={{ overflow: 'auto' }}>
              <div className="Reservation">

                <div className="container">
                  <div className="container2Col">
                    <div className="form_card">

                      <div className="inputs__container">
                        <div className='sous-title' > <strong>Client</strong></div>
                        <div className="form__control">
                          <label htmlFor="CIN">CIN / passport</label>
                          <StyledSelect value={selectedCin} onChange={handleSelectChange} id="CIN">
                            <option disabled value="">
                              Entrer  CIN
                            </option>
                            {cinList.map((cin, index) => (

                              <option key={index} value={cin}>{cin}</option>
                            ))}
                          </StyledSelect>
                        </div>
                        <div className="form__control">
                          <label htmlFor="name">Nom et prenom</label>
                          <input
                            type="text"
                            placeholder="Entrer votre nom et prénom"
                            id="name"
                            value={selectedClient.name}
                            disabled
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="npermis">N permis</label>
                          <input
                            type="text"
                            placeholder="Entrer votre n premis"
                            id="npermis"
                            value={selectedClient.numeroPermis}
                            disabled
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="tel">Telephone</label>
                          <input
                            type="text"
                            placeholder="Entrer votre num de telephone"
                            id="tel"
                            value={selectedClient.telephone}
                            disabled
                            style={{
                              backgroundColor: 'white',
                            }}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="form_card">

                      <div className="inputs__container">
                        <div className='sous-title'> <strong>Voiture</strong></div>
                        <div className="form__control">
                          {/* <label htmlFor="Matricule">Matricule</label> */}
                          <label htmlFor="Matricule">Sélectionnez votre matricule :</label>
                          <StyledSelect
                            value={selectedMatricule}
                            onChange={handleSelectChangeVoiture}
                            placeholder="Entrer matricule"
                            id="Matricule"
                          >
                            <option disabled value="">
                              Entrer Matricule
                            </option>
                            {matriculeList.map((matricule, index) => (
                              <option key={index} value={matricule}>
                                {matricule}
                              </option>
                            ))}
                          </StyledSelect>

                        </div>
                        <div className="form__control">
                          <label htmlFor="marque">Marque</label>
                          <input type="text" placeholder="Entrer la marque" id="marque"
                            value={selectedVoiture.marque}
                            disabled
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="km">Kilometrage</label>
                          <input type="text" placeholder="Entrer le kelometrage" id="km"
                            value={selectedVoiture.kilometrage}
                            disabled
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="prix">Prix negocié</label>
                          <input type="text" placeholder="Entrer le prix" id="prix" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form_card duree">
                    <div className="sous-title-d" > <strong>Durée</strong></div>
                    <div className="inputs__container">


                      <div className="form__control">
                        <label htmlFor="datedebut">Date/heure debut</label>
                        <input type="datetime-local" id="datedebut" />
                      </div>
                      <div className="form__control">
                        <label htmlFor="datefin">Date/heure fin</label>
                        <input type="datetime-local" id="datefin" />
                      </div>
                    </div>
                  </div>




                  <div className="sous-title-d"> <strong>Info suplumentaire</strong></div>
                  <div className="container2ColS">

                    <div className="form_card">
                      <div className="title">
                        <input
                          type="checkbox"
                          id="deuxièmeConducteur"
                          checked={isDeuxiemeConducteurChecked}
                          onChange={handleDeuxiemeConducteurChange}
                        />
                        <label htmlFor="deuxièmeConducteur"> Deuxième conducteur</label></div>
                      <div className="inputs__container">

                        <div className="form__control">
                          <label htmlFor="name">Nom et prenom</label>
                          <input
                            type="text"
                            placeholder="Entrer votre nom et prénom"
                            id="nameDc"
                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>

                        <div className="form__control">
                          <label htmlFor="CIN">CIN / passport</label>
                          <input
                            type="text"
                            placeholder="Entrer votre nom et prénom"
                            id="cinDc"

                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="npermis">N permis</label>
                          <input
                            type="text"
                            placeholder="Entrer votre n premis"
                            id="numeroPermisDC"

                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}
                          />
                        </div>
                        <div className="form__control">
                          <label htmlFor="tel">Telephone</label>
                          <input
                            type="text"
                            placeholder="Entrer votre num de telephone"
                            id="telephoneDc"

                            disabled={isDeuxiemeConducteurChecked}
                            style={{
                              backgroundColor: 'white',
                            }}

                          />
                        </div>
                      </div>
                    </div>


                    <div className="form_card">


                      <div className="title">
                        <input
                          type="checkbox"
                          id="additionalKilometers"
                          checked={isKiloChecked}
                          onChange={handleKiloChange}
                        />
                        <label htmlFor="additionalKilometers">Kilomètre supplémentaire</label>
                      </div>
                      <div className="inputs__container">
                        <p>
                          Tout kilomètre réalisé au-delà du kilométrage indiqué du présent contrat sera facturé au prix de ... Dirhams.
                        </p>
                        <div className="form__control">
                          <label htmlFor="tel">Montant</label>
                          <input
                            type="text"
                            placeholder="Montant"
                            id="montantKS"

                            disabled={isKiloChecked}
                            style={{
                              backgroundColor: 'white',
                            }}

                          />
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    style={{ color: 'white', backgroundColor: 'rgb(68, 125, 248)', borderRadius: '20px', marginTop: '20px' }}
                    sx={{
                      width: '200px',
                      height: '30px',
                      backgroundColor: '#eeeff5', // Couleur de fond
                      color: '#dddfe7', // Couleur du texte
                      fontWeight: 'bold',
                      textAlign: 'center',
                      textTransform: 'uppercase', // Transformation du texte en majuscules
                      padding: '12px 24px', // Espacement interne du bouton
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // Ombre légère
                      transition: 'background-color 0.3s ease', // Transition fluide pour la couleur de fond
                      '&:hover': {
                        backgroundColor: '#3f51b5', // Couleur de survol
                      },
                    }}
                  >
                    Enregistrer
                  </Button>
                </div>
              </div>



            </div>


          </Box>
        </Modal>
      </Section>
    </>
  );
};

export default ListContrat;

const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  .grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    margin-top: 2rem;
    .row__one {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 50%;
      gap: 1rem;
    }
    .row__two {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      height: 50%;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    margin-left: 0;
    .grid {
      .row__one,
      .row__two {
        grid-template-columns: 1fr;
      }
    }
  }
`;
