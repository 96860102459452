import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover'; 
import axios from 'axios';
import { MdNotificationsActive } from 'react-icons/md';
import StarIcon from '@mui/icons-material/Star';
import url from '../api/serverUrl';


const Navbar = ({ pendingContractsCount }) => {
  const [nom, setNom] = useState('');
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEll, setAnchorEll] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const agenceNom = sessionStorage.getItem('agenceNom');
    setNom(agenceNom);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/"); // Utilisez navigate() pour rediriger vers une autre route
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
   // Function to set the anchor element when the notification icon is clicked
   const handleMenuOpen = (event) => {
    setAnchorEll(event.currentTarget);
  };

  // Function to reset the anchor element when the menu is closed
  const handleMenuClose = () => {
    setAnchorEll(null);
  };


  const [clients, setClients] = useState([]);

  useEffect(() => {
    fetchClientList();
  }, []);

  const fetchClientList = () => {
    const agenceId = sessionStorage.getItem('agenceId');
    const token = sessionStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios.get(`${url}/api/clients-with-expired-date-and-null-evaluation/${agenceId}`, { headers })
      .then((res) => {
        setClients(res.data);
      })
      .catch((error) => {
        console.error('Error retrieving client list:', error);
      });
  };


  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClickOpen = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open = Boolean(anchorEl1);
  const id = open ? 'popover-notification' : undefined;
  console.log("notif : " + clients);
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ height: '57px' }} style={{ backgroundColor: '#2769D5' }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
             
            </Typography>
            {auth && (
              <div>
             {nom}
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'down',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'down',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem component={Link} to="/profil">Profile</MenuItem>
                  <MenuItem onClick={handleLogout}><FiLogOut />Déconnexion</MenuItem>
                </Menu>
              </div>
            )}
            <div>
              <Badge badgeContent={clients.length} color="error" overlap="circular">
                <IconButton onClick={handleClickOpen}>
                  <MdNotificationsActive color="white" />
                </IconButton>
              </Badge>
              {clients.length > 0 && (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl1}
                  onClose={handleClose1}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {clients.map((client) => (
                    <div
                      key={client.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        whiteSpace: 'pre-wrap',
                        border: '1px solid #2e4cf8',
                        borderRadius: '5px',
                        padding: '10px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'white',
                        fontFamily: 'Arial, sans-serif',
                        marginBottom: '5px',
                        marginTop: '5px',
                        marginLeft: '1px',
                      }}
                    >
                      <p>
                        <StarIcon style={{ color: '#eeff02' }} /> Veuillez évaluer le client <strong>{client.name}</strong> avec le CIN: <strong>{client.cin}</strong>
                      </p>
                    </div>
                  ))}
                </Popover>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Navbar;
