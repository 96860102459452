import React, { Component, useEffect} from 'react';
import { useState } from 'react';
import styled from "styled-components";
import Sidebar from './Sidebar';
import Navbar from "./Navbar";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import MapComponent from './MapComponent';
import { useLocation } from 'react-router-dom';
import  {getContras, getContratParVoiture} from '../service/functions.js';

const style = {
  
  width: 400,
  p: 4,
};

function formatDateToDDMMYYYY(dateString) {
  const dateObj = new Date(dateString);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${day}/${month}/${year}`;
}

const Localisation = (props)=> {

    const location = useLocation();
    const { data, dispo } = location.state || {};

    const token = sessionStorage.getItem('token');
    const agenceId = sessionStorage.getItem('agenceId');
    const [contras, setContras]= useState([]);
    const [contrat, setContrasVoiture] = useState(null);

    useEffect(()=> {  
      if(!dispo){
        const getData = ()=> {
          getContras(token,agenceId).then((result)=> {
            setContras(result);
          }).catch((error) => {
            console.error('Error fetching data:', error);
          });
        }
        console.log(getContras(token,agenceId));
        getData();
      }
    }, []);


    useEffect(()=> {
      if(!dispo){
        console.log(contras);
        if(contras.length != 0){
          setContrasVoiture(getContratParVoiture(contras, data.id));
        }
      }
    }, [contras])

    useEffect(()=> {
      if(!dispo){
       console.log(contrat);
      }
     }, [contrat]);


    return (
          
        <>
          <Sidebar />
          <Navbar /> 
          <Section>
           
          <div className="row" style={{ backgroundCcolor: '#f2f2f2', marginBottom: '20px'}}>
              <h2 className="text-center" style={{ fontSize: '24px',color: '#333',margin: '0', padding: '10px', borderBottom: '2px solid blue',width: '50%', margin: 'auto'}}>
                  GeoLocalisation
              </h2>
          </div>
          <div className="container">
          <div className="row">
                        <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gridColumnGap: "5px",
                        marginTop: "30px",
                        height:"500px",
                        backgroundColor: "#F2F5FA",
                        }}>

                        <Paper
                          component="form"
                          sx={{
                            p: '2px 4px',
                            width: '120%', 
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            height:"450px",
                            marginTop: '20px'
                             /* Ajouter une largeur maximale pour que le formulaire ne dépasse pas une certaine taille */
                          }}
                        >
                            <MapComponent/>
                            </Paper>
                        </div>
                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        
                          {!dispo && contrat!=null? 
                           <Box
                                sx={{
                                    ...style,
                                    width: '100%',
                                    maxWidth: '1000px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    id="modal-modal-title"
                                    variant="h5"
                                    component="h2"
                                    sx={{ mt: 2, color: 'blue', textAlign: 'center' }}
                                >
                                    Location N ° {contrat.id}
                                </Typography>
                                <hr
                                    style={{
                                        border: 'none',
                                        borderBottom: '1px solid #010102',
                                        margin: '10px 0 ',
                                    }}
                                />
                                <Box
                                    sx={{
                                        width: '100%',
                                        maxHeight: 'calc(100% )', // Adjust the value to leave space for the header and footer
                                        overflowY: 'auto', // Enable vertical scrolling
                                    }}
                                >
                                    <hr
                                        style={{
                                            border: 'none',
                                            borderBottom: '1px solid #010102',
                                            margin: '10px 0',
                                            marginBottom: '20px'
                                        }}
                                    />

                                    <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px' }}>
                                        <div>
                                            <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}> Locataire </h3>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>
                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1, top: '2px' }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Nom et Prénom :</strong> {contrat.client.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong  >Téléphone : </strong> {contrat.client.telephone}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1, }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>CIN/Passeport  :</strong>   {contrat.client.cin}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography

                                                    >
                                                        <strong> Expire le : </strong>   {contrat.client.cinExpire}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>
                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Pérmis du conduire N°  : </strong>  {contrat.client.numeroPermis}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography

                                                    >
                                                        <strong>Expire le : </strong>    {contrat.client.permisExpire}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Adresse : </strong>  {contrat.client.adresse}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography

                                                    >
                                                        <strong>Ville : </strong>    {contrat.client.ville}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px', marginTop: '1rem' }}>
                                        <div>
                                            <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}>Véhicule </h3>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Marque :</strong>   {contrat.voiture.marque}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography

                                                    >
                                                        <strong> Immatriculation : </strong>   {contrat.voiture.matricule}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Carburant : </strong>   {contrat.voiture.moteur}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </div>
                                    {contrat.client.numeroPermisDc && contrat.client.cinDc && contrat.client.telephoneDc && contrat.client.nameDc && (
                                        <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px' }}>
                                            <div>
                                                <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}>2<sup>ème</sup> Conducteur :</h3>
                                            </div>

                                            <>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography>
                                                                <strong>Nom et Prénom :</strong> {contrat.client.nameDc}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography>
                                                                <strong>Téléphone :</strong> {contrat.client.telephoneDc}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography>
                                                                <strong>CIN/Passeport :</strong> {contrat.client.cinDc}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography>
                                                                <strong>Pérmis du conduire N° :</strong> {contrat.client.numeroPermisDc}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </>

                                        </div>
                                    )}
                                    <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px', marginTop: '1rem' }}>
                                        <div>
                                            <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}>Location </h3>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Date de départ :</strong>   {formatDateToDDMMYYYY(contrat.datesortie)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography

                                                    >
                                                        <strong> Heure départ : </strong>   
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Date de retour : </strong>   {formatDateToDDMMYYYY(contrat.dateretoure)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Heure retour : </strong>   
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Km Départ : </strong>   
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Km Limite :  </strong>   
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Km Supplémentaire : </strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Tarif :  </strong>   {contrat.prixnegocie}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    </div>




                                    <hr
                                        style={{
                                            border: 'none',
                                            borderBottom: '1px solid #010102',
                                            margin: '10px 0 ',

                                        }}
                                    />

                                    
                                </Box>

                            </Box> : 
                            <Box
                            sx={{
                                ...style,
                                width: '100%',
                                maxWidth: '1000px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                id="modal-modal-title"
                                variant="h5"
                                component="h2"
                                sx={{ mt: 2, color: 'blue', textAlign: 'center' }}
                            >
                                Voiture N ° {data.id}
                            </Typography>
                            <hr
                                style={{
                                    border: 'none',
                                    borderBottom: '1px solid #010102',
                                    margin: '10px 0 ',
                                }}
                            />
                            <Box
                                sx={{
                                    width: '100%',
                                    maxHeight: 'calc(100% )', // Adjust the value to leave space for the header and footer
                                    overflowY: 'auto', // Enable vertical scrolling
                                }}
                            >
                                <hr
                                    style={{
                                        border: 'none',
                                        borderBottom: '1px solid #010102',
                                        margin: '10px 0',
                                        marginBottom: '20px'
                                    }}
                                />
                                    <div style={{ border: '1px solid black', padding: '30px', marginBottom: '10px', marginLeft: '20px', marginRight: '30px', borderRadius: '20px', marginTop: '1rem' }}>
                                        <div>
                                            <h3 style={{ fontSize: '24px', fontWeight: 'lighter', color: 'blue' }}>Véhicule </h3>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Marque :</strong>   {data.marque}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Typography

                                                    >
                                                        <strong> Immatriculation : </strong>   {data.matricule}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: '20px' }}>

                                            <Grid container spacing={10} sx={{ width: '100%', flex: 1 }}>
                                                <Grid item xs={12} md={6}>
                                                    <Typography >
                                                        <strong>Carburant : </strong>   {data.moteur}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </div>
                                    </div>
                                </Box>
                                </Box>}

                        </div>
                    </div>   
                </div>         
             </Section>
          </>
    );
}

const Section = styled.section`
     .custom-dialog {
      width: 90%; /* Définissez la largeur souhaitée en pourcentage, pixels, etc. */
    }
     .search-bar {
     
        display: "grid",
      
     
    }
     .card-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 1rem;
      margin-top: 40px;
      background-color: #ffffff;
      
    }
     margin-left: 18vw;
     padding: 2rem;
     height: 100%;
     .grid {
       display: flex;
       flex-direction: column;
       height: 100%;
       gap: 1rem;
       margin-top: 2rem;
       .row__one {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         height: 50%;
         gap: 1rem;
       }
       .row__two {
         display: grid;
         grid-template-columns: repeat(3, 1fr);
         gap: 1rem;
         height: 50%;
       }
     }
     @media screen and (min-width: 280px) and (max-width: 1080px) {
       margin-left: 0;
       .grid {
         .row__one,
         .row__two {
           grid-template-columns: 1fr;
         }
       }
     }
     @media (max-width: 768px) {
      /* Modifiez les propriétés CSS pour la mise en page mobile */
      div {
        gridTemplateColumns: 1fr;
        gridTemplateRows: auto;
      }
      .btn {
        grid-column: 1;
      }
    }
    
   `;

export default Localisation;