import React from "react";
import styled from "styled-components";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { cardStyles } from "../ReusableStyles";
import {BsPersonCircle }from "react-icons/bs";
import jwt_decode from "jwt-decode";


export default function AdminProfile() {

  const token = sessionStorage.getItem("token");
  const admin = jwt_decode(token);

  

  return (
    <Section style={{ color: 'white'}}>
      <div className="image">
        <BsPersonCircle style={{ fontSize: '150px' , color: '#c9daeb' }} />
      </div>
      <div className="title">
        <h5>
           {admin["username"]}
        </h5>
      </div>
  
    </Section>
  );
  
}
const Section = styled.section`
  ${cardStyles};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background-color: white;
  border: 1px solid #3B82F6;
  .image {
    max-height: 10rem;
    overflow: hidden;
    border-radius: 20rem;
    img {
      height: 10rem;
      width: 10rem;
      object-fit: cover;
      border-radius: 20rem;
      transition: 0.5s ease-in-out;
      border: 2px solid black; /* Set border color to black */
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .title {
    /* background-color: whit; */
    text-align: center;
    h2,
    h5 {
      color: #1637f0;
      letter-spacing: 0.3rem;
    }
    h5 {
      letter-spacing: 0.2rem;
    }
  }
  .info {
    color: black;
    display: flex;
    gap: 1rem;
    .container {
      text-align: center;
    }
  }
`;
